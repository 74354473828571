import Carousel from "../component/carousel"
import News from "../component/news";

const ComingSoon = ()=>{
    return(
        <>
         <Carousel
         mainText={
            `Coming Soon`
         }
         smallText={
            `...`
         }
         images={["img/slide-01.jpg","img/slide-01.jpg","img/slide-01.jpg"]}
         />
        <section className="mod-two-cols" data-lazy-load>

        <div className="">
        
        <div className="grid">
            <div className="grid__item md--two-thirds mod-two-cols--padded">
                <h3 className="heading-1"><span>Coming Soon</span></h3>

               
                <p style={{fontSize:"20px",lineHeight:"120%",marginTop:"15px"}}>...</p>
            </div>
        </div>
    </div>
</section>

{/*

<article className="mod-text-media mod-text-media__scheme--Light" data-lazy-load>
    <div className="mod-text-media__wrapper grid">
        <div className="grid  lg--two-thirds md-one-whole">


<div className="grid__item     
     md--one-half">
    <div className="mod-text__media">
        <div className="media-wrapper mod-text__media">
                <div className="mod-overview__media">
                        <a className="link--zoom-image">
                            <img className="img-cover" src="/img/Bidding-Benefits.jpg" alt="Conference Bid Support"/>  
                        </a>   
                </div>
        </div>
    </div>
</div>
            <div className="grid__item md--one-half">
                <div className="mod-text__content">
                    <h3 className="heading-3 mod-text__header mod-text-media__header">Benefits:</h3>
                    <div className="text--large mod-text-media__content">
                    <ul style={{listStyle:"disc",marginLeft:"25px",fontSize:"20px",lineHeight:"120%",marginTop:"5px"}}>
    <li><b>Enhanced Profile:</b> Secure prestigious international conferences to elevate your organization's reputation and visibility on the global stage.</li>
    <li><b>Global Presence:</b> Expand your organization's reach and influence by hosting events that attract participants from around the world.</li>
    <li><b>Knowledge Exchange:</b> Foster meaningful exchanges of ideas, innovations, and expertise by hosting conferences that bring together industry leaders and experts.</li>
    <li><b>Networking Opportunities:</b> Forge valuable connections with international stakeholders, fostering collaborations and partnerships that drive future growth and success.</li>
</ul>
                          
                    </div>
                       
                </div>
            </div>
        </div>
    </div>
</article>

<article className="mod-text-media mod-text-media__scheme--Light" data-lazy-load>
    <div className="mod-text-media__wrapper grid">
        <div className="grid push--lg--one-third lg--two-thirds md-one-whole">

            <div className="grid__item md--one-half">
                <div className="mod-text__content">
                    <h3 className="heading-3 mod-text__header mod-text-media__header">Services Include:</h3>
                    <div className="text--large mod-text-media__content">
                    <ul style={{listStyle:"disc",marginLeft:"25px",fontSize:"20px",lineHeight:"120%",marginTop:"15px"}}>
    <li><b>Market Research:</b> Conduct in-depth market analysis to identify potential conference opportunities and assess their feasibility.</li>
    <li><b>Bid Strategy Development:</b> Develop a customized bidding strategy tailored to your organization's goals, strengths, and target audience.</li>
    <li><b>Proposal Preparation:</b> Craft compelling bid proposals that effectively showcase your organization's capabilities, resources, and commitment to excellence.</li>
    <li><b>Presentation Support:</b> Provide expert guidance and support during the presentation stage to maximize impact and impress selection committees.</li>
    <li><b>Post-Bid Support:</b> Offer post-bid support, including negotiation assistance and contract finalization, to ensure a seamless transition to the conference planning phase.</li>
  
</ul>
                    </div>
                           
                </div>
            </div>


<div className="grid__item     
     md--one-half">
    <div className="mod-text__media">
        <div className="media-wrapper mod-text__media">
                <div className="mod-overview__media">
                        <a href="#" className="link--zoom-image">
                            <img className="img-cover" src="/img/Bidding-Services.jpg" alt="Bidding Service"/>  
                        </a>   
                </div>
        </div>
    </div>
    
</div> 


       </div>

    </div>
    
</article>

<article className="mod-text-media mod-text-media__scheme--Light" data-lazy-load>
    <div className="mod-text-media__wrapper grid">
        <div className="grid push--lg--one-third lg--one-whole md-one-whole">

            <div className="grid__item md--one-half">
                <div className="">
                    <div className="text--large mod-text-media__content">
                    <p style={{fontSize:"20px",lineHeight:"120%",marginTop:"15px"}}>Partner with CIMGLOBAL for strategic bidding assistance that propels your organization to new heights of success in the global conference arena.</p>
                    </div>
                          
                </div>
            </div>
            <br/>
<a href="/Contact" className="link--pointing link--pill ">
         <span className="link__animation">
         <span className="link__text">Conact Us</span>
         <span className="icon icon--middle icon-arrow-right"></span>
         </span>
         </a>




       </div>

    </div>
    
</article>

*/}

<News/>

        </>
    )
}

export default ComingSoon