import Carousel from "../../component/carousel"
import CaseStudiesComponent from "../../component/caseStudies"
import { Helmet } from "react-helmet"

const FisitaWorldAutomotiveCongress = ()=>{
    return(
        <>
         <Helmet>
                <meta charSet="utf-8" />
                <title>Home</title>
                <link rel="canonical" href="https://cimglobal.net/" />
            </Helmet>
         <Carousel
         mainText={
            `Sustainable Practices at the 37th FISITA World Automotive Congress`
         }
         smallText={
            ``
         }
         images={["img/slide-01.jpg","img/slide-01.jpg","img/slide-01.jpg"]}
         />
        <div className="page-contextual page-contextual--article"  >


        <nav id="articleNav" className="nav-contextual ">
    <div className="accordion accordion--mobile">
        <div className="nav-contextual__head">
            <span className="heading-5 nav-contextual__current">Go to top</span>
            <button className="btn accordion__btn">
                <span className="icon icon-chevron"></span>
                <span className="u-visually-hidden">Expand</span>     
            </button>
        </div>
        <div className="accordion__content">
            <ul className="nav-contextual__list">
                    <li className="nav-contextual__item dot__wrapper text--xsmall">
                        <a className="nav-contextual__link" href="#Sustainable-Practices-at-AFITA_WCCA-Conference" title="">
                            <span className="dot"></span>
                            Go to top
                        </a>
                    </li>
                    <li className="nav-contextual__item dot__wrapper text--xsmall">
                        <a className="nav-contextual__link" href="#Overview" title="">
                            <span className="dot"></span>
                            Overview
                        </a>
                    </li>
                    <li className="nav-contextual__item dot__wrapper text--xsmall">
                        <a className="nav-contextual__link" href="#Problem" title="">
                            <span className="dot"></span>
                            Problem
                        </a>
                    </li>
                    <li className="nav-contextual__item dot__wrapper text--xsmall">
                        <a className="nav-contextual__link" href="#Solution" title="">
                            <span className="dot"></span>
                            Solution
                        </a>
                    </li>
                    <li className="nav-contextual__item dot__wrapper text--xsmall">
                        <a className="nav-contextual__link" href="#Impact" title="">
                            <span className="dot"></span>
                            Impact
                        </a>
                    </li>
                    <li className="nav-contextual__item dot__wrapper text--xsmall">
                        <a className="nav-contextual__link" href="#Sustainable" title="">
                            <span className="dot"></span>
                            Key Sustainable Practices: 
                        </a>
                    </li>
                 
            </ul>
           
        </div>
    </div>
</nav>

    <article className="article">
            <div id="Sustainable-Practices-at-AFITA_WCCA-Conference">
                
<section className="hero-article" id="5532">
<div className="content-wrapper">
        <h2 className="heading-2 hero-article__heading">Sustainable Practices at the 37th FISITA World Automotive Congress</h2>

        

    <div className="media-wrapper hero-article__media link--zoom-image">
            <img className="img-cover" src="./img/FISITA-World-Automotive-Congress.jpg" alt="Sustainable Practices at the 37th FISITA World Automotive Congress"/></div>
    </div>
</section>
            </div>
            <div id="">
                <section className="mod-two-cols mod-two-cols--no-padding" data-lazy-load>
<div className="content-wrapper">
 
    <div className="">
            <div>
                    <div id="Overview">
                        
<div className="block-text">
<h3 className="heading-3 block-text__header grid__item md--two-quarters push--md--one-quarter">Overview</h3>
<div className="grid grid--flex">
    <aside className="grid__item md--one-quarter block-text__labels">
            <ul className="block-text__labels-list">
            </ul>
    </aside>

    <div className="grid__item md--three-quarters block-text__content">
            <p className="lg--two-thirds text--large block-text__subheader">
            The automotive industry is undergoing significant transformation driven by digital technologies and evolving customer preferences. FISITA 2018, hosted by the Society of Automotive Engineers INDIA, focused on disruptive technologies for affordable and sustainable mobility, addressing key challenges and opportunities in the industry.
            </p>
      
    </div>
</div>
</div>

                    </div>
                    <div id="Problem">
                        
<div className="block-text">
<h3 className="heading-3 block-text__header grid__item md--two-quarters push--md--one-quarter">Problem</h3>
<div className="grid grid--flex">
    <aside className="grid__item md--one-quarter block-text__labels">
            <ul className="block-text__labels-list">
            </ul>
    </aside>

    <div className="grid__item md--three-quarters block-text__content">
            <p className="lg--two-thirds text--large block-text__subheader">
            Traditional automotive practices contribute to environmental degradation and resource depletion. As the industry evolves, there is a growing need to adopt sustainable practices to minimize environmental impact and ensure long-term viability.
            </p>
       
    </div>
</div>
</div>

                    </div>
<div id="Solution">
                        
<div className="block-text">
<h3 className="heading-3 block-text__header grid__item md--two-quarters push--md--one-quarter">Solution</h3>
<div className="grid grid--flex">
    <aside className="grid__item md--one-quarter block-text__labels">
            <ul className="block-text__labels-list">
            </ul>
    </aside>

    <div className="grid__item md--three-quarters block-text__content">
            <p className="lg--two-thirds text--large block-text__subheader">
            FISITA 2018 emphasized the importance of sustainable mobility solutions, showcasing research and innovations in powertrain efficiency, emissions reduction, alternative fuels, and vehicle design. By promoting sustainable technologies and practices, the congress aimed to drive positive change and support the transition towards a more environmentally friendly automotive sector.
            </p>
     
    </div>
</div>
</div>

                    </div>
                    <div id="Impact">
                        
<div className="block-text">
<h3 className="heading-3 block-text__header grid__item md--two-quarters push--md--one-quarter">Impact</h3>
<div className="grid grid--flex">
    <aside className="grid__item md--one-quarter block-text__labels">
            <ul className="block-text__labels-list">
            </ul>
    </aside>

    <div className="grid__item md--three-quarters block-text__content">
            <p className="lg--two-thirds text--large block-text__subheader">
            CIMGlobal's management of the 37th FISITA World Automotive Congress demonstrated a commitment to sustainability and responsible event management. By integrating sustainable practices into the congress, CIMGlobal facilitated knowledge sharing and collaboration on sustainable mobility solutions, fostering a global dialogue on the future of the automotive industry.
            </p>
      
    </div>
</div>
</div>
</div>
<div id="Sustainable">
<div className="block-text">
<h3 className="heading-3 block-text__header grid__item md--two-quarters push--md--one-quarter">Key Sustainable Practices:</h3>
<div className="grid grid--flex">
    <aside className="grid__item md--one-quarter block-text__labels">
            <ul className="block-text__labels-list">
            </ul>
    </aside>

    <div className="grid__item md--three-quarters block-text__content">
            <p className="lg--two-thirds text--large block-text__subheader">
            <ul className="block-text__labels-list">
                <li>o	Reduced Environmental Footprint: FISITA 2018 raised awareness about the environmental impact of automotive technologies and encouraged the adoption of sustainable practices among industry stakeholders.</li>
                <li>
o	Knowledge Exchange:  The congress provided a platform for experts from industry and academia to share research and innovations in sustainable mobility, driving collaboration and innovation in the automotive sector.</li>
<li>
o	Community Engagement: FISITA 2018 featured special sessions, student activities, and technical visits, engaging diverse stakeholders and promoting dialogue on sustainable mobility solutions across generations
</li>
            </ul>
            
            </p>
      
    </div>
    
</div>
</div>

<div className="grid__item md--one-whole block-text__content">
            <p className="lg--two-thirds text--large block-text__subheader">
            Through these sustainable practices, the 37th FISITA World Automotive Congress contributed to CIMGlobal's mission of promoting environmentally friendly and socially responsible events while advancing the automotive industry towards a sustainable future.
            </p>
      
    </div>
                    </div>
                  
            </div>
    </div>
</div>
</section>



            </div>
          
           
           
<CaseStudiesComponent/>

    </article>
</div>
        </>
    )
}

export default FisitaWorldAutomotiveCongress