import Carousel from "../../component/carousel"
import { Helmet } from "react-helmet"

const AfitaWccaConference = ()=>{
    return(
        <>
        <Helmet>
                <meta charSet="utf-8" />
                <title>Sustainable Practices at AFITA/WCCA Conference</title>
                <link rel="canonical" href="https://cimglobal.net/" />
            </Helmet>
         <Carousel
         mainText={
            `Sustainable Practices at AFITA/WCCA Conference`
         }
         smallText={
            ` `
         }
         images={["img/slide-01.jpg","img/slide-01.jpg","img/slide-01.jpg"]}
         />
        <div className="page-contextual page-contextual--article"  >


<nav id="articleNav" className="nav-contextual ">
    <div className="accordion accordion--mobile">
        <div className="nav-contextual__head">
            <span className="heading-5 nav-contextual__current">Go to top</span>
            <button className="btn accordion__btn">
                <span className="icon icon-chevron"></span>
                <span className="u-visually-hidden">Expand</span>     
            </button>
        </div>
        <div className="accordion__content">
            <ul className="nav-contextual__list">
                    <li className="nav-contextual__item dot__wrapper text--xsmall">
                        <a className="nav-contextual__link" href="#Sustainable-Practices-at-AFITA_WCCA-Conference" title="">
                            <span className="dot"></span>
                            Go to top
                        </a>
                    </li>
                    <li className="nav-contextual__item dot__wrapper text--xsmall">
                        <a className="nav-contextual__link" href="#Overview" title="">
                            <span className="dot"></span>
                            Overview
                        </a>
                    </li>
                    <li className="nav-contextual__item dot__wrapper text--xsmall">
                        <a className="nav-contextual__link" href="#Problem" title="">
                            <span className="dot"></span>
                            Problem
                        </a>
                    </li>
                    <li className="nav-contextual__item dot__wrapper text--xsmall">
                        <a className="nav-contextual__link" href="#Solution" title="">
                            <span className="dot"></span>
                            Solution
                        </a>
                    </li>
                    <li className="nav-contextual__item dot__wrapper text--xsmall">
                        <a className="nav-contextual__link" href="#Impact" title="">
                            <span className="dot"></span>
                            Impact
                        </a>
                    </li>
                    <li className="nav-contextual__item dot__wrapper text--xsmall">
                        <a className="nav-contextual__link" href="#Sustainable" title="">
                            <span className="dot"></span>
                            Key Sustainable Practices: 
                        </a>
                    </li>
                 
            </ul>
           
        </div>
    </div>
</nav>

    <article className="article">
            <div id="Sustainable-Practices-at-AFITA_WCCA-Conference">
                
<section className="hero-article" id="5532">
<div className="content-wrapper">
        <h2 className="heading-2 hero-article__heading">Sustainable Practices at AFITA/WCCA Conference</h2>

        

    <div className="media-wrapper hero-article__media link--zoom-image">
            <img className="img-cover" src="./img/AFITA-WCCA-2018-Producing-More-with-Less.jpg" alt=""/></div>
    </div>
</section>
            </div>
            <div id="">
                <section className="mod-two-cols mod-two-cols--no-padding" data-lazy-load>
<div className="content-wrapper">
   {/* <div className="">
            
    </div>  */}
    <div className="">
            <div>
                    <div id="Overview">
                        
<div className="block-text">
<h3 className="heading-3 block-text__header grid__item md--two-quarters push--md--one-quarter">Overview</h3>
<div className="grid grid--flex">
    <aside className="grid__item md--one-quarter block-text__labels">
            <ul className="block-text__labels-list">
            </ul>
    </aside>

    <div className="grid__item md--three-quarters block-text__content">
            <p className="lg--two-thirds text--large block-text__subheader">
            AFITA/WCCA 2018 is a collaborative scientific program focused on Precision Agriculture, organized by the Asia-Pacific Federation for Information Technology (AFITA), International Network for Information Technology in Agriculture (INFITA), and Indian Society for Agricultural Information Technology (INSAIT). The conference aimed to advance the understanding and application of precision farming techniques to achieve sustainable and environmentally friendly agriculture.
            </p>
      
    </div>
</div>
</div>

                    </div>
                    <div id="Problem">
                        
<div className="block-text">
<h3 className="heading-3 block-text__header grid__item md--two-quarters push--md--one-quarter">Problem</h3>
<div className="grid grid--flex">
    <aside className="grid__item md--one-quarter block-text__labels">
            <ul className="block-text__labels-list">
            </ul>
    </aside>

    <div className="grid__item md--three-quarters block-text__content">
            <p className="lg--two-thirds text--large block-text__subheader">
            Traditional farming methods lack precision in managing variations in soil, weather, vegetation, and water, leading to inefficiencies and environmental degradation. Precision Agriculture seeks to address these challenges by using technology to accurately measure, map, and manage these variations, ultimately enhancing food production while reducing resource usage and costs.
            </p>
       
    </div>
</div>
</div>

                    </div>
<div id="Solution">
                        
<div className="block-text">
<h3 className="heading-3 block-text__header grid__item md--two-quarters push--md--one-quarter">Solution</h3>
<div className="grid grid--flex">
    <aside className="grid__item md--one-quarter block-text__labels">
            <ul className="block-text__labels-list">
            </ul>
    </aside>

    <div className="grid__item md--three-quarters block-text__content">
            <p className="lg--two-thirds text--large block-text__subheader">
            AFITA/WCCA 2018 brought together over 300 scientists, researchers, and industry professionals to share recent advances in technologies such as Remote Sensing & GIS, IoT, robotics, machine learning, and big data analytics in agricultural applications. These advancements contribute to the development and implementation of precision farming techniques, fostering competitive, sustainable, and environmentally friendly agriculture.
            </p>
     
    </div>
</div>
</div>

                    </div>
                    <div id="Impact">
                        
<div className="block-text">
<h3 className="heading-3 block-text__header grid__item md--two-quarters push--md--one-quarter">Impact</h3>
<div className="grid grid--flex">
    <aside className="grid__item md--one-quarter block-text__labels">
            <ul className="block-text__labels-list">
            </ul>
    </aside>

    <div className="grid__item md--three-quarters block-text__content">
            <p className="lg--two-thirds text--large block-text__subheader">
            By hosting AFITA/WCCA 2018, CIMGlobal facilitated the exchange of knowledge and best practices in Precision Agriculture, empowering attendees to apply cutting-edge technologies to address agricultural challenges sustainably. The conference provided a platform for collaboration and innovation, driving progress towards a more efficient, productive, and environmentally conscious agriculture sector.
            </p>
      
    </div>
</div>
</div>
</div>
<div id="Sustainable">
<div className="block-text">
<h3 className="heading-3 block-text__header grid__item md--two-quarters push--md--one-quarter">Key Sustainable Practices:</h3>
<div className="grid grid--flex">
    <aside className="grid__item md--one-quarter block-text__labels">
            <ul className="block-text__labels-list">
            </ul>
    </aside>

    <div className="grid__item md--three-quarters block-text__content">
            <p className="lg--two-thirds text--large block-text__subheader">
            <ul className="block-text__labels-list">
                <li>o	Reduced Environmental Footprint: AFITA/WCCA 2018 implemented waste management strategies, minimized single-use plastics, and encouraged paperless communication to reduce the conference's environmental impact.</li>
                <li>
o	Promotion of Sustainable Technologies: The conference showcased sustainable technologies and practices in Precision Agriculture, highlighting their role in achieving environmental protection, higher productivity, and economic benefits.</li>
<li>
o	International Collaboration: By bringing together diverse stakeholders from around the world, AFITA/WCCA 2018 facilitated international collaboration and knowledge exchange, fostering a global community committed to sustainable agriculture.
</li>
            </ul>
            </p>
      
    </div>
</div>
</div>


                    </div>
                  
            </div>
    </div>
</div>
</section>



            </div>
          
           
           
            <div id="module-d0524ada-e3a1-470d-8dd9-a7c028327f13">
                
<div className="mod-related mod-related--light" id="5566" data-lazy-load>
<div className="content-wrapper content-wrapper--right">
    <h2 className="heading-2 mod-related__header grid--flex-center">
        <span className="mod-related__header-text">Case Stories</span>
           
    </h2>

    

<ul className="grid grid--flex">
    <li className="grid__item md--one-third mod-related__item">
        <div className="block text--small mod-related__block">
            <a href="#" className="link link--pointing dot__wrapper mod-related__block">
                <div className="media-wrapper block__media block__media--standard link--zoom-image">
                        <img className="img-cover" src="./img/FISITA-World-Automotive-Congress.jpg" alt="Sustainable Practices at the 37th FISITA World Automotive Congress" />                      </div>
                <div className="mod-related__link">
                    <div className="mod-related__content">
                        <div className="tag block__tag text--dark">
                            Case  -  CIMGlobal
                        </div>

                        <h3 className="text--bold text--small">
                            <span>Sustainable Practices at the 37th FISITA World Automotive Congress</span>
                        </h3>

                        <div className="block__text text--dark">
                            <span className="dot"></span>
                            <span>The automotive industry is undergoing significant transformation driven by digital technologies and evolving customer preferences. FISITA 2018, hosted by the Society of Automotive Engineers.....</span>
                        </div>
                    </div>

                    <span>
                        <span className="link__text"></span>
                        <span className="icon icon--middle icon-arrow-right"></span>
                    </span>
                </div>
            </a>
        </div>
    </li>
    <li className="grid__item md--one-third mod-related__item">
        <div className="block text--small mod-related__block">
            <a href="#" className="link link--pointing dot__wrapper mod-related__block">
                <div className="media-wrapper block__media block__media--standard link--zoom-image">
                        <img className="img-cover" src="./img/Social-Footprints-National-CSR-Awards-Summit.png" alt="Social Footprints National CSR Awards & Summit" /></div>
                <div className="mod-related__link">
                    <div className="mod-related__content">
                        <div className="tag block__tag text--dark">
                            Case challenge -
                            Well construction &amp; integrity
                        </div>

                        <h3 className="text--bold text--small">
                            <span>Social Footprints National CSR Awards & Summit</span>
                        </h3>

                        <div className="block__text text--dark">
                            <span className="dot"></span>
                            <span>CIMGlobal organized the Social Footprints National CSR Awards & Summit, demonstrating a commitment to sustainable nation-building. Held at Vigyan Bhawan on 27th April, the event brought together...</span>
                        </div>
                    </div>

                    <span>
                        <span className="link__text"></span>
                        <span className="icon icon--middle icon-arrow-right"></span>
                    </span>
                </div>
            </a>
        </div>
    </li>
    <li className="grid__item md--one-third mod-related__item">
        <div className="block text--small mod-related__block">
            <a href="#" className="link link--pointing dot__wrapper mod-related__block">
                <div className="media-wrapper block__media block__media--standard link--zoom-image">
                        <img className="img-cover" src="./img/FISITA-World-Automotive-Congress.jpg" alt="Sustainable Practices at the 37th FISITA World Automotive Congress" />                      </div>
                <div className="mod-related__link">
                    <div className="mod-related__content">
                        <div className="tag block__tag text--dark">
                            Case  -  CIMGlobal
                        </div>

                        <h3 className="text--bold text--small">
                            <span>Sustainable Practices at the 37th FISITA World Automotive Congress</span>
                        </h3>

                        <div className="block__text text--dark">
                            <span className="dot"></span>
                            <span>The automotive industry is undergoing significant transformation driven by digital technologies and evolving customer preferences. FISITA 2018, hosted by the Society of Automotive Engineers.....</span>
                        </div>
                    </div>

                    <span>
                        <span className="link__text"></span>
                        <span className="icon icon--middle icon-arrow-right"></span>
                    </span>
                </div>
            </a>
        </div>
    </li>
    
</ul>
</div>
</div>
            </div>
    </article>
</div>
        </>
    )
}

export default AfitaWccaConference