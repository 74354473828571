import axios from "axios";

const http = axios.create({
  baseURL: "https://blogs.cimglobal.net/api", // Replace this with your API base URL
  headers: {
    "Content-type": "application/json",
  },
});

export default http;
