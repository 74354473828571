import Carousel from "../../component/carousel"
import News from "../../component/news"

const CookiePolicyPrivacy = ()=>{
return(
<>
<Carousel
mainText={
`Terms and Condition`
}
smallText={
``
}
images={["img/slide-01.jpg","img/slide-01.jpg","img/slide-01.jpg"]}
/>
<section className="mod-two-cols" data-lazy-load>

<div className="">

<div className="grid">
<div className="grid__item md--two-thirds mod-two-cols--padded">
<h3 className="heading-1"><span>Terms and Condition</span></h3>


<p style={{fontSize:"20px",lineHeight:"120%",marginTop:"15px"}}>At CIMGLOBAL, we are passionate about fostering a diverse and inclusive workplace where everyone has an equal opportunity to thrive, regardless of their background. We welcome applications from individuals of all races, sexual orientations, ages, and genders. We are dedicated to creating a fair and equitable recruitment process and will gladly accommodate any reasonable adjustments. To seize this exciting opportunity, please submit your CV as soon as possible. While we strive to review all applications, please understand that we may not be able to respond to each one individually. 
Join us in shaping the future of CIMGLOBAL!
</p>
<div className="heading-2">
<p></p>
</div>
</div>
</div>
</div>
</section>



<News/>
</>
)
}

export default CookiePolicyPrivacy