import Carousel from "../component/carousel"

const Outreach = ()=>{
    return(
        <>
         <Carousel
         mainText={
            `Think Emerging Markets`
         }
         smallText={
            `Proactive outreach to Asia and Middle East for global impact.`
         }
         images={["img/Mumbai.jpg","img/dubai.jpg","img/Singapore.jpg"]}
         />
        <section className="mod-two-cols" data-lazy-load>

        <div className="">
        
        <div className="grid">
            <div className="grid__item md--two-thirds mod-two-cols--padded">
                <h3 className="heading-1"><span>Outreach Services</span></h3>

             
<p style={{fontSize:"20px",lineHeight:"120%",marginTop:"15px"}}>Expand your organization's reach and impact globally with CIMGLOBAL's proactive outreach services <b>to Asia and the Middle East</b>. Our tailored outreach solutions are designed to connect you with key stakeholders, foster collaborations, and enhance your organization's visibility and influence in these regions.
</p>

<p style={{fontSize:"20px",lineHeight:"120%",marginTop:"15px"}}>CIMGLOBAL offers comprehensive outreach services aimed at helping organizations extend their presence and influence in Asia and the Middle East. Our proactive approach involves identifying and engaging with relevant stakeholders, including government agencies, industry associations, academic institutions, and corporate partners. Through strategic outreach initiatives, we facilitate networking opportunities, foster partnerships, and promote your organization's objectives and initiatives to target audiences in these regions.
</p>
            </div>
        </div>
    </div>
</section>

<article className="mod-text-media mod-text-media__scheme--Light" data-lazy-load>
    <div className="mod-text-media__wrapper grid">
        <div className="grid  lg--two-thirds md-one-whole">


<div className="grid__item     
     md--one-half">
    <div className="mod-text__media">
        <div className="media-wrapper mod-text__media">
                <div className="mod-overview__media">
                        <a className="link--zoom-image">
                            <img className="img-cover" src="/img/Outreach-Services.jpg" alt="Outreach Services"/>  
                        </a>   
                </div>
        </div>
    </div>
</div>
            <div className="grid__item md--one-half">
                <div className="mod-text__content">
                    <h3 className="heading-3 mod-text__header mod-text-media__header">Benefits:</h3>
                    <div className="text--large mod-text-media__content">
                    <ul style={{listStyle:"disc",marginLeft:"25px",fontSize:"20px",lineHeight:"120%",marginTop:"5px"}}>
                    <li><b>Expanded Reach:</b> Reach new audiences and markets in Asia and the Middle East, unlocking opportunities for growth and development.</li>
<li><b>Enhanced Visibility:</b> Increase your organization's visibility and brand recognition through targeted outreach campaigns and strategic partnerships.</li>
<li><b>Networking Opportunities:</b> Access a wide network of industry professionals, government officials, and decision-makers, facilitating collaboration and knowledge exchange.</li>
<li><b>Influence and Impact:</b> Position your organization as a thought leader and influencer in your industry, driving positive change and innovation.</li>
<li><b>Market Insights:</b> Gain valuable insights into local markets, trends, and regulatory landscapes, enabling informed decision-making and strategic planning.</li>
</ul>
                          
                    </div>
                       
                </div>
            </div>
        </div>
    </div>
</article>

<article className="mod-text-media mod-text-media__scheme--Light" data-lazy-load>
    <div className="mod-text-media__wrapper grid">
        <div className="grid push--lg--one-third lg--two-thirds md-one-whole">

            <div className="grid__item md--one-half">
                <div className="mod-text__content">
                    <h3 className="heading-3 mod-text__header mod-text-media__header">Services Include:</h3>
                    <div className="text--large mod-text-media__content">
                    <ul style={{listStyle:"disc",marginLeft:"25px",fontSize:"20px",lineHeight:"120%",marginTop:"15px"}}>
                    <li>Stakeholder Mapping and Engagement</li>
<li>Partnership Development</li>
<li>Event Representation and Promotion</li>
<li>Market Research and Analysis</li>
<li>Advocacy and Lobbying Support</li>
<li>Media Relations and Public Relations</li>
<li>Cultural Sensitivity Training and Localization</li>
  
</ul>
                    </div>
                           
                </div>
            </div>


<div className="grid__item     
     md--one-half">
    <div className="mod-text__media">
        <div className="media-wrapper mod-text__media">
                <div className="mod-overview__media">
                        <a href="#" className="link--zoom-image">
                            <img className="img-cover" src="/img/Outreach-Services_includes.jpg" alt="Outreach Services Includes"/>  
                        </a>   
                </div>
        </div>
    </div>
</div>        </div>
<br/>
<a href="/Contact" className="link--pointing link--pill ">
         <span className="link__animation">
         <span className="link__text">Conact Us</span>
         <span className="icon icon--middle icon-arrow-right"></span>
         </span>
         </a>
    </div>
</article>


<div class="grid mod-related mod-related--light mod-related--has-read-more" data-lazy-load>
        <div class="grid__item lg--nine-twelfths">
            <h2 class="heading-2 mod-related__header grid--flex-center">
                <span class="mod-related__header-text">Case Stories</span>
                  
            </h2>
            <ul class="grid grid--flex">
                    <li class="grid__item md--one-third mod-related__item">
                        <article class="block text--small mod-related__block">
                            <a class="link link--pointing dot__wrapper mod-related__block link--zoom-image" href="./docs/Growing-ACS-Membership-in-India.pdf" target="_blank" title="Read more" rel="noopener">
                                <div class="media-wrapper block__media block__media--standard">
                                        <img class="img-cover" src="/img/Growing-ACS-Membership-in-India.jpg" />
                                </div>
                                <div class="mod-related__link">
                                    <div class="mod-related__content">
                                      

                                        <h3 class="text--bold text--small">
                                            <span>Growing ACS Membership in India</span>
                                        </h3>

                                        <div class="block__text text--dark">
                                            <span class="dot"></span>
                                            <span>Building ACS Brand Awareness; Growing ACS membership in India; Managing ACS Events.</span>
                                        </div>
                                    </div>

                                    <span>
                                        <span class="link__text">Read more</span>
                                        <span class="icon icon--middle icon-arrow-right"></span>
                                    </span>
                                </div>
                            </a>
                        </article>
                    </li>
<li class="grid__item md--one-third mod-related__item">
<article class="block text--small mod-related__block">
<a class="link link--pointing dot__wrapper mod-related__block link--zoom-image" href="./docs/AMCA-AssociationManagement-in-ME.pdf" target="_blank" title="Read more" rel="noopener">
<div class="media-wrapper block__media block__media--standard">
<img class="img-cover" src="/img/AMCA-AssociationManagement-in-ME.jpg" />
</div>
<div class="mod-related__link">
<div class="mod-related__content">

<h3 class="text--bold text--small">
<span>AMCA AssociationManagement in ME</span>
</h3>

<div class="block__text text--dark">
<span class="dot"></span>
<span>Establishing AMCA Brand in the Middle East; Advocating for AMCA members; Managing AMCA events.</span>
</div>
</div>

<span>
<span class="link__text">Read more</span>
<span class="icon icon--middle icon-arrow-right"></span>
</span>
</div>
</a>
</article>
</li>

<li class="grid__item md--one-third mod-related__item">
<article class="block text--small mod-related__block">
<a class="link link--pointing dot__wrapper mod-related__block link--zoom-image" href="./docs/SOA-Study-Discovering-Indian-Market-Society-of-Actuaries.pdf" target="_blank" title="Read more" rel="noopener">
<div class="media-wrapper block__media block__media--standard">
<img class="img-cover" src="/img/soa-Discovering-Indian-Market.jpg" />
</div>
<div class="mod-related__link">
<div class="mod-related__content">

<h3 class="text--bold text--small">
<span>SOA Discovering India</span>
</h3>

<div class="block__text text--dark">
<span class="dot"></span>
<span>Professional support to the SOA team India visit, designing and ensuring timely meetings.</span>
</div>
</div>

<span>
<span class="link__text">Read more</span>
<span class="icon icon--middle icon-arrow-right"></span>
</span>
</div>
</a>
</article>
</li>
            </ul>
        </div>
    </div>
        </>
    )
}

export default Outreach