import Carousel from "../../component/carousel"
import News from "../../component/news"

const Career = ()=>{
    return(
        <>
         <Carousel
         mainText={
            `Career`
         }
         smallText={
            ``
         }
         images={["img/slide-01.jpg","img/slide-01.jpg","img/slide-01.jpg"]}
         />
        <section className="mod-two-cols" data-lazy-load>

        <div className="">
        
        <div className="grid">
            <div className="grid__item md--two-thirds mod-two-cols--padded">
                <h3 className="heading-1"><span>Career</span></h3>

                
                               <p style={{fontSize:"20px",lineHeight:"120%",marginTop:"15px"}}>At CIMGLOBAL, we are passionate about fostering a diverse and inclusive workplace where everyone has an equal opportunity to thrive, regardless of their background. We welcome applications from individuals of all races, sexual orientations, ages, and genders. We are dedicated to creating a fair and equitable recruitment process and will gladly accommodate any reasonable adjustments. To seize this exciting opportunity, please submit your CV as soon as possible. While we strive to review all applications, please understand that we may not be able to respond to each one individually. 
Join us in shaping the future of CIMGLOBAL!
</p>
            </div>
        </div>
    </div>
</section>

<div class="grid mod-related mod-related--light mod-related--has-read-more" data-lazy-load>
        <div class="grid__item lg--nine-twelfths">
          
            <ul class="grid grid--flex">
            <li class="grid__item md--one-half mod-related__item">
                        <article class="block text--small mod-related__block">
                        <a class="link link--pointing dot__wrapper mod-related__block link--zoom-image" href="./docs/Marketing-Manager_09.07.2024.pdf" target="_blank" title="Read more" rel="noopener">                                <div class="media-wrapper block__media block__media--standard">
                                        <img class="img-cover" src="/img/Marketing-Manager.jpg" />
                                </div>
                                <div class="mod-related__link">
                                    <div class="mod-related__content">
                                      

                                        <h3 class="text--bold text--small">
                                            <span>Marketing-Manager</span>
                                        </h3>

                                        <div class="block__text text--dark">
                                            <span class="dot"></span>
                                            <span>Based in Mumbai/Delhi (India)</span>
                                        </div>
                                    </div>

                                    <span>
                                        <span class="link__text">Read more</span>
                                        <span class="icon icon--middle icon-arrow-right"></span>
                                    </span>
                                </div>
                            </a>
                        </article>
                    </li>
                    <li class="grid__item md--one-half mod-related__item">
                        <article class="block text--small mod-related__block">
                        <a class="link link--pointing dot__wrapper mod-related__block link--zoom-image" href="./docs/Exhibiton-Sales.pdf" target="_blank" title="Read more" rel="noopener">
                                <div class="media-wrapper block__media block__media--standard">
                                        <img class="img-cover" src="/img/We-are-hiring.jpg" />
                                </div>
                                <div class="mod-related__link">
                                    <div class="mod-related__content">
                                      

                                        <h3 class="text--bold text--small">
                                            <span>Exhibitons Sales</span>
                                        </h3>

                                        <div class="block__text text--dark">
                                            <span class="dot"></span>
                                            <span>Based in Mumbai/Delhi</span>
                                        </div>
                                    </div>

                                    <span>
                                        <span class="link__text">Read more</span>
                                        <span class="icon icon--middle icon-arrow-right"></span>
                                    </span>
                                </div>
                            </a>
                        </article>
                    </li>
                    </ul>
                    <ul class="grid grid--flex">
                   {/* <li class="grid__item md--one-half mod-related__item">
                        <article class="block text--small mod-related__block">
                        <a class="link link--pointing dot__wrapper mod-related__block link--zoom-image" href="./docs/Marketing-Manager.pdf" target="_blank" title="Read more" rel="noopener">                                <div class="media-wrapper block__media block__media--standard">
                                        <img class="img-cover" src="/img/We-are-hiring2.jpg" />
                                </div>
                                <div class="mod-related__link">
                                    <div class="mod-related__content">
                                      

                                        <h3 class="text--bold text--small">
                                            <span>Marketing-Manager</span>
                                        </h3>

                                        <div class="block__text text--dark">
                                            <span class="dot"></span>
                                            <span>Based in Mumbai/Delhi</span>
                                        </div>
                                    </div>

                                    <span>
                                        <span class="link__text">Read more</span>
                                        <span class="icon icon--middle icon-arrow-right"></span>
                                    </span>
                                </div>
                            </a>
                        </article>
                    </li> */}
                    <li class="grid__item md--one-half mod-related__item">
                        <article class="block text--small mod-related__block">
                        <a class="link link--pointing dot__wrapper mod-related__block link--zoom-image" href="./docs/Social-Media-Manager.pdf" target="_blank" title="Read more" rel="noopener">
                                <div class="media-wrapper block__media block__media--standard">
                                        <img class="img-cover" src="/img/We-are-hiring1.jpg" />
                                </div>
                                <div class="mod-related__link">
                                    <div class="mod-related__content">
                                      

                                        <h3 class="text--bold text--small">
                                            <span>Social Media Manager</span>
                                        </h3>

                                        <div class="block__text text--dark">
                                            <span class="dot"></span>
                                            <span>Based in Mumbai/Delhi</span>
                                        </div>
                                    </div>

                                    <span>
                                        <span class="link__text">Read more</span>
                                        <span class="icon icon--middle icon-arrow-right"></span>
                                    </span>
                                </div>
                            </a>
                        </article>
                    </li>


            </ul>
        </div>
    </div>

    <News/>
        </>
    )
}

export default Career