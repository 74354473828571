import Carousel from "../component/carousel"

const ExhibitionManagement = ()=>{
    return(
        <>
         <Carousel
         mainText={
            `Exhibition<br/>Success`
         }
         
         smallText={
            `Exhibition success through expert management.`
         }
         images={["img/Exhibition_BG_01.jpg"]}
         />
        <section className="mod-two-cols" data-lazy-load>

        <div className="">
        
        <div className="grid">
            <div className="grid__item md--two-thirds mod-two-cols--padded">
                <h3 className="heading-1"><span>Exhibition Management</span></h3>


               
                <p style={{fontSize:"20px",lineHeight:"120%",marginTop:"15px"}}>

Organizing an exhibition, whether part of a conference or stand alone, asks for in-depth knowledge of logistics in combination with commercial insight. How do you offer the right value to exhibitors and sponsors? And how can you as an organization make sure both commercial parties and visitors get what they came for?
<br></br><br></br>
Make your exhibition a commercial success for you, your visitors and your exhibitors. Get in touch with our team to find out how we can add value for all parties involved.
</p>
                </div>
        </div>
    </div>
</section>

<article className="mod-text-media mod-text-media__scheme--Light" data-lazy-load>
    <div className="mod-text-media__wrapper grid">
        <div className="grid  lg--two-thirds md-one-whole">


<div className="grid__item     
     md--one-half">
    <div className="mod-text__media">
        <div className="media-wrapper mod-text__media">
                <div className="mod-overview__media">
                        <a className="link--zoom-image">
                            <img className="img-cover" src="/img/Exhibition-Management.jpg" alt="Exhibition Management"/>  
                        </a>   
                </div>
        </div>
    </div>
</div>
            <div className="grid__item md--one-half">
                <div className="mod-text__content">
                    <h3 className="heading-3 mod-text__header mod-text-media__header">Our services include: Exhibition Management</h3>
                    <div className="text--large mod-text-media__content">
                    <ul style={{listStyle:"disc",marginLeft:"25px",fontSize:"20px",lineHeight:"120%",marginTop:"5px"}}>
<li>Design and maintain exhibit hall floor plan</li>
<li>Produce the call for exhibitors and exhibitor registration forms</li>
<li>Solicit and secure exhibitors through direct mail and telemarketing</li>
<li>Process requests for exhibit information</li>
<li>Create sales and marketing tools</li>
<li>Accept and process all exhibitor applications including invoicing</li>
<li>Prepare and distribute exhibitor service manual and contract with decorator</li>
<li>Distribute exhibitor confirmations and booth assignments</li>
<li>Contract and act as liaison for decorator</li>
<li>Arrange for and order exhibit hall materials</li>
<li>Interface with exhibitors and exhibit hall venues</li>
<li>Provide final exposition report</li>
<li>Process orders for tracking/bar coding equipment</li>
</ul>
                          
                    </div>
                       
                </div>
            </div>
        </div>
    </div>
</article>

<article className="mod-text-media mod-text-media__scheme--Light" data-lazy-load>
    <div className="mod-text-media__wrapper grid">
        <div className="grid push--lg--one-third lg--two-thirds md-one-whole">

            <div className="grid__item md--one-half">
                <div className="mod-text__content">
                    <h3 className="heading-3 mod-text__header mod-text-media__header">On-site Exhibition Management</h3>
                    <div className="text--large mod-text-media__content">
                    <ul style={{listStyle:"disc",marginLeft:"25px",fontSize:"20px",lineHeight:"120%",marginTop:"5px"}}>
<li>Conduct site inspection of exhibit area</li>
<li>Ensure all exhibitors set-up and break-down according to code and conference regulations</li>
<li>Act as liaison between exhibitors, vendors, committee and staff</li>
<li>Fulfill orders for attendee info tracking equipment</li>
<li>Provide bar-coded badges for exhibitor lead tracking</li>
</ul>
                    </div>
                           
                </div>
            </div>


<div className="grid__item     
     md--one-half">
    <div className="mod-text__media">
        <div className="media-wrapper mod-text__media">
                <div className="mod-overview__media">
                        <a href="#" className="link--zoom-image">
                            <img className="img-cover" src="/img/On-site-Exhibition-Management.jpg" alt="On-site Exhibition Management"/>  
                        </a>   
                </div>
        </div>
    </div>
</div>        </div>
<br/>
<a href="/Contact" className="link--pointing link--pill ">
         <span className="link__animation">
         <span className="link__text">Conact Us</span>
         <span className="icon icon--middle icon-arrow-right"></span>
         </span>
         </a>
    </div>
</article>


<div class="grid mod-related mod-related--light mod-related--has-read-more" data-lazy-load>
        <div class="grid__item lg--nine-twelfths">
            <h2 class="heading-2 mod-related__header grid--flex-center">
                <span class="mod-related__header-text">Case Stories</span>
                    
            </h2>
            <ul class="grid grid--flex">
                    <li class="grid__item md--one-third mod-related__item">
                        <article class="block text--small mod-related__block">
                            <a class="link link--pointing dot__wrapper mod-related__block link--zoom-image" href="/ASIAFORGEMeetingandForgetechIndiaExhibition" title="Read more" rel="noopener">
                                <div class="media-wrapper block__media block__media--standard">
                                        <img class="img-cover" src="/img/ASIAFORGE-Meeting-and-Forgetech-India-Exhibition.jpg" alt="ASIAFORGE Meeting and Forgetech India Exhibition" />
                                </div>
                                <div class="mod-related__link">
                                    <div class="mod-related__content">
                                        <h3 class="text--bold text--small">
                                            <span>ASIAFORGE Meeting and Forgetech India Exhibition</span>
                                        </h3>

                                        <div class="block__text text--dark">
                                            <span class="dot"></span>
                                            <span>The ASIAFORGE Meeting is an international conference organized by the ASIAFORGE organization, which was established in 2005 by the Association of Indian Forging Industry...</span>
                                        </div>
                                    </div>

                                    <span>
                                        <span class="link__text">Read more</span>
                                        <span class="icon icon--middle icon-arrow-right"></span>
                                    </span>
                                </div>
                            </a>
                        </article>
                    </li>
                    <li class="grid__item md--one-third mod-related__item">
                        <article class="block text--small mod-related__block">
                            <a class="link link--pointing dot__wrapper mod-related__block link--zoom-image" href="/WindergyIndiaConferenceandExhibition" title="Read more" rel="noopener">
                                <div class="media-wrapper block__media block__media--standard">
                                        <img class="img-cover" src="/img/Windergy-India-Conference-and-Exhibition.jpg" alt="Windergy India Conference and Exhibition" />
                                </div>
                                <div class="mod-related__link">
                                    <div class="mod-related__content">
                                         <h3 class="text--bold text--small">
                                            <span>Windergy India Conference and Exhibition</span>
                                        </h3>

                                        <div class="block__text text--dark">
                                            <span class="dot"></span>
                                            <span>The Windergy India 2017 conference and exhibition was a landmark event for the wind energy industry in India, held from April 25-27, 2017 in New Delhi. The event was organized...</span>
                                        </div>
                                    </div>

                                    <span>
                                        <span class="link__text">Read more</span>
                                        <span class="icon icon--middle icon-arrow-right"></span>
                                    </span>
                                </div>
                            </a>
                        </article>
                    </li>
                    <li class="grid__item md--one-third mod-related__item">
                        <article class="block text--small mod-related__block">
                            <a class="link link--pointing dot__wrapper mod-related__block link--zoom-image" href="/CONCRETEConferenceandExhibition" title="Read more" rel="noopener">
                                <div class="media-wrapper block__media block__media--standard">
                                        <img class="img-cover" src="/img/CONCRETE-Conference-and-Exhibition.jpg" alt="CONCRETE Conference and Exhibition" />
                                </div>
                                <div class="mod-related__link">
                                    <div class="mod-related__content">
                                      
                                        <h3 class="text--bold text--small">
                                            <span>CONCRETE Conference and Exhibition</span>
                                        </h3>

                                        <div class="block__text text--dark">
                                            <span class="dot"></span>
                                            <span>CIMGLOBAL, a leading event management company, successfully concluded the CONCRETE 2017 Conference and Exhibition on Underground Structures, held on July 14-15, 2017 at Hotel the Royal Plaza in New Delhi...</span>
                                        </div>
                                    </div>

                                    <span>
                                        <span class="link__text">Read more</span>
                                        <span class="icon icon--middle icon-arrow-right"></span>
                                    </span>
                                </div>
                            </a>
                        </article>
                    </li>
            </ul>
        </div>
    </div>
        </>
    )
}

export default ExhibitionManagement