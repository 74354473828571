import { Helmet } from "react-helmet"
import Carousel from "../../component/carousel"
import { useEffect, useState } from "react"
import AllBlogList from "../../baseURL/services"
import { Link } from "react-router-dom"

const CaseStudiesAll = ()=>{
   const [data,setData] = useState([])
   const [imageUrl,setImageUrl] = useState('')
   function allBlog(){
       AllBlogList.getCaseStudyList().then((result)=>{
           console.log(result)
           setImageUrl(result.data.image_url)
           console.log('data',result.data.image_url)
           setData(result.data.posts)
       })
   }
   useEffect(()=>{
       allBlog()
   },[])
   useEffect(()=>{
      // Scroll to the top of the page
window.scrollTo({
   top: 0,
   behavior: 'smooth' // Optional: smooth scrolling animation
 });
 
   },[])
    return(
        <>
        <Helmet>
                <meta charSet="utf-8" />
                <title>Case Studies</title>
                <link rel="canonical" href="https://cimglobal.net/" />
            </Helmet>
         <Carousel
         mainText={
            `Sustainable Practices at AFITA/WCCA Conference`
         }
         smallText={
            ` `
         }
         images={["img/slide-01.jpg","img/slide-01.jpg","img/slide-01.jpg"]}
         />
        <div className="page-contextual page-contextual--article"  >
        <article className="mod-text mod-text--lead" data-lazy-load>
    <div className="content-wrapper__full mod-text__wrapper">
            <h2 className="heading-1 lg--three-quarters mod-text__header">The case for our outstanding achievements</h2>
        <div className="heading-2 lg--four-sixths mod-text__text">
            <p>Our case stories are testament to the exceptional results we have achieved for our clients over many years of operations.</p>
        </div>

    </div>
</article>    <script src="../sb/bundles_all-cases.js.v638442705763727407"></script>
<div id="see-all-cases" className="react-see-all-cases" data-page-id="7743"></div>
</div>

<div className="content-wrapper__full">

<div className="grid">
{
   data.map((item)=>
   <div className="grid__item md--one-half xlg--two-eighths animated fadeInUp">
      <Link to={`/NewsPage/${item.id}/${item.slug}/caseStudy`}  state={{data:item,img:imageUrl+item.feature_image,case:true}} className="link link--pointing dot__wrapper">
         <div className="block block--in-list">
            <div className="media-wrapper block__media"><img className="img-cover" src={imageUrl+item.feature_image}/></div>
                        <div className="mod-related__link">
               <div className="mod-related__content minimum-height">
                  <div className="text--bold text--small">{item.title}</div>
                  <div className="block__text text--dark"><span className="dot"></span><span className="threelinep">{item.excerpt}</span></div>
               </div>
               <span><span></span><span className="icon icon--middle icon-arrow-right"></span></span>
            </div>
         </div>
      </Link>
   </div>
   )
}
   
</div>
</div>



        </>
    )
}

export default CaseStudiesAll