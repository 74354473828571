import { Helmet } from "react-helmet"
import Carousel from "../component/carousel"
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import { EffectFade, Navigation,Autoplay } from 'swiper/modules';
import News from "../component/news";
// import CarouselVideo from "../component/carouselVideo";
const Home = ()=>{
    return(<>

        {/* <section data-lazy-load className="mod-text-with-ctas mod-text-with-ctas--green">
        <div className="dialog-tool" data-dialog-extra-info="{&quot;isExtended&quot;:false,&quot;description&quot;:null,&quot;linkHeading&quot;:null,&quot;links&quot;:null,&quot;colorScheme&quot;:&quot;green&quot;}">
   <div className="Container__scheme--green___1ebPv Container--mod-link___3wYrW Container___222gB">
      <div className="dialog-tool__intro dialog-tool__intro--small">
         <div className="Intro__wrapper___223lY">
            <h1 className="heading-1 tool-d" data-sr-id="2" >Find a case story to match your expectations                                                    </h1>
            <div className="Intro__spacer___3cPmE"></div>
            <a href="#" className="link--pointing link--pill link--bright tool-d1" data-sr-id="3"><span className="link__animation tool-d2" data-sr-id="4"><span className="link__text">Search now</span><span className="icon icon--middle icon-arrow-right"></span></span></a>
         </div>
      </div>
   </div>
</div>

       
           </section> */}
            <Helmet>
                <meta charSet="utf-8" />
                <title>Home</title>
                <link rel="canonical" href="https://cimglobal.net/" />
            </Helmet>

            <Carousel
         mainText={
            `Creating Content<br/>Connecting People`
         }
         smallText={
            `Welcome to CIMGLOBAL`
         }
         images={["img/sustainability_06.jpg","img/sustainability_07.jpg"]}
         />
 
    {/* <CarouselVideo/> */}

 <section data-lazy-load className="mod-text-with-ctas mod-text-with-ctas--green">
        <div className="dialog-tool" data-dialog-extra-info="{&quot;isExtended&quot;:false,&quot;description&quot;:null,&quot;linkHeading&quot;:null,&quot;links&quot;:null,&quot;colorScheme&quot;:&quot;green&quot;}">
        <a href="/Clients" className="">      
   <div className="Container__scheme--green___1ebPv Container--mod-link___3wYrW Container___222gB conpadding">
      <div className="dialog-tool__intro dialog-tool__intro--small">
      <h2 className="heading-2 u-text-center color-white small-font">
               <span>Our Clients</span>
            </h2>
         <div className="Intro__wrapper___223lY">
           <Swiper
        slidesPerView={5}
        spaceBetween={30}
        navigation={true}
        pagination={{
          clickable: true,
        }}
        autoplay={{
            delay: 1500,
             disableOnInteraction: false,
          }}
          
        breakpoints={{
         250: {
           slidesPerView: 1,
           spaceBetween: 10,
         },
         768: {
           slidesPerView: 3,
           spaceBetween: 40,
         },
         1024: {
           slidesPerView: 4,
           spaceBetween: 20,
         },
       }}

        modules={[EffectFade, Navigation,Autoplay]}
        className="mySwiper"
      >
        <SwiperSlide><img src="img/ACS.png" className="multi-logo"/></SwiperSlide>
        <SwiperSlide><img src="img/ccf.png" className="multi-logo"/></SwiperSlide>
        <SwiperSlide><img src="img/EASL.png" className="multi-logo"/></SwiperSlide>
        <SwiperSlide><img src="img/soa.png" className="multi-logo"/></SwiperSlide>
        <SwiperSlide><img src="img/ieee_logo_w.png" className="multi-logo"/></SwiperSlide>
        <SwiperSlide><img src="img/ISHG_logo.png" className="multi-logo"/></SwiperSlide>
        <SwiperSlide><img src="img/SCAI.png" className="multi-logo"/></SwiperSlide>
        <SwiperSlide><img src="img/who.png" className="multi-logo"/></SwiperSlide>
        <SwiperSlide><img src="img/ifa.png" className="multi-logo"/></SwiperSlide>
        <SwiperSlide><img src="img/SAARC.png" className="multi-logo"/></SwiperSlide>
        <SwiperSlide><img src="img/asme.png" className="multi-logo"/></SwiperSlide>
        <SwiperSlide><img src="img/amca.png" className="multi-logo"/></SwiperSlide>
        <SwiperSlide><img src="img/dia.png" className="multi-logo"/></SwiperSlide>
        <SwiperSlide><img src="img/amalogo.png" className="multi-logo"/></SwiperSlide>
        <SwiperSlide><img src="img/bicsi-advancing-the-information-and-technology-profession.png" className="multi-logo"/></SwiperSlide>

        
        
              </Swiper>
         </div>
      </div>
   </div>
   </a>
</div>

       
           </section>
    <div className="grid grid--flex mod__container">
   <div className="white-bg-layer grid__item md--one-half">
      <article className="mod mod--inner dot__wrapper mod-overview mod-overview--Normal" data-lazy-load>
         <div className="mod-overview__media">
            <img className="img-cover" src="/img/manage.jpg" alt="Our comprehensive services cover every aspect of conference and association management"/> 
         </div>
         <div className="mod-overview__content lg--two-thirds">
            <div className="mod-overview__content-overlay">
               <p></p>
            </div>
            <h2 className="heading-2">
               <span>Manage</span>
            </h2>
            <div className="mod-overview__content-wrapper">
               <div className="mod-overview__lead text--medium">
                  <span className="dot__animation"><span className="dot dot--light"></span></span>
                  <span className="mod-overview__lead-text">
                     <div id="desktopHome" className="clsDesktopHome">
                        <div id="foid:27" className="clsFormContainer" data-client-id="6005">
                           <div className="clsFloatingForm clsLargeDropShadow">
                              <div className="clsContent">
                                 <div className="clsCommentText clsSelectableText" data-client-id="com-13">
                                    <p>Our comprehensive services cover every aspect of conference and association management, from outreach and bidding to exhibitions, meetings, social media, and communication."</p>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </span>
               </div>
               <a href="/globalPresence" className="link--pointing link--pill ">
               <span className="link__animation">
               <span className="link__text">Read more</span>
               <span className="icon icon--middle icon-arrow-right"></span>
               </span>
               </a>
            </div>
         </div>
      </article>
   </div>
   <div className="white-bg-layer grid__item md--one-half">
      <article className="mod mod--inner dot__wrapper mod-overview mod-overview--Dark mode white text" data-lazy-load>
         <div className="mod-overview__media">
            <img className="img-cover" src="/img/co-create_01.jpg" alt="Co-Create"/> 
         </div>
         <div className="mod-overview__content lg--two-thirds">
            <div className="mod-overview__content-overlay">
               <p></p>
            </div>
            <h2 className="heading-2">
               <span>Co-create</span>
            </h2>
            <div className="mod-overview__content-wrapper">
               <div className="mod-overview__lead text--medium">
                  <span className="dot__animation"><span className="dot dot--light"></span></span>
                  <span className="mod-overview__lead-text">
                     <div id="desktopHome" className="clsDesktopHome">
                        <div id="foid:27" className="clsFormContainer" data-client-id="6005">
                           <div className="clsFloatingForm clsLargeDropShadow">
                              <div className="clsContent">
                                 <div className="clsCommentText clsSelectableText" data-client-id="com-13">
                                    <p>Our co-create services foster collaboration and risk-sharing to build innovative events tailored to partners' audiences, market trends, and needs.</p>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </span>
               </div>
               <a href="/Cocreate" className="link--pointing link--pill ">
               <span className="link__animation">
               <span className="link__text">Read more</span>
               <span className="icon icon--middle icon-arrow-right"></span>
               </span>
               </a>
            </div>
         </div>
      </article>
   </div>
</div>

<article className="mod mod-overview mod-overview--full-width" data-lazy-load>
   <div className="portal">
      <div className="portal__background">
         <img className="img-cover portal__background-image" src="/img/1920-x-1080-case-stories-hero.jpg" alt="Casestory 1920 X 1080"/> 
      </div>
      <div className="portal__mask">
         <img className="img-cover portal__mask-image" src="/img/1920-x-1080-case-stories-hero.jpg" alt="Casestory 1920 X 1080"/>  
      </div>
   </div>
   <h2 className="mod-overview__header--large lg--one-third heading-1">
      <span>Case stories </span>
   </h2>
   <div className="mod-overview__content mod-overview__content--animated lg--one-half">
      <div className="mod-overview__content-wrapper dot__wrapper">
         <div className="mod-overview__lead text--medium">
            <span className="dot__animation"><span className="dot "></span></span>
            <span className="mod-overview__lead-text">
               <p>Our case stories showcase how our innovative solutions have achieved outstanding results for our clients over many years and countless operations.</p>
            </span>
         </div>
         <a href="/caseStudies" className="link--pointing link--pill ">
         <span className="link__animation">
         <span className="link__text">Read our case stories</span>
         <span className="icon icon--middle icon-arrow-right"></span>
         </span>
         </a>
      </div>
   </div>
</article>


<div className="grid grid--flex mod__container">
    <div className="white-bg-layer grid__item md--one-half">


<article className="mod mod--inner dot__wrapper mod-overview mod-overview--Light" data-lazy-load>

        <div className="mod-overview__media">
            <img className="img-cover" src="/img/our-story-752-x-671.jpg" alt="xOur Story 752 X 671"/>  
        </div>

    <div className="mod-overview__content lg--two-thirds">
        <h2 className="heading-2">
            <span>We are CIMGLOBAL</span>
        </h2>

        <div className="mod-with-media__media mask">
            <div className="video-plug">
                <div className="video-plug__content">
                    <button className="btn btn-play" data-videoHTML-modal-open="modal-1717410335">
                        <span className="video__play_btn"></span>
                    </button>
                        <h2 className="video-plug__title text--small">
                            <span></span> 
                        </h2>
                </div>
                    <div className="video-plug__mask">
                        <img className="video-plug__poster" src="/img/thumnail.png" alt="We Are CIMGlobal"/> 
                    </div>
            </div>
        </div>

        <div className="mod-overview__content-wrapper">
            <div className="mod-overview__lead text--medium">
                <span className="dot__animation"><span className="dot dot--light"></span></span>
                <span className="mod-overview__lead-text"><div id="desktopHome" className="clsDesktopHome">
<div id="foid:27" className="clsFormContainer" data-client-id="6005">
<div className="clsFloatingForm clsLargeDropShadow">
<div className="clsContent">
<div className="clsCommentText clsSelectableText" data-client-id="com-13">
<p>CIMGLOBAL is a leading conference and association management company, specializing in seamless event planning and execution. Our tailored solutions, backed by 27 years of expertise, ensure unforgettable experiences and surpass client expectations.  </p>
</div>
</div>
</div>
</div>
</div></span>
            </div>
                    <a href="/whoweare" className="link--pointing link--pill ">
                        <span className="link__animation">
                            <span className="link__text">Read more</span>
                            <span className="icon icon--middle icon-arrow-right"></span>
                        </span>
                    </a>
        </div>
    </div>
</article>

<section id="modal-1717410335" className="modal  modal--slide-top" aria-hidden="true">
    <div className="modal__overlay" tabindex="-1" data-micromodal-close>
        <div role="dialog" aria-modal="true" aria-labelledby="modal-1-title" className="modal__container">
            <header className="modal__header">
                <h2 id="modal-2-title">
                    <span className="icon icon-video"></span>
                    
                </h2>
                <button className="modal__btn" aria-label="Close modal" data-micromodal-close>
                    <span className="icon icon-close-light" data-micromodal-close></span>
                </button>
            </header>
            <div id="modal-2-content" className="modal__content">
                <div className="media-wrapper">

                    <div className="video">
                        <div className="video__content">
                            <button className="btn btn-play">
                                <span className="icon icon-play"></span>
                            </button>
                            <h2 className="video__title"></h2>
                        </div>
                        <video preload="none" poster="/img/thumnail.png" preload="auto">
                            <source src="https://cimglobal.net/cim.mp4" type="video/mp4"/>
                        </video>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>    </div>

    <div className="white-bg-layer grid__item md--one-half">



<article className="mod mod--inner dot__wrapper mod-overview mod-overview--Dark mode white text" data-lazy-load>
        <div className="mod-overview__media">
            <img className="img-cover" src="/img/sustainability_01.jpg" alt=""/> 
        </div>

    <div className="mod-overview__content lg--two-thirds">
        <div className="mod-overview__content-overlay">
            <p></p>
        </div>
        <h2 className="heading-2">
            <span>Sustainability</span>
        </h2>
        
        <div className="mod-overview__content-wrapper">
            <div className="mod-overview__lead text--medium">
                <span className="dot__animation"><span className="dot dot--light"></span></span>
                <span className="mod-overview__lead-text"><div id="desktopHome" className="clsDesktopHome">
<div id="foid:27" className="clsFormContainer" data-client-id="6005">
<div className="clsFloatingForm clsLargeDropShadow">
<div className="clsContent">
<div className="clsCommentText clsSelectableText" data-client-id="com-13">
<p><span>At CIMGLOBAL, sustainability is woven into our daily practices. From eco-friendly event planning to minimizing waste, we believe every small, right action contributes to significant environmental changes, ensuring a brighter future for generations to come.</span></p>
</div>
</div>
</div>
</div>
</div></span>
            </div>
                <a href="/Sustainability" className="link--pointing link--pill ">
                    <span className="link__animation">
                        <span className="link__text">Read more</span>
                        <span className="icon icon--middle icon-arrow-right"></span>
                    </span>
                </a>
        </div>
    </div>
</article>


    </div>
</div>

    <News/>
    </>)
}

export default Home