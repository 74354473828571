import { Link } from "react-router-dom"
import AllBlogList from "../baseURL/services"
import { useEffect } from "react"
import { useState } from "react"


const News = ()=>{
const [data,setData] = useState([])
const [imageUrl,setImageUrl] = useState('')
function allBlog(){
AllBlogList.getBlogList().then((result)=>{
console.log('data news',result.data.posts)
setImageUrl(result.data.image_url)
console.log('data',result.data.image_url)
setData(result.data.posts)
})
}
useEffect(()=>{
allBlog()
},[])
return(
<>
<div class="grid mod-related mod-related--light mod-related--has-read-more" data-lazy-load>
<div class="grid__item lg--nine-twelfths">
<h2 class="heading-2 mod-related__header grid--flex-center">
<span class="mod-related__header-text">News & Updates</span>
<Link to="/NewsAll" target="" rel="noopener" class="link link--pointing text--small mod-related__header-link text--bold">
<span class="link__text">See all news...</span>
<span class="icon icon--middle icon-arrow-right"></span>
</Link>
</h2>
<ul class="grid grid--flex">
{data.slice(0, 3).map((item)=>
<li class="grid__item md--one-third mod-related__item">
<article class="block text--small mod-related__block">
<Link class="link link--pointing dot__wrapper mod-related__block link--zoom-image" to={`/NewsPage/${item.id}/${item.slug}/blog`} state={{data:item,img:imageUrl+item.feature_image}}>
<div class="media-wrapper block__media block__media--standard">
<img class="img-cover" src={imageUrl+item.feature_image} alt=""/>
</div>
<div class="mod-related__link">
<div class="mod-related__content">
<div class="tag block__tag text--dark">

</div>

<h3 class="text--bold text--small">
<span >{item.title}</span>
</h3>

<div class="block__text text--dark">
<span class="dot"></span>
<span className="threelinep">{item.excerpt}</span>
</div>
</div>

<span>
<span class="link__text">Read more</span>
<span class="icon icon--middle icon-arrow-right"></span>
</span>
</div>
</Link>
</article>
</li> 
)
}
</ul>
</div>
</div>
</>
)
}

export default News