import Carousel from "../../component/carousel"


const CorporateEvents = ()=>{
    return(
        <>
         <Carousel
         mainText={
            `Co-Create: Elevate Your Association's Success with Tailored Events.`
         }
         smallText={
            `Unlock your association's potential with Co-Create by CIMGLOBAL. Tailored events designed to amplify your goals - from boosting membership to igniting community engagement. Let's collaborate and bring your vision to life.`
         }
         images={["img/slide-01.jpg","img/slide-01.jpg","img/slide-01.jpg"]}
         />
        <section className="mod-two-cols" data-lazy-load>

        <div className="">
        
        <div className="grid">
            <div className="grid__item md--two-thirds mod-two-cols--padded">
                <h3 className="heading-1"><span>Corporate Events</span></h3>

                <ul className="list-metrics">
                    <li>
                        <div className="metrics metrics--large metrics--base-info">
                           {/* <div className="metrics__ico">
                                <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80">
                                    <g fill="none" fill-rule="nonzero" stroke="#4D4F53">
                                        <path d="M38.5 40.5H28.08c.275.783.42 1.63.42 2.5 0 3.572-2.44 6.5-5.5 6.5s-5.5-2.928-5.5-6.5c0-.87.145-1.717.42-2.5H7.505C7.768 57.5 21.5 71.232 38.5 71.496V59.535l.717.346c.837.403 1.79.619 2.783.619 3.056 0 5.5-2.037 5.5-4.5s-2.444-4.5-5.5-4.5c-.993 0-1.946.216-2.783.62l-.717.345V40.5zM38.5 40.5V30.08a7.53 7.53 0 0 1-2.5.42c-3.572 0-6.5-2.44-6.5-5.5s2.928-5.5 6.5-5.5c.87 0 1.717.145 2.5.42V9.505C21.5 9.768 7.768 23.5 7.504 40.5H38.5z" />
                                        <path fill="#4D4F53" fill-opacity=".16" d="M42.863 41.125l10.066 2.697a7.53 7.53 0 0 1 .24-2.524c.925-3.45 4.04-5.647 6.995-4.855 2.956.792 4.555 4.252 3.63 7.702a7.53 7.53 0 0 1-1.053 2.306l10.062 2.696c4.144-16.489-5.565-33.307-21.917-37.962L47.79 22.738l-.603-.52a6.414 6.414 0 0 0-2.528-1.318c-2.952-.79-5.84.544-6.478 2.924-.637 2.379 1.197 4.979 4.148 5.77.96.257 1.936.295 2.849.122l.782-.148-.206.769-2.89 10.788z" />
                                    </g>
                                </svg>
                            </div> */}
                            <div className="text--small md--one-whole">
                                <div className="text--bold">Your Vision, Our Expertise, Infinite Possibilities</div>
                                <div>
                                    <span className="dot"></span>
                                      <div>Transforming visions into reality with expert planning, customization, and limitless potential.</div>
                                </div>

                            </div>
                        </div>
                    </li>
                </ul>

                <div className="heading-2">
              <br/>
<h3>Maximize the potential of your association with Co-Create by CIMGLOBAL. </h3>

                </div>
                <p style={{fontSize:"20px",lineHeight:"120%",marginTop:"15px"}}>We understand the hesitations that come with event planning, including financial risk and uncertainty. Co-Create alleviates these concerns by sharing the burden and safeguarding your vision. Together, we navigate the complexities of event management, combining resources, expertise, and risk management. With us by your side, you can confidently pursue ambitious goals, knowing that we're invested in your success. 
</p>
<div className="heading-2">
               <p>Let's turn your aspirations into reality and create something extraordinary together."</p>
                </div>
            </div>
        </div>
    </div>
</section>

<article className="mod-text-media mod-text-media__scheme--Light" data-lazy-load>
    <div className="mod-text-media__wrapper grid">
        <div className="grid  lg--two-thirds md-one-whole">


<div className="grid__item     
     md--one-half">
    <div className="mod-text__media">
        <div className="media-wrapper mod-text__media">
                <div className="mod-overview__media">
                        <a className="link--zoom-image">
                            <img className="img-cover" src="/img/Conference-Bid-Support.jpg" alt="Conference Bid Support"/>  
                        </a>   
                </div>
        </div>
    </div>
</div>
            <div className="grid__item md--one-half">
                <div className="mod-text__content">
                    <h3 className="heading-3 mod-text__header mod-text-media__header">Your association can benefit from:</h3>
                    <div className="text--large mod-text-media__content">
                    <ul style={{listStyle:"disc",marginLeft:"25px",fontSize:"20px",lineHeight:"120%",marginTop:"5px"}}>
    <li><b>Collaborative Ideation Sessions:</b> We facilitate collaborative brainstorming sessions with association stakeholders to gather insights, ideas, and feedback. By involving key stakeholders in the planning process, we ensure that the event reflects the collective vision and priorities of your organization.</li>
    <li><b>Strategic Goal Alignment:</b> Co-Create ensures that every element of the event is aligned with your association's strategic objectives. Whether it's increasing membership, fostering community engagement, or advancing your mission, our team works closely with you to ensure that the event serves as a catalyst for achieving your goals.</li>
    <li><b>Customizable Event Planning:</b> Tailored event solutions designed to meet the unique needs and objectives of your association. From venue selection to program development, every aspect is meticulously crafted to align with your vision and goals.</li>
</ul>
                          
                    </div>
                       
                </div>
            </div>
        </div>
    </div>
</article>

<article className="mod-text-media mod-text-media__scheme--Light" data-lazy-load>
    <div className="mod-text-media__wrapper grid">
        <div className="grid push--lg--one-third lg--two-thirds md-one-whole">

            <div className="grid__item md--one-half">
                <div className="mod-text__content">
                    <h3 className="heading-3 mod-text__header mod-text-media__header">Benefits of Co-Create:</h3>
                    <div className="text--large mod-text-media__content">
                    <ul style={{listStyle:"disc",marginLeft:"25px",fontSize:"20px",lineHeight:"120%",marginTop:"15px"}}>
    <li><b>Time Savings:</b> Co-Create streamlines the event planning process, allowing association leaders to focus on core responsibilities while our expert team handles the intricate details.</li>
    <li><b>Stress Reduction:</b>  With CIMGLOBAL as your partner, say goodbye to event-related stress. We manage every aspect of the event with precision and care, ensuring a seamless experience from start to finish.</li>
    <li><b>Tailored Solutions:</b>  Each event is meticulously tailored to meet the unique needs and goals of your association, guaranteeing that every detail aligns with your vision and objectives.</li>
    <li><b>Strategic Alignment:</b>  Our collaborative approach ensures that every aspect of the event is strategically aligned with your association's goals, contributing to the achievement of your overarching objectives.</li>
    <li><b>Expertise and Guidance:</b>  Benefit from our extensive experience and industry expertise as we guide you through the event planning process, offering valuable insights and recommendations along the way.</li>
    <li><b>Dedicated Support:</b>  With CIMGLOBAL as your partner, you'll have access to dedicated support every step of the way. From initial planning to post-event evaluation, we're here to ensure your event is a resounding success.</li>
    <li><b>Exceptional Results:</b>  Expect nothing but exceptional results when you partner with CIMGLOBAL. Our commitment to excellence and attention to detail guarantee that your event will exceed expectations and leave a lasting impression.</li>
    <li><b>Peace of Mind:</b>  Rest easy knowing that your event is in capable hands. With CIMGLOBAL as your partner, you can trust that every aspect of the planning and execution process is being managed with care and professionalism.</li>
  
</ul>
                    </div>
                           
                </div>
            </div>


<div className="grid__item     
     md--one-half">
    <div className="mod-text__media">
        <div className="media-wrapper mod-text__media">
                <div className="mod-overview__media">
                        <a href="#" className="link--zoom-image">
                            <img className="img-cover" src="/img/Conference-Project-Management-and-Delivery.jpg" alt="Conference Project Management and Delivery"/>  
                        </a>   
                </div>
        </div>
    </div>
</div>        </div>
<br/>
<a href="/Contact" className="link--pointing link--pill ">
         <span className="link__animation">
         <span className="link__text">Conact Us</span>
         <span className="icon icon--middle icon-arrow-right"></span>
         </span>
         </a>
    </div>
</article>




<div class="grid mod-related mod-related--light mod-related--has-read-more" data-lazy-load>
        <div class="grid__item lg--nine-twelfths">
            <h2 class="heading-2 mod-related__header grid--flex-center">
                <span class="mod-related__header-text">Latest updates</span>
                    <a href="\NewsAll" target="" rel="noopener" class="link link--pointing text--small mod-related__header-link text--bold">
                        <span class="link__text">See all news, stories and updates</span>
                        <span class="icon icon--middle icon-arrow-right"></span>
                    </a>
            </h2>
            <ul class="grid grid--flex">
                    <li class="grid__item md--one-third mod-related__item">
                        <article class="block text--small mod-related__block">
                            <a class="link link--pointing dot__wrapper mod-related__block link--zoom-image" href="#" title="Read more" rel="noopener">
                                <div class="media-wrapper block__media block__media--standard">
                                        <img class="img-cover" src="/img/istock-636032898-sea-rig.jpg" />
                                </div>
                                <div class="mod-related__link">
                                    <div class="mod-related__content">
                                        <div class="tag block__tag text--dark">
                                            07.12.2023 -
                                            Company news
                                        </div>

                                        <h3 class="text--bold text--small">
                                            <span>Court rules in favour of CIMGlobal to protect patents</span>
                                        </h3>

                                        <div class="block__text text--dark">
                                            <span class="dot"></span>
                                            <span>The Borgarting Court of Appeal in Norway has sided with CIMGlobal and ruled that Altus Intervention has infringed and misused a patent for well intervention that belongs to CIMGlobal Oilfield Services in Stavanger.</span>
                                        </div>
                                    </div>

                                    <span>
                                        <span class="link__text">Read more</span>
                                        <span class="icon icon--middle icon-arrow-right"></span>
                                    </span>
                                </div>
                            </a>
                        </article>
                    </li>
                    <li class="grid__item md--one-third mod-related__item">
                        <article class="block text--small mod-related__block">
                            <a class="link link--pointing dot__wrapper mod-related__block link--zoom-image" href="#" title="Read more" rel="noopener">
                                <div class="media-wrapper block__media block__media--standard">
                                        <img class="img-cover" src="/img/seo-794-x396-test-loop.jpg" />
                                </div>
                                <div class="mod-related__link">
                                    <div class="mod-related__content">
                                        <div class="tag block__tag text--dark">
                                            13.11.2023 -
                                            Company news
                                        </div>

                                        <h3 class="text--bold text--small">
                                            <span>Strategic partnership focuses on enhancing CCUS and PtX testing</span>
                                        </h3>

                                        <div class="block__text text--dark">
                                            <span class="dot"></span>
                                            <span>CIMGlobal and FORCE Technology will deliver cutting-edge advanced testing services for Carbon Capture, Utilisation, and Storage (CCUS) and Power-to-X (PtX) markets.</span>
                                        </div>
                                    </div>

                                    <span>
                                        <span class="link__text">Read more</span>
                                        <span class="icon icon--middle icon-arrow-right"></span>
                                    </span>
                                </div>
                            </a>
                        </article>
                    </li>
                    <li class="grid__item md--one-third mod-related__item">
                        <article class="block text--small mod-related__block">
                            <a class="link link--pointing dot__wrapper mod-related__block link--zoom-image" href="#" title="Read more" rel="noopener">
                                <div class="media-wrapper block__media block__media--standard">
                                        <img class="img-cover" src="/img/seo-794-x396-isealate.jpg" />
                                </div>
                                <div class="mod-related__link">
                                    <div class="mod-related__content">
                                        <div class="tag block__tag text--dark">
                                            01.11.2023 -
                                            Company news
                                        </div>

                                        <h3 class="text--bold text--small">
                                            <span>Isealate Springblade Patch excels during in-field testing</span>
                                        </h3>

                                        <div class="block__text text--dark">
                                            <span class="dot"></span>
                                            <span>CIMGLOBAL&#xAE; is pleased to announce the success of the first in-field test of the Isealate Springblade Patch, a unique relining and repair solution for downhole applications.</span>
                                        </div>
                                    </div>

                                    <span>
                                        <span class="link__text">Read more</span>
                                        <span class="icon icon--middle icon-arrow-right"></span>
                                    </span>
                                </div>
                            </a>
                        </article>
                    </li>
            </ul>
        </div>
    </div>
        </>
    )
}

export default CorporateEvents