import Carousel from "../component/carousel"

const Communication = ()=>{
    return(
        <>
         <Carousel
         mainText={
            `Amplifying Impact`
         }
         smallText={
            `Crafting compelling narratives to amplify organizational impact`
         }
         images={["img/sustainability_04.jpg","img/sustainability_04.jpg"]}
        
         />
        <section className="mod-two-cols" data-lazy-load>

        <div className="">
        
        <div className="grid">
            <div className="grid__item md--two-thirds mod-two-cols--padded">
                <h3 className="heading-1"><span>Communication</span></h3>
<p style={{fontSize:"20px",lineHeight:"120%",marginTop:"15px"}}>At CIMGLOBAL, we understand the pivotal role that effective communication plays in shaping the success and reputation of an organization. Our comprehensive communication services are tailored to enhance engagement, foster meaningful connections, and amplify your organization's message both locally and globally.</p>
<p style={{fontSize:"20px",lineHeight:"120%",marginTop:"15px"}}>Our communication services are also tailored to promote your events, ensuring maximum engagement and participation. We employ a variety of strategies, including targeted email campaigns, social media promotion, press releases, and content marketing, to spread awareness and generate interest in your event. By leveraging our expertise in conference promotion, we help enhance visibility, attract attendees, and ensure the success of your event.</p>
            </div>
        </div>
    </div>
</section>

<article className="mod-text-media mod-text-media__scheme--Light" data-lazy-load>
    <div className="mod-text-media__wrapper grid">
        <div className="grid  lg--two-thirds md-one-whole">


<div className="grid__item     
     md--one-half">
    <div className="mod-text__media">
        <div className="media-wrapper mod-text__media">
                <div className="mod-overview__media">
                        <a className="link--zoom-image">
                            <img className="img-cover" src="/img/Communication-Service.jpg" alt="Communication Service"/>  
                        </a>   
                </div>
        </div>
    </div>
</div>
            <div className="grid__item md--one-half">
                <div className="mod-text__content">
                    <h3 className="heading-3 mod-text__header mod-text-media__header">Benefit:</h3>
                    <div className="text--large mod-text-media__content">
                    <ul style={{listStyle:"disc",marginLeft:"25px",fontSize:"20px",lineHeight:"120%",marginTop:"5px"}}>
                    <li><b>Enhanced Engagement:</b> Our communication strategies are aimed at fostering meaningful engagement with your audience, driving interest and participation in your organization's initiatives.</li>

<li><b>Increased Visibility:</b> By amplifying your organization's message through various channels, we help increase your visibility and reach, ensuring that your voice is heard by a wider audience.</li>

<li><b>Improved Reputation:</b> Through transparent and authentic communication, we help build and maintain a positive reputation for your organization, enhancing trust and credibility among stakeholders.</li>

<li><b>Global Reach:</b> With a focus on both local and global communication strategies, we help expand your organization's reach and influence, connecting you with audiences around the world.</li>

<li><b>Increased Attendance:</b> Clear and engaging communication helps attract more attendees to your conference, leading to a larger and more diverse audience.</li>

<li><b>Improved Sponsorship Opportunities:</b> Clear communication about the conference's value proposition and audience demographics can attract sponsors, leading to enhanced financial support and resources.</li>

<li><b>Successful Post-Event Follow-up:</b> Effective communication extends beyond the conference itself, ensuring successful post-event follow-up, feedback collection, and ongoing engagement with participants.</li>

</ul>
                          
                    </div>
                       
                </div>
            </div>
        </div>
    </div>
</article>

<article className="mod-text-media mod-text-media__scheme--Light" data-lazy-load>
    <div className="mod-text-media__wrapper grid">
        <div className="grid push--lg--one-third lg--two-thirds md-one-whole">

            <div className="grid__item md--one-half">
                <div className="mod-text__content">
                    <h3 className="heading-3 mod-text__header mod-text-media__header">Services Include:</h3>
                    <div className="text--large mod-text-media__content">
                    <ul style={{listStyle:"disc",marginLeft:"25px",fontSize:"20px",lineHeight:"120%",marginTop:"15px"}}>
                    <li><b>Strategic Communication Planning:</b> Developing tailored communication strategies aligned with your organization's goals and objectives to ensure effective messaging before, during, and after the conference.</li>

<li><b>Content Creation:</b> Crafting compelling content, including press releases, articles, blog posts, and social media posts, to effectively convey your conference's key messages and engage your target audience.</li>

<li><b>Media Relations:</b> Building and nurturing relationships with media outlets to secure coverage and generate positive publicity for your conference, maximizing its visibility and reach.</li>

<li><b>Digital Marketing:</b> Leveraging digital channels such as social media, email marketing, and online advertising to reach and engage your target audience, driving attendance and participation in the conference.</li>
  
</ul>
                    </div>
                           
                </div>
            </div>


<div className="grid__item     
     md--one-half">
    <div className="mod-text__media">
        <div className="media-wrapper mod-text__media">
                <div className="mod-overview__media">
                        <a href="#" className="link--zoom-image">
                            <img className="img-cover" src="/img/Conference-Project-Management-and-Delivery.jpg" alt="Communication Services"/>  
                        </a>   
                </div>
        </div>
    </div>
</div>        </div>
<br/>
<a href="/Contact" className="link--pointing link--pill ">
         <span className="link__animation">
         <span className="link__text">Conact Us</span>
         <span className="icon icon--middle icon-arrow-right"></span>
         </span>
         </a>
    </div>
</article>


<section id="modal-favorites" className="modal modal--message modal--slide-top" aria-hidden="true">
    <div className="modal__overlay" tabindex="-1" data-micromodal-close>
        <div role="dialog" aria-modal="true" aria-labelledby="modal-favorites-title" className="modal__container">
            <header className="modal__header modal__header--empty">
                <button className="modal__btn" aria-label="Close modal" data-micromodal-close>
                    <span className="icon icon-close-light" data-micromodal-close></span>
                </button>
            </header>
            <div id="modal-favorites-content" className="modal__content text--center">
                <h2 id="modal-favorites-title" className="heading-3">Favorites</h2>
                <div className="modal__text">
                    <p>You have just added an item to your favorites.</p>
<p>You can find it under favorites in the main menu.</p>
                </div>
                <div className="modal__text">
                    <span className="icon icon--big icon-fav-counter "></span>
                </div>
                <div className="modal__text--offset-top input-choice text--small">
                    <input className="input-checkbox" id="hideFavoritesModal" type="checkbox" name="hideFavoritesModal" value=""/>
                    <label for="hideFavoritesModal"> Don&#x27;t show this message again.</label>
                </div>
            </div>
            <div className="modal__footer text--center text--small">
                <p>The favorites list is stored using cookies.</p>
            </div>
        </div>
    </div>
</section>
<div class="grid mod-related mod-related--light mod-related--has-read-more" data-lazy-load>
        <div class="grid__item lg--nine-twelfths">
            <h2 class="heading-2 mod-related__header grid--flex-center">
                <span class="mod-related__header-text">Case Stories</span>
                  
            </h2>
            <ul class="grid grid--flex">
                    <li class="grid__item md--one-third mod-related__item">
                        <article class="block text--small mod-related__block">
                            <a class="link link--pointing dot__wrapper mod-related__block link--zoom-image" href="/ReachingOver200ThousandThroughSocialMediaCampaign" title="Read more" rel="noopener">
                                <div class="media-wrapper block__media block__media--standard">
                                        <img class="img-cover" src="/img/reaching-over-200-thousand-through-social-media-campaign.jpg" />
                                </div>
                                <div class="mod-related__link">
                                    <div class="mod-related__content">
<h3 class="text--bold text--small"> <span>Reaching Over 200 Thousand Through Social Media Campaign</span></h3>
<div class="block__text text--dark">
<span class="dot"></span>
<span>To strategize and manage the entire social media presence, increase awareness and registration numbers</span>
</div>
</div>

<span>
<span class="link__text">Read more</span>
<span class="icon icon--middle icon-arrow-right"></span>
</span>
</div>
</a>
                        </article>
                    </li>
                  
            </ul>
        </div>
    </div>
        </>
    )
}

export default Communication