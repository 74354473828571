import Carousel from "../../component/carousel"

const Sustainability = ()=>{
return(
<>
<Carousel
mainText={
`The Future is Now`
}
smallText={
`Prioritizing environmentally-friendly practices`
}
images={["img/sustainability_01.jpg","img/sustainability_02.jpg"]}
/>
<section className="mod-two-cols" data-lazy-load>

<div className="">

<div className="grid">
<div className="grid__item md--two-thirds mod-two-cols--padded">
<ul className="list-metrics">
<li>
<div className="metrics metrics--large metrics--base-info">

<div className="text--small md--one-whole">
<div>
<span className="dot"></span>
<div>At CIMGLOBAL, we prioritize sustainability in conference management by collaborating closely with our clients to identify and implement the best sustainable solutions. From venue selection to waste management and energy conservation, we work hand in hand with our clients partners and providers to minimize environmental outputs and maximize the positive impact of the events.</div>                                </div>

</div>
</div>
</li>
</ul>

</div>
</div>
</div>
</section>

<article className="mod-text-media mod-text-media__scheme--Light" data-lazy-load>
<div className="mod-text-media__wrapper grid">
<div className="grid  lg--two-thirds md-one-whole">

<div className="grid__item md--one-half">
<div className="mod-text__media">
<div className="media-wrapper mod-text__media">
<div className="mod-overview__media">
<a className="link--zoom-image">
<img className="img-cover" src="/img/Conference-Bid-Support.jpg" alt="Conference Bid Support"/>  
</a>   
</div>
</div>
</div>
</div>
<div className="grid__item md--one-half">
<div className="mod-text__content">
<h3 className="heading-3 mod-text__header mod-text-media__header">Venue Selection</h3>
<div className="text--large mod-text-media__content">
<p style={{fontSize:"20px",lineHeight:"120%",marginTop:"5px"}}>
We prioritize selecting venues that align with sustainability goals, opting for locations with eco-certifications, green building designs, and easy access to public transportation, minimizing the carbon footprint associated with event travel. In Catering we prioritize choosing locally-sourced, seasonal, and organic food options to reduce the carbon footprint associated with catering.</p>

</div>

</div>
</div>
</div>
</div>
</article>

<article className="mod-text-media mod-text-media__scheme--Light" data-lazy-load>
<div className="mod-text-media__wrapper grid">
<div className="grid push--lg--one-third lg--two-thirds md-one-whole">

<div className="grid__item md--one-half">
<div className="mod-text__content">
<h3 className="heading-3 mod-text__header mod-text-media__header">Waste Reduction</h3>
<div className="text--large mod-text-media__content">
<p style={{fontSize:"20px",lineHeight:"120%",marginTop:"5px"}}>
We strive to work closely with our clients, partners, and solution providers to implement the best practices in waste management. This includes comprehensive strategies encompassing recycling, composting, and minimizing single-use plastics. Additionally, we incorporate green initiatives like tree planting into conference activities, fostering environmental sustainability.</p>
</div>

</div>
</div>


<div className="grid__item md--one-half">
<div className="mod-text__media">
<div className="media-wrapper mod-text__media">
<div className="mod-overview__media">
<a href="#" className="link--zoom-image">
<img className="img-cover" src="/img/Waste-Reduction.jpg" alt="Waste Reduction"/>  
</a>   
</div>
</div>
</div>
</div>        </div>
</div>
</article>

<article className="mod-text-media mod-text-media__scheme--Light" data-lazy-load>
<div className="mod-text-media__wrapper grid">
<div className="grid  lg--two-thirds md-one-whole">


<div className="grid__item     
md--one-half">
<div className="mod-text__media">
<div className="media-wrapper mod-text__media">
<div className="mod-overview__media">
<a className="link--zoom-image">
<img className="img-cover" src="/img/Energy-Efficiency.jpg" alt="Energy Efficiency"/>  
</a>   
</div>
</div>
</div>
</div>
<div className="grid__item md--one-half">
<div className="mod-text__content">
<h3 className="heading-3 mod-text__header mod-text-media__header">Energy Efficiency</h3>
<div className="text--large mod-text-media__content">
<p style={{fontSize:"20px",lineHeight:"120%",marginTop:"5px"}}>
We actively seek ways to conserve energy by prioritizing energy-efficient lighting and equipment usage, ultimately reducing overall energy consumption during events. Additionally, we promote digital communication methods and minimize paper usage to foster a more sustainable and eco-friendly event environment.</p>

</div>

</div>
</div>
</div>
</div>
</article>





<div class="grid mod-related mod-related--light mod-related--has-read-more" data-lazy-load>
<div class="grid__item lg--nine-twelfths">
<h2 class="heading-2 mod-related__header grid--flex-center">
<span class="mod-related__header-text">Case Studies </span>
{/*    <a href="#" target="" rel="noopener" class="link link--pointing text--small mod-related__header-link text--bold">
<span class="link__text">See all news, stories and updates</span>
<span class="icon icon--middle icon-arrow-right"></span>
</a>*/}
</h2>
<ul class="grid grid--flex">
<li class="grid__item md--one-third mod-related__item">
<article class="block text--small mod-related__block">
<a class="link link--pointing dot__wrapper mod-related__block link--zoom-image" href="#" title="Read more" rel="noopener">
<div class="media-wrapper block__media block__media--standard">
<img class="img-cover" src="/img/istock-636032898-sea-rig.jpg" />
</div>
<div class="mod-related__link">
<div class="mod-related__content">
<div class="tag block__tag text--dark">
29.04.2024 - CIMGlobal
</div>

<h3 class="text--bold text--small">
<span>Sustainable Practices at AFITA/WCCA Conference</span>
</h3>

<div class="block__text text--dark">
<span class="dot"></span>
<span>AFITA/WCCA 2018 is a collaborative scientific program focused on Precision Agriculture, organized by the Asia-Pacific Federation for Information Technology (AFITA), International Network for Information Technology in Agriculture (INFITA), and Indian Society for Agricultural Information Technology (INSAIT). The conference aimed to advance the understanding and application of precision farming techniques to achieve sustainable and environmentally friendly agriculture.</span>
</div>
</div>

<span>
<span class="link__text">Read more</span>
<span class="icon icon--middle icon-arrow-right"></span>
</span>
</div>
</a>
</article>
</li>
<li class="grid__item md--one-third mod-related__item">
<article class="block text--small mod-related__block">
<a class="link link--pointing dot__wrapper mod-related__block link--zoom-image" href="#" title="Read more" rel="noopener">
<div class="media-wrapper block__media block__media--standard">
<img class="img-cover" src="/img/seo-794-x396-test-loop.jpg" />
</div>
<div class="mod-related__link">
<div class="mod-related__content">
<div class="tag block__tag text--dark">
29.04.2024 - CIMGlobal
</div>

<h3 class="text--bold text--small">
<span>Sustainable Practices at the 37th FISITA World Automotive Congress</span>
</h3>

<div class="block__text text--dark">
<span class="dot"></span>
<span>The automotive industry is undergoing significant transformation driven by digital technologies and evolving customer preferences. FISITA 2018, hosted by the Society of Automotive Engineers INDIA, focused on disruptive technologies for affordable and sustainable mobility, addressing key challenges and opportunities in the industry.</span>
</div>
</div>

<span>
<span class="link__text">Read more</span>
<span class="icon icon--middle icon-arrow-right"></span>
</span>
</div>
</a>
</article>
</li>
<li class="grid__item md--one-third mod-related__item">
<article class="block text--small mod-related__block">
<a class="link link--pointing dot__wrapper mod-related__block link--zoom-image" href="#" title="Read more" rel="noopener">
<div class="media-wrapper block__media block__media--standard">
<img class="img-cover" src="/img/seo-794-x396-isealate.jpg" />
</div>
<div class="mod-related__link">
<div class="mod-related__content">
<div class="tag block__tag text--dark">
29.04.2024 - CIMGlobal
</div>

<h3 class="text--bold text--small">
<span>Social Footprints National CSR Awards & Summit</span>
</h3>

<div class="block__text text--dark">
<span class="dot"></span>
<span>CIMGlobal organized the Social Footprints National CSR Awards & Summit, demonstrating a commitment to sustainable nation-building. Held at Vigyan Bhawan on 27th April, the event brought together distinguished personalities to discuss and recognize innovative approaches to corporate social responsibility (CSR).</span>
</div>
</div>

<span>
<span class="link__text">Read more</span>
<span class="icon icon--middle icon-arrow-right"></span>
</span>
</div>
</a>
</article>
</li>
</ul>
</div>
</div>
</>
)
}

export default Sustainability