import Carousel from "../../component/carousel"
import { Helmet } from "react-helmet"
import News from "../../component/news"
import { useLocation, useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import AllBlogList from "../../baseURL/services"

const NewsPage = (prop)=>{
    const location = useLocation()
    const {blogId, case: caseValue} = useParams()
    console.log("blogId",blogId)
   // const item = location.state.data
  // console.log('data img',location.state.case)
    const [data,setData] = useState([])
    const [imageUrl,setImageUrl] = useState('')
    function singalBlog(){
        AllBlogList.singalPost(blogId).then((result)=>{
          //  console.log('data newssss',location.state.img)
            setImageUrl(result.data.image_url)
           
            setData(result.data.post)
        })
    }
    function singalCase(){
        AllBlogList.singalCase(blogId).then((result)=>{
          //  console.log('data newssss',location.state.img)
            setImageUrl(result.data.image_url)
           
            setData(result.data.post)
        })
    }
    
    useEffect(()=>{
        if(caseValue === "caseStudy"){
            console.log("caseValue yes")
            singalCase()
        }else{
            singalBlog()
            console.log("caseValue no",caseValue == "true")
        }
        
    },[blogId])


    
    useEffect(()=>{ document.getElementById('pageHeader').classList.add("header--on-scroll2","header--hover")
        // Scroll to the top of the page
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // Optional: smooth scrolling animation
          });
   
   
       
     },[])
    return(
        <>
        <Helmet>
                <meta charSet="utf-8" />
                <title>{data?.title}</title>
                <link rel="canonical" href="https://cimglobal.net/" />
                {/* <link rel="stylesheet" href="/css/style.css"/>
<link rel="stylesheet" href="/css/style2.css"/> */}
            </Helmet>
         {/* <Carousel
         mainText={
            `GHA-SCAI SHOCK MENA – February 2024, Dubai, UAE`
         }
         smallText={
            ``
         }
         images={["img/slide-01.jpg","img/slide-01.jpg","img/slide-01.jpg"]}
         /> */}
         <div className="" style={{backgroundImage:"url('/img/slide-01.jpg')",height:"150px",paddingTop:"7.3rem",paddingLeft:"2rem"}}>
           {/* <h2 className="heading-2 hero-article__heading" style={{color:"#fff",lineHeight:"90%"}}>{data?.title}<br/>
            <span style={{fontSize:"15px",letterSpacing:1}}>{data?.created_at} | {data?.location}</span></h2>
            */}
            </div>
    
       {/*}             
<section >
<div className="content-wrapper" style={{margin:"0 auto"}}>

<article className="mod-text-media mod-text-media__scheme--Light" data-lazy-load>

        <div className="grid  lg--two-thirds md-one-whole">


            <div className="grid__item">
                <div className="mod-text__content">
                    
                    <div className="text--large mod-text-media__content">
                    <div className="media-wrapper hero-article__media link--zoom-image">
                    <img className="img-cover" src={imageUrl+data?.feature_image} alt=""/></div>

<p style={{fontSize:"20px",lineHeight:"120%",marginTop:"5px"}} className="block-text__subheader" dangerouslySetInnerHTML={{__html:data?.content}}></p>
                          
                    </div>
                       
                </div>
            </div>
        </div>

 
 <div className="grid lg--one-third">
    
            <div className="grid__item">
                <div className="mod-text__content">
                    1111

                       
                </div>
            </div>
        </div>


</article>
    </div>
</section>

*/}


<section>
<div className="">
<div class="grid" data-lazy-load>
<div style={{ maxWidth:"1250px", paddingRight:"25px", paddingLeft:"25px", marginLeft:"auto", marginRight:"auto", marginTop:"10px", marginBottom:"20px"}} class="">
<ul class="grid grid--flex">
<li class="grid__item md--two-third mod-related__item">
<article class="block text--small mod-related__block">



<div style={{paddingRight:"25px", paddingLeft:"20px;"}} className="text--large mod-text-media__content">
<div className="media-wrapper hero-article__media link--zoom-image"><img  className="img-cover" src={imageUrl+data?.feature_image} alt=""/></div>

<p style={{fontSize:"18px",lineHeight:"150%",marginTop:"5px"}} className="block-text__subheader" dangerouslySetInnerHTML={{__html:data?.content}}></p>

{/*} <p style={{fontSize:"18px",lineHeight:"120%",marginTop:"5px"}} className="block-text__subheader"   dangerouslySetInnerHTML={{__html:data?.content}}></p> */}
                          
                    </div>



</article>
</li>


<li class="grid__item md--one-third mod-related__item">
    <br></br>    <br></br>
<article class="block text--small mod-related__block">
<h3 style={{fontSize:"25px",lineHeight:"120%",marginBottom:"15px"}} className="heading-3"><span>Conference Management (PCO)</span></h3>

<h3 style={{fontSize:"16px",   fontWeight:"500", lineHeight:"180%",marginBottom:"5px"}} class="text--bold text--small"> <span className="dot"></span><a href="/globalPresence">Conference Management (PCO)</a> </h3>
<h3 style={{fontSize:"16px",   fontWeight:"500", lineHeight:"180%",marginBottom:"5px"}} class="text--bold text--small"><span className="dot"></span><a href="/associationManagement">Association Consulting</a> </h3>
<h3 style={{fontSize:"16px",   fontWeight:"500", lineHeight:"180%",marginBottom:"5px"}} class="text--bold text--small"> <span className="dot"></span><a href="/exhibitionManagement">Exhibition Management</a> </h3>
<h3 style={{fontSize:"16px",   fontWeight:"500", lineHeight:"180%",marginBottom:"5px"}} class="text--bold text--small"> <span className="dot"></span><a href="/bidding">Bidding</a> </h3>
<h3 style={{fontSize:"16px",   fontWeight:"500", lineHeight:"180%",marginBottom:"5px"}} class="text--bold text--small"> <span className="dot"></span><a href="/outreach">Outreach</a> </h3>
<h3 style={{fontSize:"16px",   fontWeight:"500", lineHeight:"180%",marginBottom:"5px"}} class="text--bold text--small"> <span className="dot"></span><a href="/communication">Communication</a> </h3>
<hr></hr>

<h3 style={{fontSize:"25px",lineHeight:"120%",marginBottom:"15px"}} className="heading-3"><span>Co-Create</span></h3>

<h3 style={{fontSize:"16px",   fontWeight:"500", lineHeight:"180%",marginBottom:"5px"}} class="text--bold text--small"> <span className="dot"></span><a href="/Cocreate">Co-Create</a> </h3>

<hr></hr>

<h3 style={{fontSize:"25px", lineHeight:"120%",marginBottom:"15px"}} className="heading-3"><span>Company</span></h3>

<h3 style={{fontSize:"16px",   fontWeight:"500", lineHeight:"180%",marginBottom:"5px"}} class="text--bold text--small"> <span className="dot"></span><a href="/ourstory">Our story</a> </h3>

<h3 style={{fontSize:"16px",  fontWeight:"500",  lineHeight:"180%",marginBottom:"5px"}} class="text--bold text--small"> <span className="dot"></span><a href="/whoweare">Who we are</a> </h3>

<h3 style={{fontSize:"16px",   fontWeight:"500", lineHeight:"180%",marginBottom:"5px"}} class="text--bold text--small"> <span className="dot"></span><a href="/CorporateGovernance">Corporate governance and policies</a> </h3>

<h3 style={{fontSize:"16px",  fontWeight:"500", lineHeight:"180%",marginBottom:"5px"}} class="text--bold text--small"> <span className="dot"></span><a href="/Sustainability">Sustainability</a> </h3>

<h3 style={{fontSize:"16px",  fontWeight:"500", lineHeight:"180%",marginBottom:"5px"}} class="text--bold text--small"> <span className="dot"></span><a href="/Management">Management</a> </h3>

<h3 style={{fontSize:"16px", fontWeight:"500", lineHeight:"180%",marginBottom:"5px"}} class="text--bold text--small"> <span className="dot"></span><a href="/Career">Career</a> </h3>

<hr></hr>

</article>
</li>
</ul>
</div>
</div>
</div>
</section>

          
<News/>
        </>
    )
}

export default NewsPage