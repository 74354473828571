import Carousel from "../../component/carousel"
import CaseStudiesComponent from "../../component/caseStudies"

const caseStudies = ()=>{
    return(
        <>
         <Carousel
         mainText={
            `Case Stories`
         }
         smallText={
            `Showcasing Transformative Success Stories Globally`
         }
         images={["img/slide-01.jpg","img/slide-01.jpg","img/slide-01.jpg"]}
         />
        <div className="page-contextual page-contextual--article"  >


<article className="article">
            
{/*
<CaseStudiesComponent/>  */}           

    </article>
    <div class="grid mod-related mod-related--light mod-related--has-read-more" data-lazy-load>
        <div class="grid__item lg--nine-twelfths">
            <h2 class="heading-2 mod-related__header grid--flex-center">
                <span class="mod-related__header-text">Case Stori   es</span>
                  
            </h2>
            <ul class="grid grid--flex">
                    <li class="grid__item md--one-third mod-related__item">
                        <article class="block text--small mod-related__block">
                            <a class="link link--pointing dot__wrapper mod-related__block link--zoom-image" href="./docs/Growing-ACS-Membership-in-India.pdf" target="_blank" title="Read more" rel="noopener">
                                <div class="media-wrapper block__media block__media--standard">
                                        <img class="img-cover" src="/img/Growing-ACS-Membership-in-India.jpg" />
                                </div>
                                <div class="mod-related__link">
                                    <div class="mod-related__content">
                                      

                                        <h3 class="text--bold text--small">
                                            <span>Growing ACS Membership in India</span>
                                        </h3>

                                        <div class="block__text text--dark">
                                            <span class="dot"></span>
                                            <span>Building ACS Brand Awareness; Growing ACS membership in India; Managing ACS Events.</span>
                                        </div>
                                    </div>

                                    <span>
                                        <span class="link__text">Read more</span>
                                        <span class="icon icon--middle icon-arrow-right"></span>
                                    </span>
                                </div>
                            </a>
                        </article>
                    </li>
<li class="grid__item md--one-third mod-related__item">
<article class="block text--small mod-related__block">
<a class="link link--pointing dot__wrapper mod-related__block link--zoom-image" href="./docs/AMCA-AssociationManagement-in-ME.pdf" target="_blank" title="Read more" rel="noopener">
<div class="media-wrapper block__media block__media--standard">
<img class="img-cover" src="/img/AMCA-AssociationManagement-in-ME.jpg" />
</div>
<div class="mod-related__link">
<div class="mod-related__content">

<h3 class="text--bold text--small">
<span>AMCA AssociationManagement in ME</span>
</h3>

<div class="block__text text--dark">
<span class="dot"></span>
<span>Establishing AMCA Brand in the Middle East; Advocating for AMCA members; Managing AMCA events.</span>
</div>
</div>

<span>
<span class="link__text">Read more</span>
<span class="icon icon--middle icon-arrow-right"></span>
</span>
</div>
</a>
</article>
</li>

<li class="grid__item md--one-third mod-related__item">
<article class="block text--small mod-related__block">
<a class="link link--pointing dot__wrapper mod-related__block link--zoom-image" href="./docs/SOA-Study-Discovering-Indian-Market-Society-of-Actuaries.pdf" target="_blank" title="Read more" rel="noopener">
<div class="media-wrapper block__media block__media--standard">
<img class="img-cover" src="/img/soa-Discovering-Indian-Market.jpg" />
</div>
<div class="mod-related__link">
<div class="mod-related__content">

<h3 class="text--bold text--small">
<span>AMCA AssociationManagement in ME</span>
</h3>

<div class="block__text text--dark">
<span class="dot"></span>
<span>Establishing AMCA Brand in the Middle East; Advocating for AMCA members; Managing AMCA events.</span>
</div>
</div>

<span>
<span class="link__text">Read more</span>
<span class="icon icon--middle icon-arrow-right"></span>
</span>
</div>
</a>
</article>
</li>
            </ul>
            <br/><br/>
            <ul class="grid grid--flex">
                    <li class="grid__item md--one-third mod-related__item">
                        <article class="block text--small mod-related__block">
                            <a class="link link--pointing dot__wrapper mod-related__block link--zoom-image" href="/AnnualMeetingoftheInternationalSocietyofBloodPurification" title="Read more" rel="noopener">
                                <div class="media-wrapper block__media block__media--standard">
                                        <img class="img-cover" src="/img/Annual-Meeting-of-the-International-Society-of-Blood-Purification.jpg" alt="Bidding and Winning the 37th Annual Meeting of the International Society of Blood Purification (ISBP)" />
                                </div>
                                <div class="mod-related__link">
                                    <div class="mod-related__content">
                                      

                                        <h3 class="text--bold text--small">
                                            <span>Bidding and Winning the 37th Annual Meeting of the International Society of Blood Purification (ISBP)</span>
                                        </h3>

                                        <div class="block__text text--dark">
                                            <span class="dot"></span>
                                            <span>CIMGLOBAL, a premier global meetings and associations management company, has successfully won the bid to manage the 37th Annual Meeting of the International Society of Blood Purification (ISBP) in 2019...</span>
                                        </div>
                                    </div>

                                    <span>
                                        <span class="link__text">Read more</span>
                                        <span class="icon icon--middle icon-arrow-right"></span>
                                    </span>
                                </div>
                            </a>
                        </article>
                    </li>
                    <li class="grid__item md--one-third mod-related__item">
                        <article class="block text--small mod-related__block">
                            <a class="link link--pointing dot__wrapper mod-related__block link--zoom-image" href="/InternationalSTEMFest" title="Read more" rel="noopener">
                                <div class="media-wrapper block__media block__media--standard">
                                        <img class="img-cover" src="/img/3rd-International-STEMFest.jpg" alt="Bidding and Winning the 3rd International STEMFest" />
                                </div>
                                <div class="mod-related__link">
                                    <div class="mod-related__content">
                                        

                                        <h3 class="text--bold text--small">
                                            <span>Bidding and Winning the 3rd International STEMFest</span>
                                        </h3>

                                        <div class="block__text text--dark">
                                            <span class="dot"></span>
                                            <span>The 3rd International Festival of Science, Technology, Engineering & Mathematics (STEMFest) 2016 was a major science and technology event hosted in Mysore, India from October 17-20, 2016...</span>
                                        </div>
                                    </div>

                                    <span>
                                        <span class="link__text">Read more</span>
                                        <span class="icon icon--middle icon-arrow-right"></span>
                                    </span>
                                </div>
                            </a>
                        </article>
                    </li>
                    <li class="grid__item md--one-third mod-related__item">
                        <article class="block text--small mod-related__block">
                            <a class="link link--pointing dot__wrapper mod-related__block link--zoom-image" href="/WWWConference" title="Read more" rel="noopener">
                                <div class="media-wrapper block__media block__media--standard">
                                        <img class="img-cover" src="/img/WWW-Conference-2011.jpg" alt="Bidding and Organizing the WWW Conference " />
                                </div>
                                <div class="mod-related__link">
                                    <div class="mod-related__content">
                                        

                                        <h3 class="text--bold text--small">
                                            <span>Bidding and Organizing the WWW Conference </span>
                                        </h3>

                                        <div class="block__text text--dark">
                                            <span class="dot"></span>
                                            <span>CIMGLOBAL, a leading global meetings and associations management company, has demonstrated its expertise in bidding and organizing major international conferences. One such example is the...</span>
                                        </div>
                                    </div>

                                    <span>
                                        <span class="link__text">Read more</span>
                                        <span class="icon icon--middle icon-arrow-right"></span>
                                    </span>
                                </div>
                            </a>
                        </article>
                    </li>
            </ul>
            <br/><br/>
            <ul class="grid grid--flex">
                    <li class="grid__item md--one-third mod-related__item">
                        <article class="block text--small mod-related__block">
                            <a class="link link--pointing dot__wrapper mod-related__block link--zoom-image" href="/ASIAFORGEMeetingandForgetechIndiaExhibition" title="Read more" rel="noopener">
                                <div class="media-wrapper block__media block__media--standard">
                                        <img class="img-cover" src="/img/ASIAFORGE-Meeting-and-Forgetech-India-Exhibition.jpg" alt="ASIAFORGE Meeting and Forgetech India Exhibition" />
                                </div>
                                <div class="mod-related__link">
                                    <div class="mod-related__content">
                                        <h3 class="text--bold text--small">
                                            <span>ASIAFORGE Meeting and Forgetech India Exhibition</span>
                                        </h3>

                                        <div class="block__text text--dark">
                                            <span class="dot"></span>
                                            <span>The ASIAFORGE Meeting is an international conference organized by the ASIAFORGE organization, which was established in 2005 by the Association of Indian Forging Industry...</span>
                                        </div>
                                    </div>

                                    <span>
                                        <span class="link__text">Read more</span>
                                        <span class="icon icon--middle icon-arrow-right"></span>
                                    </span>
                                </div>
                            </a>
                        </article>
                    </li>
                    <li class="grid__item md--one-third mod-related__item">
                        <article class="block text--small mod-related__block">
                            <a class="link link--pointing dot__wrapper mod-related__block link--zoom-image" href="/WindergyIndiaConferenceandExhibition" title="Read more" rel="noopener">
                                <div class="media-wrapper block__media block__media--standard">
                                        <img class="img-cover" src="/img/Windergy-India-Conference-and-Exhibition.jpg" alt="Windergy India Conference and Exhibition" />
                                </div>
                                <div class="mod-related__link">
                                    <div class="mod-related__content">
                                         <h3 class="text--bold text--small">
                                            <span>Windergy India Conference and Exhibition</span>
                                        </h3>

                                        <div class="block__text text--dark">
                                            <span class="dot"></span>
                                            <span>The Windergy India 2017 conference and exhibition was a landmark event for the wind energy industry in India, held from April 25-27, 2017 in New Delhi. The event was organized...</span>
                                        </div>
                                    </div>

                                    <span>
                                        <span class="link__text">Read more</span>
                                        <span class="icon icon--middle icon-arrow-right"></span>
                                    </span>
                                </div>
                            </a>
                        </article>
                    </li>
                    <li class="grid__item md--one-third mod-related__item">
                        <article class="block text--small mod-related__block">
                            <a class="link link--pointing dot__wrapper mod-related__block link--zoom-image" href="/CONCRETEConferenceandExhibition" title="Read more" rel="noopener">
                                <div class="media-wrapper block__media block__media--standard">
                                        <img class="img-cover" src="/img/CONCRETE-Conference-and-Exhibition.jpg" alt="CONCRETE Conference and Exhibition" />
                                </div>
                                <div class="mod-related__link">
                                    <div class="mod-related__content">
                                      
                                        <h3 class="text--bold text--small">
                                            <span>CONCRETE Conference and Exhibition</span>
                                        </h3>

                                        <div class="block__text text--dark">
                                            <span class="dot"></span>
                                            <span>CIMGLOBAL, a leading event management company, successfully concluded the CONCRETE 2017 Conference and Exhibition on Underground Structures, held on July 14-15, 2017 at Hotel the Royal Plaza in New Delhi...</span>
                                        </div>
                                    </div>

                                    <span>
                                        <span class="link__text">Read more</span>
                                        <span class="icon icon--middle icon-arrow-right"></span>
                                    </span>
                                </div>
                            </a>
                        </article>
                    </li></ul>
                    <br/><br/>
                    <ul class="grid grid--flex">
                    <li class="grid__item md--one-third mod-related__item">
                        <article class="block text--small mod-related__block">
                            <a class="link link--pointing dot__wrapper mod-related__block link--zoom-image" href="/ReachingOver200ThousandThroughSocialMediaCampaign" title="Read more" rel="noopener">
                                <div class="media-wrapper block__media block__media--standard">
                                        <img class="img-cover" src="/img/reaching-over-200-thousand-through-social-media-campaign.jpg" />
                                </div>
                                <div class="mod-related__link">
                                    <div class="mod-related__content">
<h3 class="text--bold text--small"> <span>Reaching Over 200 Thousand Through Social Media Campaign</span></h3>
<div class="block__text text--dark">
<span class="dot"></span>
<span>To strategize and manage the entire social media presence, increase awareness and registration numbers</span>
</div>
</div>

<span>
<span class="link__text">Read more</span>
<span class="icon icon--middle icon-arrow-right"></span>
</span>
</div>
</a>
                        </article>
                    </li>
                    
            </ul>

        </div>
    </div>
</div>
        </>
    )
}

export default caseStudies