import Carousel from "../component/carousel"
import News from "../component/news";
const AssociationManagement = ()=>{
    return(
        <>
         <Carousel
         mainText={
            `Empowering Visionary<br/>Connections`
         }
         smallText={
            `Tailored consulting for associations, societies, and non-profits.​`
         }
         images={["img/AssociationConsulting_01.jpg"]}
         />
        <section className="mod-two-cols" data-lazy-load>

        <div className="">
        
        <div className="grid">
            <div className="grid__item md--two-thirds mod-two-cols--padded">
                <h3 className="heading-1"><span>Association Consulting </span></h3>

               <p style={{fontSize:"20px",lineHeight:"120%",marginTop:"15px"}}>CIMGLOBAL offers management and consulting services tailored to professional associations, societies, foundations, and other not-for-profit entities. Leveraging our extensive experience, personalized approach, and customized solutions, we strategically develop and expand your  organizational network according to your precise specifications and objectives.</p>
</div>
        </div>
    </div>
</section>

<article className="mod-text-media mod-text-media__scheme--Light" data-lazy-load>
    <div className="mod-text-media__wrapper grid">
        <div className="grid  lg--two-thirds md-one-whole">


<div className="grid__item     
     md--one-half">
    <div className="mod-text__media">
        <div className="media-wrapper mod-text__media">
                <div className="mod-overview__media">
                        <a className="link--zoom-image">
                            <img className="img-cover" src="/img/Executive-Services.jpg" alt="Executive Services"/>  
                        </a>   
                </div>
        </div>
    </div>
</div>
            <div className="grid__item md--one-half">
                <div className="mod-text__content">
                    <h3 className="heading-3 mod-text__header mod-text-media__header">Executive Services</h3>
                    <div className="text--large mod-text-media__content">
                    <ul style={{listStyle:"disc",marginLeft:"25px",fontSize:"20px",lineHeight:"120%",marginTop:"5px"}}>
<li>Strategic Planning and Setting up the Operations</li>
<li>Legal & Financial Services necessary for the Operation</li>
<li>Human Resource Deployment, General administration</li>
<li>Conducting meetings (Annual meeting / Board meeting Etc.)</li>
<li>All the relevant Operational aspects</li>
</ul>
                          
                    </div>
                       
                </div>
            </div>
        </div>
    </div>
</article>

<article className="mod-text-media mod-text-media__scheme--Light" data-lazy-load>
    <div className="mod-text-media__wrapper grid">
        <div className="grid push--lg--one-third lg--two-thirds md-one-whole">

            <div className="grid__item md--one-half">
                <div className="mod-text__content">
                    <h3 className="heading-3 mod-text__header mod-text-media__header">Communication & Policy Intervention</h3>
                    <div className="text--large mod-text-media__content">
                    <ul style={{listStyle:"disc",marginLeft:"25px",fontSize:"20px",lineHeight:"120%",marginTop:"5px"}}>
    <li>Advocacy, Policy intervention</li>
    <li>Publication, Newsletter and In-house Documents</li>
    <li>Advertisement, Publicity, Media handling</li>
    <li>Membership promotional activity</li>
    <li>Website design and maintenance</li>
    <li>In-house facility for making Marketing collaterals</li>
</ul>
</div>
                           
                </div>
            </div>


<div className="grid__item     
     md--one-half">
    <div className="mod-text__media">
        <div className="media-wrapper mod-text__media">
                <div className="mod-overview__media">
                        <a href="#" className="link--zoom-image">
                            <img className="img-cover" src="/img/Communication-Policy-Intervention.jpg" alt="Communication & Policy Intervention"/>  
                        </a>   
                </div>
        </div>
    </div>
</div>        </div>
    </div>
</article>
<article className="mod-text-media mod-text-media__scheme--Light" data-lazy-load>
    <div className="mod-text-media__wrapper grid">
        <div className="grid  lg--two-thirds md-one-whole">


<div className="grid__item     
     md--one-half">
    <div className="mod-text__media">
        <div className="media-wrapper mod-text__media">
                <div className="mod-overview__media">
                        <a className="link--zoom-image">
                            <img className="img-cover" src="/img/Financial-Services.jpg" alt="Financial Services"/>  
                        </a>   
                </div>
        </div>
    </div>
</div>
            <div className="grid__item md--one-half">
                <div className="mod-text__content">
                    <h3 className="heading-3 mod-text__header mod-text-media__header">Financial Services</h3>
                    <div className="text--large mod-text-media__content">
                    <ul style={{listStyle:"disc",marginLeft:"25px",fontSize:"20px",lineHeight:"120%",marginTop:"5px"}}>
    <li>Budget planning, Financial monitoring</li>
    <li>Revenue collection & reporting</li>
    <li>Financial Audit as per requirements</li>
    <li>Legal compliances</li>
</ul>
                          
                    </div>
                       
                </div>
            </div>
        </div>
    </div>
</article>
<article className="mod-text-media mod-text-media__scheme--Light" data-lazy-load>
    <div className="mod-text-media__wrapper grid">
        <div className="grid push--lg--one-third lg--two-thirds md-one-whole">

            <div className="grid__item md--one-half">
                <div className="mod-text__content">
                    <h3 className="heading-3 mod-text__header mod-text-media__header">Information Technology</h3>
                    <div className="text--large mod-text-media__content">
                    <ul style={{listStyle:"disc",marginLeft:"25px",fontSize:"20px",lineHeight:"120%",marginTop:"5px"}}>
    <li>Complete ICT implementation</li>
    <li>Multi-Currency payment gateways</li>
    <li>Software tools for Abstract, Technical Paper submissions and peer review.</li>
    <li>Social media campaign</li>
</ul>
</div>
                           
                </div>
            </div>


<div className="grid__item     
     md--one-half">
    <div className="mod-text__media">
        <div className="media-wrapper mod-text__media">
                <div className="mod-overview__media">
                        <a href="#" className="link--zoom-image">
                            <img className="img-cover" src="/img/Information-Technology.jpg" alt="Information Technology"/>  
                        </a>   
                </div>
        </div>
    </div>
</div>        </div>
    </div>
</article>
<article className="mod-text-media mod-text-media__scheme--Light" data-lazy-load>
    <div className="mod-text-media__wrapper grid">
        <div className="grid  lg--two-thirds md-one-whole">


<div className="grid__item     
     md--one-half">
    <div className="mod-text__media">
        <div className="media-wrapper mod-text__media">
                <div className="mod-overview__media">
                        <a className="link--zoom-image">
                            <img className="img-cover" src="/img/Consulting-Services.jpg" alt="Financial Services"/>  
                        </a>   
                </div>
                
        </div>
        
    </div>
    <br/>
<a href="/Contact" className="link--pointing link--pill ">
         <span className="link__animation">
         <span className="link__text">Conact Us</span>
         <span className="icon icon--middle icon-arrow-right"></span>
         </span>
         </a>
</div>
            <div className="grid__item md--one-half">
                <div className="mod-text__content">
                    <h3 className="heading-3 mod-text__header mod-text-media__header">Financial Services</h3>
                    <div className="text--large mod-text-media__content">
                    <ul style={{listStyle:"disc",marginLeft:"25px",fontSize:"20px",lineHeight:"120%",marginTop:"5px"}}>
    <li>Entry Services in new geographies</li>
    <li>Providing Incubation Services for newly set up platform</li>
    <li>Market Research – Analysis & Report</li>
</ul>
                          
                    </div>
                       
                </div>
            </div>
        </div>
    </div>
</article>


<section id="modal-favorites" className="modal modal--message modal--slide-top" aria-hidden="true">
    <div className="modal__overlay" tabindex="-1" data-micromodal-close>
        <div role="dialog" aria-modal="true" aria-labelledby="modal-favorites-title" className="modal__container">
            <header className="modal__header modal__header--empty">
                <button className="modal__btn" aria-label="Close modal" data-micromodal-close>
                    <span className="icon icon-close-light" data-micromodal-close></span>
                </button>
            </header>
            <div id="modal-favorites-content" className="modal__content text--center">
                <h2 id="modal-favorites-title" className="heading-3">Favorites</h2>
                <div className="modal__text">
                    <p>You have just added an item to your favorites.</p>
<p>You can find it under favorites in the main menu.</p>
                </div>
                <div className="modal__text">
                    <span className="icon icon--big icon-fav-counter "></span>
                </div>
                <div className="modal__text--offset-top input-choice text--small">
                    <input className="input-checkbox" id="hideFavoritesModal" type="checkbox" name="hideFavoritesModal" value=""/>
                    <label for="hideFavoritesModal"> Don&#x27;t show this message again.</label>
                </div>
            </div>
            <div className="modal__footer text--center text--small">
                <p>The favorites list is stored using cookies.</p>
            </div>
        </div>
    </div>
</section>

<div class="grid mod-related mod-related--light mod-related--has-read-more" data-lazy-load>
        <div class="grid__item lg--nine-twelfths">
            <h2 class="heading-2 mod-related__header grid--flex-center">
                <span class="mod-related__header-text">Case Stories</span>
                  
            </h2>
            <ul class="grid grid--flex">
                    <li class="grid__item md--one-third mod-related__item">
                        <article class="block text--small mod-related__block">
                            <a class="link link--pointing dot__wrapper mod-related__block link--zoom-image" href="./docs/Growing-ACS-Membership-in-India.pdf" target="_blank" title="Read more" rel="noopener">
                                <div class="media-wrapper block__media block__media--standard">
                                        <img class="img-cover" src="/img/Growing-ACS-Membership-in-India.jpg" />
                                </div>
                                <div class="mod-related__link">
                                    <div class="mod-related__content">
                                      

                                        <h3 class="text--bold text--small">
                                            <span>Growing ACS Membership in India</span>
                                        </h3>

                                        <div class="block__text text--dark">
                                            <span class="dot"></span>
                                            <span>Building ACS Brand Awareness; Growing ACS membership in India; Managing ACS Events.</span>
                                        </div>
                                    </div>

                                    <span>
                                        <span class="link__text">Read more</span>
                                        <span class="icon icon--middle icon-arrow-right"></span>
                                    </span>
                                </div>
                            </a>
                        </article>
                    </li>
<li class="grid__item md--one-third mod-related__item">
<article class="block text--small mod-related__block">
<a class="link link--pointing dot__wrapper mod-related__block link--zoom-image" href="./docs/AMCA-AssociationManagement-in-ME.pdf" target="_blank" title="Read more" rel="noopener">
<div class="media-wrapper block__media block__media--standard">
<img class="img-cover" src="/img/AMCA-AssociationManagement-in-ME.jpg" />
</div>
<div class="mod-related__link">
<div class="mod-related__content">

<h3 class="text--bold text--small">
<span>AMCA AssociationManagement in ME</span>
</h3>

<div class="block__text text--dark">
<span class="dot"></span>
<span>Establishing AMCA Brand in the Middle East; Advocating for AMCA members; Managing AMCA events.</span>
</div>
</div>

<span>
<span class="link__text">Read more</span>
<span class="icon icon--middle icon-arrow-right"></span>
</span>
</div>
</a>
</article>
</li>

<li class="grid__item md--one-third mod-related__item">
<article class="block text--small mod-related__block">
<a class="link link--pointing dot__wrapper mod-related__block link--zoom-image" href="./docs/SOA-Study-Discovering-Indian-Market-Society-of-Actuaries.pdf" target="_blank" title="Read more" rel="noopener">
<div class="media-wrapper block__media block__media--standard">
<img class="img-cover" src="/img/soa-Discovering-Indian-Market.jpg" />
</div>
<div class="mod-related__link">
<div class="mod-related__content">

<h3 class="text--bold text--small">
<span>SOA Discovering India</span>
</h3>

<div class="block__text text--dark">
<span class="dot"></span>
<span>Professional support to the SOA team India visit, designing and ensuring timely meetings.</span>
</div>
</div>

<span>
<span class="link__text">Read more</span>
<span class="icon icon--middle icon-arrow-right"></span>
</span>
</div>
</a>
</article>
</li>
            </ul>
        </div>
    </div>
{/* 
<News/>
*/}
        </>
    )
}

export default AssociationManagement