import Carousel from "../../component/carousel"
import News from "../../component/news";
const Clients = ()=>{
    return(
        <>
         <Carousel
         mainText={
            `Clients`
         }
         smallText={
            ` `
         }
         images={["img/slide-01.jpg"]}
         />

<article className="mod-text-media mod-text-media__scheme--Light" data-lazy-load>
    <div className="mod-text-media__wrapper grid">
        <div className="grid  lg--two-thirds md-one-whole">


            <div className="grid__item md--two-half">
                <div className="mod-text__content">
                    <h3 className="heading-3 mod-text__header mod-text-media__header">Our clients</h3>
                    <div className="text--large mod-text-media__content">
                    With a client list that speaks volumes, CIMGLOBAL prides itself on fostering true partnerships built on trust, respect, and expertise. Since our inception in 1997, we've dedicated ourselves wholeheartedly to our clients, forging long-term relationships and delivering exceptional value. Our track record of success is a testament to our unwavering commitment to meeting and exceeding our clients' needs. At CIMGLOBAL, we prioritize collaboration and understanding, ensuring that every client receives tailored solutions that drive tangible results. Trust us to be your dedicated partner in achieving greatness together.
                          
                    </div>
                       
                </div>
            </div>
        </div>
    </div>
</article>

<article className="mod-text-media mod-text-media__scheme--Light" data-lazy-load>
    <div className="mod-text-media__wrapper grid">
        <div className="grid push--lg--one-third lg--two-thirds md-one-whole">

            <div className="grid__item md--one-full">
                <div className="mod-text__content">
                    <h3 className="heading-3 mod-text__header">Here is the sample of our current and past clients</h3>
                 
<div className="clients">
<div className="clogo"><img className="c-logo" src="/img/clients/able_newlogo.png" alt=""/>  </div>
<div className="clogo"><img className="c-logo" src="/img/clients/ace-school-of-sleep-medicine.png" alt=""/>  </div>
<div className="clogo"><img className="c-logo" src="/img/clients/acs-logo.svg" alt=""/>  </div>
<div className="clogo"><img className="c-logo" src="/img/clients/aifi_association-of-indian-forging-industry.png" alt=""/>  </div>
<div className="clogo"><img className="c-logo" src="/img/clients/air-movement-and-control-association-international-inc-amca-vector-logo.png" alt=""/>  </div>
<div className="clogo"><img className="c-logo" src="/img/clients/all-india-rubber-industries-association.png" alt=""/>  </div>
<div className="clogo"><img className="c-logo" src="/img/clients/amalogo.png" alt=""/>  </div>
<div className="clogo"><img className="c-logo" src="/img/clients/amasi.png" alt=""/>  </div>
<div className="clogo"><img className="c-logo" src="/img/clients/asabe.png" alt=""/>  </div>
<div className="clogo"><img className="c-logo" src="/img/clients/asia-pacific-alliance-of-coaches.png" alt=""/>  </div>
<div className="clogo"><img className="c-logo" src="/img/clients/asme_india-section.png" alt=""/>  </div>
<div className="clogo"><img className="c-logo" src="/img/clients/asme-gas-turbine.png" alt=""/>  </div>
<div className="clogo"><img className="c-logo" src="/img/clients/asrtu.png" alt=""/>  </div>
<div className="clogo"><img className="c-logo" src="/img/clients/association-of-cutaneous-surgeons-(i).png" alt=""/>  </div>
<div className="clogo"><img className="c-logo" src="/img/clients/association-of-medical-physicists-of-india.png" alt=""/>  </div>
<div className="clogo"><img className="c-logo" src="/img/clients/association-of-physicians-of-india.png" alt=""/>  </div>
<div className="clogo"><img className="c-logo" src="/img/clients/association-of-radiation-oncologists-of-india.png" alt=""/>  </div>
<div className="clogo"><img className="c-logo" src="/img/clients/association-of-spine-surgeons-of-india-(-assi-).png" alt=""/>  </div>
<div className="clogo"><img className="c-logo" src="/img/clients/association-of-surgeons-of-india.png" alt=""/>  </div>
<div className="clogo"><img className="c-logo" src="/img/clients/brain-&-spine-society-of-india.png" alt=""/>  </div>

<div className="clogo"><img className="c-logo" src="/img/clients/calcined-clays-in-cement-and-concrete.png" alt=""/>  </div>
<div className="clogo"><img className="c-logo" src="/img/clients/cardiff_university.png" alt=""/>  </div>
<div className="clogo"><img className="c-logo" src="/img/clients/cardiology-society-of-india.png" alt=""/>  </div>
<div className="clogo"><img className="c-logo" src="/img/clients/cerebrovascular-society-of-india.png" alt=""/>  </div>
<div className="clogo"><img className="c-logo" src="/img/clients/cldp-is-a-division-of-the-u.s.-department-of-commerce.png" alt=""/>  </div>
<div className="clogo"><img className="c-logo" src="/img/clients/cldp-is-a-division-of-the-u.s.-department-of-commerce.webp" alt=""/>  </div>
<div className="clogo"><img className="c-logo" src="/img/clients/cleveland_clinic_logo.svg" alt=""/>  </div>
<div className="clogo"><img className="c-logo" src="/img/clients/cmai-association-of-india_communication-multimedia-and-infrsturctural.jpg" alt=""/>  </div>
<div className="clogo"><img className="c-logo" src="/img/clients/council-of-scientific-and-industrial-research.png" alt=""/>  </div>
<div className="clogo"><img className="c-logo" src="/img/clients/delhi-heart-and-lung-institute.png" alt=""/>  </div>
<div className="clogo"><img className="c-logo" src="/img/clients/designer-interior-architect-welfare-association.jpg" alt=""/>  </div>
<div className="clogo"><img className="c-logo" src="/img/clients/diabetes-in-pregnancy-study-group-of-india.png" alt=""/>  </div>
<div className="clogo"><img className="c-logo" src="/img/clients/eha.svg" alt=""/>  </div>
<div className="clogo"><img className="c-logo" src="/img/clients/electron-microscope-society-of-india.png" alt=""/>  </div>
<div className="clogo"><img className="c-logo" src="/img/clients/federation-of-obstetric-and-gynaecological-societies-of-india.png" alt=""/>  </div>
<div className="clogo"><img className="c-logo" src="/img/clients/forgetech-india.png" alt=""/>  </div>
<div className="clogo"><img className="c-logo" src="/img/clients/forum-saude-xxi.png" alt=""/>  </div>
<div className="clogo"><img className="c-logo" src="/img/clients/ga19_delhi_logo_big.jpg" alt=""/>  </div>
<div className="clogo"><img className="c-logo" src="/img/clients/gha_logo_hires-min.png" alt=""/>  </div>
<div className="clogo"><img className="c-logo" src="/img/clients/heritage-foundation-india.png" alt=""/>  </div>
<div className="clogo"><img className="c-logo" src="/img/clients/high-performance-computing,-data,-and-analytics--hipc-logo.svg" alt=""/>  </div>
<div className="clogo"><img className="c-logo" src="/img/clients/indian-society-for-assisted-reproduction.png" alt=""/>  </div>
<div className="clogo"><img className="c-logo" src="/img/clients/iapa-logo.png" alt=""/>  </div>
<div className="clogo"><img className="c-logo" src="/img/clients/ieee_logo.png" alt=""/>  </div>
<div className="clogo"><img className="c-logo" src="/img/clients/ieee_logo.svg" alt=""/>  </div>
<div className="clogo"><img className="c-logo" src="/img/clients/ieee_sensors_council_logo.png" alt=""/>  </div>
<div className="clogo"><img className="c-logo" src="/img/clients/ieee-pune-section-logo.png" alt=""/>  </div>

<div className="clogo"><img className="c-logo" src="/img/clients/indian_council_of_agricultural_research.png" alt=""/>  </div>
<div className="clogo"><img className="c-logo" src="/img/clients/indian_science_congress_association.png" alt=""/>  </div>
<div className="clogo"><img className="c-logo" src="/img/clients/indian_speech_and_hearing_association.png" alt=""/>  </div>
<div className="clogo"><img className="c-logo" src="/img/clients/indian-academy-of-neurology.png" alt=""/>  </div>
<div className="clogo"><img className="c-logo" src="/img/clients/indian-academy-of-pediatrics.png" alt=""/>  </div>
<div className="clogo"><img className="c-logo" src="/img/clients/indian-association-of-cardiovascular-thoracic-surgeons.png" alt=""/>  </div>
<div className="clogo"><img className="c-logo" src="/img/clients/indian-association-of-clinical-cardiologists.png" alt=""/>  </div>
<div className="clogo"><img className="c-logo" src="/img/clients/indian-association-of-surgical-oncology.png" alt=""/>  </div>
<div className="clogo"><img className="c-logo" src="/img/clients/indian-concrete-institute.png" alt=""/>  </div>
<div className="clogo"><img className="c-logo" src="/img/clients/indian-institute-of-welding.png" alt=""/>  </div>
<div className="clogo"><img className="c-logo" src="/img/clients/indian-orthopaedic-association.png" alt=""/>  </div>
<div className="clogo"><img className="c-logo" src="/img/clients/indian-pharmaceutical-association-(ipa).png" alt=""/>  </div>
<div className="clogo"><img className="c-logo" src="/img/clients/indian-prosthodontic-society.png" alt=""/>  </div>
<div className="clogo"><img className="c-logo" src="/img/clients/indian-society-for-pediatric-neurosurgery.png" alt=""/>  </div>
<div className="clogo"><img className="c-logo" src="/img/clients/indian-society-for-prenatal-diagnosis-&-therapy.png" alt=""/>  </div>
<div className="clogo"><img className="c-logo" src="/img/clients/indian-society-for-study-of-pain-1.webp" alt=""/>  </div>
<div className="clogo"><img className="c-logo" src="/img/clients/indian-society-of-blood-transfusion-and-immunohaematology.png" alt=""/>  </div>
<div className="clogo"><img className="c-logo" src="/img/clients/indian-society-of-gastroenterology.png" alt=""/>  </div>
<div className="clogo"><img className="c-logo" src="/img/clients/indian-society-of-medical-and-pediatric-oncology.png" alt=""/>  </div>
<div className="clogo"><img className="c-logo" src="/img/clients/indian-society-of-nephrology.png" alt=""/>  </div>
<div className="clogo"><img className="c-logo" src="/img/clients/indian-society-of-oncology.png" alt=""/>  </div>
<div className="clogo"><img className="c-logo" src="/img/clients/indian-society-of-organ-transplantation-(isot).png" alt=""/>  </div>
<div className="clogo"><img className="c-logo" src="/img/clients/indian-society-of-remote-sensing.png" alt=""/>  </div>
<div className="clogo"><img className="c-logo" src="/img/clients/interim-meeting-of-international-federation-for-neuroendoscopy.png" alt=""/>  </div>
<div className="clogo"><img className="c-logo" src="/img/clients/international-building-performance-simulation-association.png" alt=""/>  </div>
<div className="clogo"><img className="c-logo" src="/img/clients/international-council-on-monuments-and-sites.svg" alt=""/>  </div>
<div className="clogo"><img className="c-logo" src="/img/clients/international-military-testing-association.png" alt=""/>  </div>
<div className="clogo"><img className="c-logo" src="/img/clients/international-nuclear-law-association.png" alt=""/>  </div>

<div className="clogo"><img className="c-logo" src="/img/clients/international-practice-group.png" alt=""/>  </div>
<div className="clogo"><img className="c-logo" src="/img/clients/international-society-of-blood-transfusion.svg" alt=""/>  </div>
<div className="clogo"><img className="c-logo" src="/img/clients/international-union-of-laboratories-and-experts-in-construction-materials,-systems-and-structures.png" alt=""/>  </div>
<div className="clogo"><img className="c-logo" src="/img/clients/ishbt-logo.png" alt=""/>  </div>
<div className="clogo"><img className="c-logo" src="/img/clients/ishrae_racon-2016.png" alt=""/>  </div>
<div className="clogo"><img className="c-logo" src="/img/clients/jaivik-awards.png" alt=""/>  </div>
<div className="clogo"><img className="c-logo" src="/img/clients/mayo-clinic-logo.png" alt=""/>  </div>
<div className="clogo"><img className="c-logo" src="/img/clients/minimally-invasive-spine-surgeons-of-india.png" alt=""/>  </div>
<div className="clogo"><img className="c-logo" src="/img/clients/national-college-of-chest-physicians.png" alt=""/>  </div>
<div className="clogo"><img className="c-logo" src="/img/clients/national-seed-association-of-india.png" alt=""/>  </div>
<div className="clogo"><img className="c-logo" src="/img/clients/neuroendoscopy-committee-of-wfns.png" alt=""/>  </div>
<div className="clogo"><img className="c-logo" src="/img/clients/neuroendoscopy-society-india.png" alt=""/>  </div>
<div className="clogo"><img className="c-logo" src="/img/clients/neurological_society_of_india_(nsi)_logo.png" alt=""/>  </div>
<div className="clogo"><img className="c-logo" src="/img/clients/ophthalmological-society-of-west-bengal.png" alt=""/>  </div>
<div className="clogo"><img className="c-logo" src="/img/clients/pediatric-hematology-and-oncology-chapter-(indian-academy-of-pediatrics).png" alt=""/>  </div>
<div className="clogo"><img className="c-logo" src="/img/clients/peritoneal-dialysis-society-of-india.png" alt=""/>  </div>
<div className="clogo"><img className="c-logo" src="/img/clients/physician.png" alt=""/>  </div>
<div className="clogo"><img className="c-logo" src="/img/clients/rssdi-delhi-chapter.png" alt=""/>  </div>
<div className="clogo"><img className="c-logo" src="/img/clients/SAARC_Logo.png" alt=""/>  </div>
<div className="clogo"><img className="c-logo" src="/img/clients/saeindia.png" alt=""/>  </div>
<div className="clogo"><img className="c-logo" src="/img/clients/scai.svg" alt=""/>  </div>
<div className="clogo"><img className="c-logo" src="/img/clients/scigenom-research-foundation.png" alt=""/>  </div>
<div className="clogo"><img className="c-logo" src="/img/clients/stemfest-2016.png" alt=""/>  </div>
<div className="clogo"><img className="c-logo" src="/img/clients/soa.png" alt=""/>  </div>
<div className="clogo"><img className="c-logo" src="/img/clients/telemedicine-society-of-india.png" alt=""/>  </div>
<div className="clogo"><img className="c-logo" src="/img/clients/the-indian-association-of-gastrointestinal-endosurgeons.png" alt=""/>  </div>
<div className="clogo"><img className="c-logo" src="/img/clients/the-indian-institute-of-architects.png" alt=""/>  </div>
<div className="clogo"><img className="c-logo" src="/img/clients/transportation-research-group-of-india.gif" alt=""/>  </div>
<div className="clogo"><img className="c-logo" src="/img/clients/ursi-logo-lq-white-transparent.png" alt=""/>  </div>
<div className="clogo"><img className="c-logo" src="/img/clients/world-of-concrete_india.png" alt=""/>  </div>



</div>


                    
                           
                </div>
                <br/>
            
            <a href="/Contact" className="link--pointing link--pill ">
                     <span className="link__animation">
                     <span className="link__text">Conact Us</span>
                     <span className="icon icon--middle icon-arrow-right"></span>
                     </span>
                     </a>

            </div>
            <br/>


   </div>
   
    </div>
</article>




<News/>

        </>
    )
}

export default Clients