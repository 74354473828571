import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import i18n from "i18next";
import { useTranslation } from "react-i18next";

// import required modules
import { EffectFade, Navigation, Pagination,Autoplay } from 'swiper/modules';

const Carousel = (prop)=>{
  const { t } = useTranslation();
  console.log("Carousel",prop)
  const images = prop.images
    return(<>
    <section id="pageHero" className="mod hero hero--portal" data-lazy-load>
    <div className="hero__media">
                <div className="hero__slider swiper-container">
                <Swiper
        spaceBetween={30}
        effect={'fade'}
        navigation={false}
        autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
        modules={[EffectFade, Navigation,Autoplay]}
        classNameName="mySwiper"
      >
        {
          images.map((img)=>
          <SwiperSlide>
          <img src={img} />
          <div classNameName='dark-mask'></div>
        </SwiperSlide>
          )
        }
        
        {/* <SwiperSlide>
        <img src="img/slide-01.jpg" />
        </SwiperSlide>
        <SwiperSlide>
        <img src="img/slide-01.jpg" />
        <div classNameName='dark-mask'></div>
        </SwiperSlide>
        <SwiperSlide>
        <img src="img/slide-01.jpg" />
        <div classNameName='dark-mask'></div>
        </SwiperSlide> */}
      </Swiper>
                </div>
    </div>

    <div className="hero__content lg--two-thirds">
    <h1 className="heading-0 hero__heading"><p dangerouslySetInnerHTML={{ __html: t(prop.mainText) }} ></p></h1>
    <div className="hero__lead lg--two-thirds">
        <span className="dotw dotw--large dotw--accent dotw--active"></span>
        <span className="hero__lead-text"><p dangerouslySetInnerHTML={{ __html: t(prop.smallText) }}></p></span>
        <div className="text--small"></div>
    </div>
</div>

  {/*   <div className="hero__content lg--two-thirds">
        <h1 className="heading-0 hero__heading"><p>	{t(prop.mainText)}</p></h1>
        <div className="hero__lead lg--two-thirds">
                <span className="dotw dotw--large dotw--accent dotw--active"></span>
                <span className="hero__lead-text"><p>{t(prop.smallText)}</p></span>
        <div className="text--small">
        </div>
        </div>
    </div> */}
    <div className="hero__btn-scroll">
        <button id="btnScrollHero" className="btn btn-scroll">
            <span className="link__animation">
                <span className="icon icon-arrow-down icon--middle"></span>
            </span>
        </button>
    </div>
</section>

    {/* <div classNameName="banner"></div> */}
    </>)
}

export default Carousel