import Carousel from "../../component/carousel"
import { Helmet } from "react-helmet"

const InternationalSTEMFest = ()=>{
    return(
        <>
        <Helmet>
                <meta charSet="utf-8" />
                <title>Bidding and Winning the 3rd International STEMFest</title>
                <link rel="canonical" href="https://cimglobal.net/" />
            </Helmet>
         <Carousel
         mainText={
            `Bidding services - Elevate Your Global Presence`
         }
         smallText={
            `Decades of experience securing prestigious international conferences with strategic bidding.`
         }
         images={["img/slide-01.jpg","img/slide-01.jpg","img/slide-01.jpg"]}
         />
        <div className="page-contextual page-contextual--article"  >


<nav id="articleNav" className="nav-contextual ">
    <div className="accordion accordion--mobile">
        <div className="nav-contextual__head">
            <span className="heading-5 nav-contextual__current">Go to top</span>
            <button className="btn accordion__btn">
                <span className="icon icon-chevron"></span>
                <span className="u-visually-hidden">Expand</span>     
            </button>
        </div>
        <div className="accordion__content">
            <ul className="nav-contextual__list">
                    <li className="nav-contextual__item dot__wrapper text--xsmall">
                        <a className="nav-contextual__link" href="#top" title="">
                            <span className="dot"></span>
                            Go to top
                        </a>
                    </li>
                    <li className="nav-contextual__item dot__wrapper text--xsmall">
                        <a className="nav-contextual__link" href="#Overview" title="">
                            <span className="dot"></span>
                            Overview
                        </a>
                    </li>
                    <li className="nav-contextual__item dot__wrapper text--xsmall">
                        <a className="nav-contextual__link" href="#Bidding" title="">
                            <span className="dot"></span>
                            Bidding Process
                        </a>
                    </li>
                    <li className="nav-contextual__item dot__wrapper text--xsmall">
                        <a className="nav-contextual__link" href="#Highlights" title="">
                            <span className="dot"></span>
                            Event Highlights
                        </a>
                    </li>
                    <li className="nav-contextual__item dot__wrapper text--xsmall">
                        <a className="nav-contextual__link" href="#CIMglobal" title="">
                            <span className="dot"></span>
                            CIMGLOBAL's Role
                        </a>
                    </li>
                    <li className="nav-contextual__item dot__wrapper text--xsmall">
                        <a className="nav-contextual__link" href="#Impact" title="">
                            <span className="dot"></span>
                            Impact and Outcomes
                        </a>
                    </li>
                    <li className="nav-contextual__item dot__wrapper text--xsmall">
                        <a className="nav-contextual__link" href="#Facts" title="">
                            <span className="dot"></span>
                            Key Facts
                        </a>
                    </li>
                 
            </ul>
           
        </div>
    </div>
</nav>

    <article className="article">
            <div id="top">
                
<section className="hero-article" id="5532">
<div className="content-wrapper">
        <h2 className="heading-2 hero-article__heading">Bidding and Winning the 3rd International STEMFest</h2>

        

    <div className="media-wrapper hero-article__media link--zoom-image">
            <img className="img-cover" src="./img/3rd-International-STEMFest.jpg" alt="Bidding and Winning the 3rd International STEMFest"/></div>
    </div>
</section>
            </div>
            <div id="">
                <section className="mod-two-cols mod-two-cols--no-padding" data-lazy-load>
<div className="content-wrapper">
   {/* <div className="">
            
    </div>  */}
    <div className="">
            <div>
<div id="Overview">
<div className="block-text">
<h3 className="heading-3 block-text__header grid__item md--two-quarters push--md--one-quarter">Overview</h3>
<div className="grid grid--flex">
<aside className="grid__item md--one-quarter block-text__labels">
<ul className="block-text__labels-list">
</ul>
</aside>

<div className="grid__item md--three-quarters block-text__content">
<p className="lg--two-thirds text--large block-text__subheader">
The 3rd International Festival of Science, Technology, Engineering & Mathematics (STEMFest) 2016 was a major science and technology event hosted in Mysore, India from October 17-20, 2016. The event was organized by the Global STEM States, an international body, in association with the University of Mysore.
</p>
<p className="lg--two-thirds text--large block-text__subheader">
CIMGlobal, a leading professional conference management company in India, was selected as the official Professional Conference Organizer (PCO) for the STEMFest 2016 event. The company's expertise in bidding and organizing large-scale international conferences played a crucial role in securing the hosting of this prestigious event in Mysore. 
</p>
</div>
</div>
</div>
</div>
<div id="Bidding">
<div className="block-text">
<h3 className="heading-3 block-text__header grid__item md--two-quarters push--md--one-quarter">Bidding Process</h3>
<div className="grid grid--flex">
<aside className="grid__item md--one-quarter block-text__labels">
<ul className="block-text__labels-list">
</ul>
</aside>

<div className="grid__item md--three-quarters block-text__content">
<p className="lg--two-thirds text--large block-text__subheader">
The decision to host the 3rd International STEMFest 2016 in India was a significant achievement, as the previous editions of the event were held in Canada and Malaysia. The University of Mysore, in collaboration with the Global STEM States, successfully bid to host the event, leveraging CIMGlobal's expertise in the bidding process. 
</p>
<p className="lg--two-thirds text--large block-text__subheader">
CIMGLOBAL's comprehensive understanding of the event management industry, combined with its strong partnerships and local knowledge, gave it a competitive edge in the bidding process. The company's ability to analyze the event's requirements, the target audience, and the host city's capabilities was instrumental in securing the bid for Mysore. 
</p>
</div>
</div>
</div>
</div>
                    <div id="Highlights">
                        
<div className="block-text">
<h3 className="heading-3 block-text__header grid__item md--two-quarters push--md--one-quarter">Event Highlights</h3>
<div className="grid grid--flex">
    <aside className="grid__item md--one-quarter block-text__labels">
            <ul className="block-text__labels-list">
            </ul>
    </aside>

    <div className="grid__item md--three-quarters block-text__content">
            <p className="lg--two-thirds text--large block-text__subheader">
            The 3rd International STEMFest 2016 in Mysore was a resounding success, attracting a diverse range of participants, including renowned international and national speakers, leading institutions, R&D organizations, and <b>over 15,000 visitors</b> for the education and technology exhibition. </p>
            <p className="lg--two-thirds text--large block-text__subheader">
            The event featured a wide range of activities, including symposiums, keynote addresses, workshops, science competitions, and demonstrations of cutting-edge technologies like drones, robots, and game and app development. The program was designed to inspire the next generation to pursue careers in science, technology, engineering, and mathematics (STEM) and to develop the tools and techniques that will drive the economy of the future. </p>
        
       
    </div>
</div>
</div>

                    </div>
<div id="CIMGlobal">
                        
<div className="block-text">
<h3 className="heading-3 block-text__header grid__item md--two-quarters push--md--one-quarter">CIMGLOBAL's Role</h3>
<div className="grid grid--flex">
    <aside className="grid__item md--one-quarter block-text__labels">
            <ul className="block-text__labels-list">
            </ul>
    </aside>

    <div className="grid__item md--three-quarters block-text__content">
            <p className="lg--two-thirds text--large block-text__subheader">
            CIMGlobal's expertise as the official PCO for the STEMFest 2016 event was instrumental in its success. The company's dedicated team handled all aspects of the event management, including logistics coordination, venue management, speaker and sponsor management, marketing and promotion, and on-site coordination. 
            </p>
            <p className="lg--two-thirds text--large block-text__subheader">
            CIMGLOBAL's ability to adapt to the ever-changing dynamics of the event and deliver a seamless experience for the organizers and attendees was widely appreciated. The company's comprehensive event management services and its strong partnerships with local organizations contributed to the overall success of the 3rd International STEMFest 2016 in Mysore. 
            </p>
     
    </div>
</div>
</div>

                    </div>

                    <div id="Impact">
                        
<div className="block-text">
<h3 className="heading-3 block-text__header grid__item md--two-quarters push--md--one-quarter">Impact and Outcomes</h3>
<div className="grid grid--flex">
    <aside className="grid__item md--one-quarter block-text__labels">
            <ul className="block-text__labels-list">
            </ul>
    </aside>

    <div className="grid__item md--three-quarters block-text__content">
            <p className="lg--two-thirds text--large block-text__subheader">
            The successful hosting of the 3rd International STEMFest 2016 in Mysore had a significant impact on the city and the region. The event showcased Mysore's capabilities in hosting large-scale international conferences and attracted global attention to India's prowess in science, technology, engineering, and mathematics. 
            </p>
            <p className="lg--two-thirds text--large block-text__subheader">
            For CIMGLOBAL, the win further solidified its reputation as a leading event management company with expertise in bidding and delivering successful international conferences. The company's ability to leverage its extensive industry knowledge, strong partnerships, and deep understanding of the host city's capabilities has been instrumental in securing prestigious events like the STEMFest 2016. 
            </p>
     
    </div>
</div>
</div>

                    </div>

<div id="Facts">
<div className="block-text">
<h3 className="heading-3 block-text__header grid__item md--two-quarters push--md--one-quarter">Key Facts</h3>
<div className="grid grid--flex">
    <aside className="grid__item md--one-quarter block-text__labels">
            <ul className="block-text__labels-list">
            </ul>
    </aside>

    <div className="grid__item md--three-quarters block-text__content">
            <p className="lg--two-thirds text--large block-text__subheader">
<ul className="block-text__labels-list">
<li>o	The 3rd International Festival of Science, Technology, Engineering & Mathematics (STEMFest) 2016 was hosted in Mysore, India, from October 17-20, 2016.</li>
<li>o	The event was organized by the Global STEM States, an international body, in association with the University of Mysore.</li>
<li>o	CIMGlobal, a leading professional conference management company in India, was selected as the official Professional Conference Organizer (PCO) for the STEMFest 2016 event.</li>
<li>o	The event attracted a diverse range of participants, including renowned international and national speakers, leading institutions, R&D organizations, and over 15,000 visitors for the education and technology exhibition.</li>
<li>o	The event featured a wide range of activities, including symposiums, keynote addresses, workshops, science competitions, and demonstrations of cutting-edge technologies.</li>
<li>o	CIMGlobal's expertise in event management, including logistics coordination, venue management, speaker and sponsor management, and on-site coordination, was instrumental in the success of the STEMFest 2016 event.</li>
<li>o	The successful hosting of the 3rd International STEMFest 2016 in Mysore showcased the city's capabilities in hosting large-scale international conferences and attracted global attention to India's prowess in science, technology, engineering, and mathematics.</li>
            </ul>
            </p>
      
    </div>
</div>
</div>


                    </div>
                  
            </div>
    </div>
</div>
</section>



            </div>
            <div class="grid mod-related mod-related--light mod-related--has-read-more" data-lazy-load>
        <div class="grid__item lg--nine-twelfths">
            <h2 class="heading-2 mod-related__header grid--flex-center">
                <span class="mod-related__header-text">Case Stories</span>
                   
            </h2>
            <ul class="grid grid--flex">
                    <li class="grid__item md--one-third mod-related__item">
                        <article class="block text--small mod-related__block">
                            <a class="link link--pointing dot__wrapper mod-related__block link--zoom-image" href="/AnnualMeetingoftheInternationalSocietyofBloodPurification" title="Read more" rel="noopener">
                                <div class="media-wrapper block__media block__media--standard">
                                        <img class="img-cover" src="/img/Annual-Meeting-of-the-International-Society-of-Blood-Purification.jpg" alt="Bidding and Winning the 37th Annual Meeting of the International Society of Blood Purification (ISBP)" />
                                </div>
                                <div class="mod-related__link">
                                    <div class="mod-related__content">
                                      

                                        <h3 class="text--bold text--small">
                                            <span>Bidding and Winning the 37th Annual Meeting of the International Society of Blood Purification (ISBP)</span>
                                        </h3>

                                        <div class="block__text text--dark">
                                            <span class="dot"></span>
                                            <span>CIMGLOBAL, a premier global meetings and associations management company, has successfully won the bid to manage the 37th Annual Meeting of the International Society of Blood Purification (ISBP) in 2019...</span>
                                        </div>
                                    </div>

                                    <span>
                                        <span class="link__text">Read more</span>
                                        <span class="icon icon--middle icon-arrow-right"></span>
                                    </span>
                                </div>
                            </a>
                        </article>
                    </li>
                    <li class="grid__item md--one-third mod-related__item">
                        <article class="block text--small mod-related__block">
                            <a class="link link--pointing dot__wrapper mod-related__block link--zoom-image" href="/InternationalSTEMFest" title="Read more" rel="noopener">
                                <div class="media-wrapper block__media block__media--standard">
                                        <img class="img-cover" src="/img/3rd-International-STEMFest.jpg" alt="Bidding and Winning the 3rd International STEMFest" />
                                </div>
                                <div class="mod-related__link">
                                    <div class="mod-related__content">
                                        

                                        <h3 class="text--bold text--small">
                                            <span>Bidding and Winning the 3rd International STEMFest</span>
                                        </h3>

                                        <div class="block__text text--dark">
                                            <span class="dot"></span>
                                            <span>The 3rd International Festival of Science, Technology, Engineering & Mathematics (STEMFest) 2016 was a major science and technology event hosted in Mysore, India from October 17-20, 2016...</span>
                                        </div>
                                    </div>

                                    <span>
                                        <span class="link__text">Read more</span>
                                        <span class="icon icon--middle icon-arrow-right"></span>
                                    </span>
                                </div>
                            </a>
                        </article>
                    </li>
                    <li class="grid__item md--one-third mod-related__item">
                        <article class="block text--small mod-related__block">
                            <a class="link link--pointing dot__wrapper mod-related__block link--zoom-image" href="/WWWConference" title="Read more" rel="noopener">
                                <div class="media-wrapper block__media block__media--standard">
                                        <img class="img-cover" src="/img/WWW-Conference-2011.jpg" alt="Bidding and Organizing the WWW Conference " />
                                </div>
                                <div class="mod-related__link">
                                    <div class="mod-related__content">
                                        

                                        <h3 class="text--bold text--small">
                                            <span>Bidding and Organizing the WWW Conference </span>
                                        </h3>

                                        <div class="block__text text--dark">
                                            <span class="dot"></span>
                                            <span>CIMGLOBAL, a leading global meetings and associations management company, has demonstrated its expertise in bidding and organizing major international conferences. One such example is the...</span>
                                        </div>
                                    </div>

                                    <span>
                                        <span class="link__text">Read more</span>
                                        <span class="icon icon--middle icon-arrow-right"></span>
                                    </span>
                                </div>
                            </a>
                        </article>
                    </li>
            </ul>
        </div>
    </div>    
           
    </article>
</div>
        </>
    )
}

export default InternationalSTEMFest