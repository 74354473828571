import Carousel from "../../component/carousel"
import { Helmet } from "react-helmet"
import News from "../../component/news"

const GhaScaiShockMena = ()=>{
    return(
        <>
        <Helmet>
                <meta charSet="utf-8" />
                <title>GHA-SCAI SHOCK MENA – February 2024, Dubai, UAE</title>
                <link rel="canonical" href="https://cimglobal.net/" />
            </Helmet>
         <Carousel
         mainText={
            `GHA-SCAI SHOCK MENA – February 2024, Dubai, UAE`
         }
         smallText={
            ``
         }
         images={["img/slide-01.jpg","img/slide-01.jpg","img/slide-01.jpg"]}
         />
        <div className="page-contextual page-contextual--article"  >




    <article className="article">
            <div id="top">
                
<section className="hero-article" id="5532">
<div className="content-wrapper">
        <h2 className="heading-2 hero-article__heading">GHA-SCAI SHOCK MENA – February 2024, Dubai, UAE</h2>

        

    <div className="media-wrapper hero-article__media link--zoom-image">
            <img className="img-cover" src="./img/SHOCK-MENA-2024.png" alt="GHA-SCAI SHOCK MENA – February 2024, Dubai, UAE"/></div>
    </div>
</section>
            </div>
            <div id="">
                <section className="mod-two-cols mod-two-cols--no-padding" data-lazy-load>
<div className="content-wrapper">
   
    <div className="">
            <div>
<div id="Overview">
<div className="block-text">
{/*<h3 className="heading-3 block-text__header grid__item md--one-whole">Overview</h3>*/}
<div className="grid grid--flex">

<div className="grid__item md--one-whole ">
<p className="text--large block-text__subheader">
CIMGLOBAL has successfully delivered the GHA-SCAI Shock Mena conference at Le Meridien Dubai Hotel and Conferences Centre, a global convergence of Interventional Cardiologists, Cardiovascular Surgeons and Intensivists from diverse nations.
</p>
<p className="text--large block-text__subheader">
CIM GLOBAL facilitated the inaugural collaboration between the Society of Cardiovascular Angiography and Interventions of the US and the Gulf Heart Association, establishing a powerful, first of its kind platform in the MENA region.
</p>
<p className="text--large block-text__subheader">
The two-day conference and workshops garnered remarkable attendance and acclaim.
<br/><br/>
Website: <a href="https://shockmena.com/" target="_blank">www.shockmena.com</a>

</p>
</div>
</div>
</div>
</div>

  {/* 
<div id="Facts">
<div className="block-text">
<h3 className="heading-3 block-text__header grid__item md--one-whole">Key Facts</h3>
<div className="grid grid--flex">
  

    <div className="grid__item md--one-whole">
            <p className="text--large block-text__subheader">
<ul className="block-text__labels-list">
<li>o	The 3rd International Festival of Science, Technology, Engineering & Mathematics (STEMFest) 2016 was hosted in Mysore, India, from October 17-20, 2016.</li>
<li>o	The event was organized by the Global STEM States, an international body, in association with the University of Mysore.</li>
<li>o	CIMGlobal, a leading professional conference management company in India, was selected as the official Professional Conference Organizer (PCO) for the STEMFest 2016 event.</li>
<li>o	The event attracted a diverse range of participants, including renowned international and national speakers, leading institutions, R&D organizations, and over 15,000 visitors for the education and technology exhibition.</li>
<li>o	The event featured a wide range of activities, including symposiums, keynote addresses, workshops, science competitions, and demonstrations of cutting-edge technologies.</li>
<li>o	CIMGlobal's expertise in event management, including logistics coordination, venue management, speaker and sponsor management, and on-site coordination, was instrumental in the success of the STEMFest 2016 event.</li>
<li>o	The successful hosting of the 3rd International STEMFest 2016 in Mysore showcased the city's capabilities in hosting large-scale international conferences and attracted global attention to India's prowess in science, technology, engineering, and mathematics.</li>
            </ul>
            </p>
      
    </div>
</div>
</div>


                    </div>*/}
                  
            </div>
    </div>
</div>
</section>



            </div>
           
           
    </article>
</div>
<News/>
        </>
    )
}

export default GhaScaiShockMena