import { Helmet } from "react-helmet"
import Carousel from "../component/carousel"
import News from "../component/news"

const Cocreate = ()=>{
    return(
        <>
         <Helmet>
                <meta charSet="utf-8" />
                <title>Co-Create</title>
                <link rel="canonical" href="https://cimglobal.net/" />
            </Helmet>
         <Carousel
         mainText={
            `Co-Create: Infinite Possibilities`
         }
         smallText={
            `Transforming visions into reality with expert planning, customization, and limitless potential.`
         }
         images={["img/co-create_02.jpg","img/co-create_01.jpg"]}
         />
        <section className="mod-two-cols" data-lazy-load>

        <div className="">
        
        <div className="grid">
            <div className="grid__item md--two-thirds mod-two-cols--padded">
                <h3 className="heading-1"><span>Co-Create</span></h3>

              

                <div className="heading-2">
              <br/>
<h3>Maximize the potential of your association with Co-Create by CIMGLOBAL. </h3>

                </div>
                <p style={{fontSize:"20px",lineHeight:"120%",marginTop:"15px"}}>We understand the hesitations that come with event planning, including financial risk and uncertainty. Co-Create alleviates these concerns by sharing the burden and safeguarding your vision. Together, we navigate the complexities of event management, combining resources, expertise, and risk management. With us by your side, you can confidently pursue ambitious goals, knowing that we're invested in your success. 
</p>
<div className="heading-2">
               <p>Let's turn your aspirations into reality and create something extraordinary together."</p>
                </div>
            </div>
        </div>
    </div>
</section>

<article className="mod-text-media mod-text-media__scheme--Light" data-lazy-load>
    <div className="mod-text-media__wrapper grid">
        <div className="grid  lg--two-thirds md-one-whole">


<div className="grid__item     
     md--one-half">
    <div className="mod-text__media">
        <div className="media-wrapper mod-text__media">
                <div className="mod-overview__media">
                        <a className="link--zoom-image">
                            <img className="img-cover" src="/img/Benefits-of-Co-Create.jpg" alt="Co-Create"/>  
                        </a>   
                </div>
        </div>
    </div>
</div>
            <div className="grid__item md--one-half">
                <div className="mod-text__content">
                    <h3 className="heading-3 mod-text__header mod-text-media__header">Your association can benefit from:</h3>
                    <div className="text--large mod-text-media__content">
                    <ul style={{listStyle:"disc",marginLeft:"25px",fontSize:"20px",lineHeight:"120%",marginTop:"5px"}}>
    <li><b>Collaborative Ideation Sessions:</b> We facilitate collaborative brainstorming sessions with association stakeholders to gather insights, ideas, and feedback. By involving key stakeholders in the planning process, we ensure that the event reflects the collective vision and priorities of your organization.</li>
    <li><b>Strategic Goal Alignment:</b> Co-Create ensures that every element of the event is aligned with your association's strategic objectives. Whether it's increasing membership, fostering community engagement, or advancing your mission, our team works closely with you to ensure that the event serves as a catalyst for achieving your goals.</li>
    <li><b>Customizable Event Planning:</b> Tailored event solutions designed to meet the unique needs and objectives of your association. From venue selection to program development, every aspect is meticulously crafted to align with your vision and goals.</li>
</ul>
                          
                    </div>
                       
                </div>
            </div>
        </div>
    </div>
</article>

<article className="mod-text-media mod-text-media__scheme--Light" data-lazy-load>
    <div className="mod-text-media__wrapper grid">
        <div className="grid push--lg--one-third lg--two-thirds md-one-whole">

            <div className="grid__item md--one-half">
                <div className="mod-text__content">
                    <h3 className="heading-3 mod-text__header mod-text-media__header">Benefits of Co-Create:</h3>
                    <div className="text--large mod-text-media__content">
                    <ul style={{listStyle:"disc",marginLeft:"25px",fontSize:"20px",lineHeight:"120%",marginTop:"15px"}}>
    <li><b>Time Savings:</b> Co-Create streamlines the event planning process, allowing association leaders to focus on core responsibilities while our expert team handles the intricate details.</li>
    <li><b>Stress Reduction:</b>  With CIMGLOBAL as your partner, say goodbye to event-related stress. We manage every aspect of the event with precision and care, ensuring a seamless experience from start to finish.</li>
    <li><b>Tailored Solutions:</b>  Each event is meticulously tailored to meet the unique needs and goals of your association, guaranteeing that every detail aligns with your vision and objectives.</li>
    <li><b>Strategic Alignment:</b>  Our collaborative approach ensures that every aspect of the event is strategically aligned with your association's goals, contributing to the achievement of your overarching objectives.</li>
    <li><b>Expertise and Guidance:</b>  Benefit from our extensive experience and industry expertise as we guide you through the event planning process, offering valuable insights and recommendations along the way.</li>
    <li><b>Dedicated Support:</b>  With CIMGLOBAL as your partner, you'll have access to dedicated support every step of the way. From initial planning to post-event evaluation, we're here to ensure your event is a resounding success.</li>
    <li><b>Exceptional Results:</b>  Expect nothing but exceptional results when you partner with CIMGLOBAL. Our commitment to excellence and attention to detail guarantee that your event will exceed expectations and leave a lasting impression.</li>
    <li><b>Peace of Mind:</b>  Rest easy knowing that your event is in capable hands. With CIMGLOBAL as your partner, you can trust that every aspect of the planning and execution process is being managed with care and professionalism.</li>
  
</ul>
                    </div>
                           
                </div>
            </div>


<div className="grid__item     
     md--one-half">
    <div className="mod-text__media">
        <div className="media-wrapper mod-text__media">
                <div className="mod-overview__media">
                        <a href="#" className="link--zoom-image">
                            <img className="img-cover" src="/img/co-create_04.jpg" alt="Benefits of Co-Create"/>  
                        </a>   
                </div>
        </div>
    </div>
</div>        </div>
<br/>
<a href="/Contact" className="link--pointing link--pill ">
         <span className="link__animation">
         <span className="link__text">Conact Us</span>
         <span className="icon icon--middle icon-arrow-right"></span>
         </span>
         </a>
    </div>
</article>


<div class="grid mod-related mod-related--light mod-related--has-read-more" data-lazy-load>
        <div class="grid__item lg--nine-twelfths">
            <h2 class="heading-2 mod-related__header grid--flex-center">
                <span class="mod-related__header-text">Case Stories</span>
                  
            </h2>
            <ul class="grid grid--flex">
                    <li class="grid__item md--one-third mod-related__item">
                        <article class="block text--small mod-related__block">
                            <a class="link link--pointing dot__wrapper mod-related__block link--zoom-image" href="/CaseStudyGlobalHealthForum" target="" title="Read more" rel="noopener">
                                <div class="media-wrapper block__media block__media--standard">
                                        <img class="img-cover" src="/img/global-health-forum1.jpg" />
                                </div>
                                <div class="mod-related__link">
                                    <div class="mod-related__content">
                                      

                                        <h3 class="text--bold text--small">
                                            <span>Global Health Forum</span>
                                        </h3>

                                        <div class="block__text text--dark">
                                            <span class="dot"></span>
                                            <span>The Global Health Forum 2023, held on September 29-30 in Cascais, Portugal, was a groundbreaking healthcare</span>
                                        </div>
                                    </div>

                                    <span>
                                        <span class="link__text">Read more</span>
                                        <span class="icon icon--middle icon-arrow-right"></span>
                                    </span>
                                </div>
                            </a>
                        </article>
                    </li>
<li class="grid__item md--one-third mod-related__item">
<article class="block text--small mod-related__block">
<a class="link link--pointing dot__wrapper mod-related__block link--zoom-image" href="/CaseStudyGhaScaiShockMena" target="" title="Read more" rel="noopener">
<div class="media-wrapper block__media block__media--standard">
<img class="img-cover" src="/img/CaseStudyGhaScaiShockMena.jpg" />
</div>
<div class="mod-related__link">
<div class="mod-related__content">

<h3 class="text--bold text--small">
<span>GHA-SCAI SHOCK MENA</span>
</h3>

<div class="block__text text--dark">
<span class="dot"></span>
<span>The GHA-SCAI SHOCK MENA conference, initiated and organized by CIMGLOBAL, is a groundbreaking event...</span>
</div>
</div>

<span>
<span class="link__text">Read more</span>
<span class="icon icon--middle icon-arrow-right"></span>
</span>
</div>
</a>
</article>
</li>

<li class="grid__item md--one-third mod-related__item">
<article class="block text--small mod-related__block">
<a class="link link--pointing dot__wrapper mod-related__block link--zoom-image" href="/TheBestofSCCMCriticalCareCongressinAbuDhabi" target="" title="Read more" rel="noopener">
<div class="media-wrapper block__media block__media--standard">
<img class="img-cover" src="/img/SCCM2019.jpg" />
</div>
<div class="mod-related__link">
<div class="mod-related__content">

<h3 class="text--bold text--small">
<span>The Best of SCCM Critical Care Congress in Abu Dhabi</span>
</h3>

<div class="block__text text--dark">
<span class="dot"></span>
<span>In November 2019, CIMGLOBAL spearheaded the collaboration between the Society of Critical Care ...</span>
</div>
</div>

<span>
<span class="link__text">Read more</span>
<span class="icon icon--middle icon-arrow-right"></span>
</span>
</div>
</a>
</article>
</li>
            </ul>
        </div>
    </div>
{/* 
<News/>
*/}
        </>
    )
}

export default Cocreate