import Carousel from "../../component/carousel"
import { Helmet } from "react-helmet"

const SocialFootprintsNationalCsrAwardsSummit = ()=>{
    return(
        <>
         <Helmet>
                <meta charSet="utf-8" />
                <title>Home</title>
                <link rel="canonical" href="https://cimglobal.net/" />
            </Helmet>
         <Carousel
         mainText={
            `Social Footprints National CSR Awards & Summit`
         }
         smallText={
            ` `
         }
         images={["img/slide-01.jpg","img/slide-01.jpg","img/slide-01.jpg"]}
         />
        <div className="page-contextual page-contextual--article"  >



        <nav id="articleNav" className="nav-contextual ">
    <div className="accordion accordion--mobile">
        <div className="nav-contextual__head">
            <span className="heading-5 nav-contextual__current">Go to top</span>
            <button className="btn accordion__btn">
                <span className="icon icon-chevron"></span>
                <span className="u-visually-hidden">Expand</span>     
            </button>
        </div>
        <div className="accordion__content">
            <ul className="nav-contextual__list">
                    <li className="nav-contextual__item dot__wrapper text--xsmall">
                        <a className="nav-contextual__link" href="#Sustainable-Practices-at-AFITA_WCCA-Conference" title="">
                            <span className="dot"></span>
                            Go to top
                        </a>
                    </li>
                    <li className="nav-contextual__item dot__wrapper text--xsmall">
                        <a className="nav-contextual__link" href="#Overview" title="">
                            <span className="dot"></span>
                            Overview
                        </a>
                    </li>
                    <li className="nav-contextual__item dot__wrapper text--xsmall">
                        <a className="nav-contextual__link" href="#Problem" title="">
                            <span className="dot"></span>
                            Problem
                        </a>
                    </li>
                    <li className="nav-contextual__item dot__wrapper text--xsmall">
                        <a className="nav-contextual__link" href="#Solution" title="">
                            <span className="dot"></span>
                            Solution
                        </a>
                    </li>
                    <li className="nav-contextual__item dot__wrapper text--xsmall">
                        <a className="nav-contextual__link" href="#Impact" title="">
                            <span className="dot"></span>
                            Impact
                        </a>
                    </li>
                    <li className="nav-contextual__item dot__wrapper text--xsmall">
                        <a className="nav-contextual__link" href="#Sustainable" title="">
                            <span className="dot"></span>
                            Key Sustainable Practices: 
                        </a>
                    </li>
                 
            </ul>
           
        </div>
    </div>
</nav>

    <article className="article">
            <div id="Sustainable-Practices-at-AFITA_WCCA-Conference">
                
<section className="hero-article" id="5532">
<div className="content-wrapper">
        <h2 className="heading-2 hero-article__heading">Social Footprints National CSR Awards & Summit</h2>

        

    <div className="media-wrapper hero-article__media link--zoom-image">
            <img className="img-cover" src="./img/Social-Footprints-National-CSR-Awards-Summit.png" alt="Social Footprints National CSR Awards & Summit"/></div>
                </div>
</section>
            </div>
            <div id="">
                <section className="mod-two-cols mod-two-cols--no-padding" data-lazy-load>
<div className="content-wrapper">
 
    <div className="">
            <div>
                    <div id="Overview">
                        
<div className="block-text">
<h3 className="heading-3 block-text__header grid__item md--two-quarters push--md--one-quarter">Overview</h3>
<div className="grid grid--flex">
    <aside className="grid__item md--one-quarter block-text__labels">
            <ul className="block-text__labels-list">
            </ul>
    </aside>

    <div className="grid__item md--three-quarters block-text__content">
            <p className="lg--two-thirds text--large block-text__subheader">
            CIMGlobal organized the Social Footprints National CSR Awards & Summit, demonstrating a commitment to sustainable nation-building. Held at Vigyan Bhawan on 27th April, the event brought together distinguished personalities to discuss and recognize innovative approaches to corporate social responsibility (CSR).
            </p>
      
    </div>
</div>
</div>

                    </div>
                    <div id="Problem">
                        
<div className="block-text">
<h3 className="heading-3 block-text__header grid__item md--two-quarters push--md--one-quarter">Problem</h3>
<div className="grid grid--flex">
    <aside className="grid__item md--one-quarter block-text__labels">
            <ul className="block-text__labels-list">
            </ul>
    </aside>

    <div className="grid__item md--three-quarters block-text__content">
            <p className="lg--two-thirds text--large block-text__subheader">
            Traditional CSR practices often lack accountability and fail to address pressing social and environmental challenges. There is a need to identify and promote innovative CSR initiatives that contribute to sustainable development and inclusive growth.
            </p>
       
    </div>
</div>
</div>

                    </div>
<div id="Solution">
                        
<div className="block-text">
<h3 className="heading-3 block-text__header grid__item md--two-quarters push--md--one-quarter">Solution</h3>
<div className="grid grid--flex">
    <aside className="grid__item md--one-quarter block-text__labels">
            <ul className="block-text__labels-list">
            </ul>
    </aside>

    <div className="grid__item md--three-quarters block-text__content">
            <p className="lg--two-thirds text--large block-text__subheader">
            The Social Footprints National CSR Awards & Summit aimed to recognize institutions making significant contributions to India's economy while promoting responsible CSR practices. By showcasing exemplary CSR projects and facilitating dialogue among industry leaders and experts, the event sought to inspire and encourage the adoption of sustainable CSR strategies.
            </p>
     
    </div>
</div>
</div>

                    </div>
                    <div id="Impact">
                        
<div className="block-text">
<h3 className="heading-3 block-text__header grid__item md--two-quarters push--md--one-quarter">Impact</h3>
<div className="grid grid--flex">
    <aside className="grid__item md--one-quarter block-text__labels">
            <ul className="block-text__labels-list">
            </ul>
    </aside>

    <div className="grid__item md--three-quarters block-text__content">
            <p className="lg--two-thirds text--large block-text__subheader">
            CIMGlobal's initiative highlighted the importance of CSR in driving social and economic development. By bringing together key stakeholders and honoring innovative CSR initiatives, the event fostered collaboration and knowledge sharing, ultimately contributing to a more sustainable and inclusive society.
            </p>
      
    </div>
</div>
</div>
</div>
<div id="Sustainable">
<div className="block-text">
<h3 className="heading-3 block-text__header grid__item md--two-quarters push--md--one-quarter">Key Sustainable Practices:</h3>
<div className="grid grid--flex">
    <aside className="grid__item md--one-quarter block-text__labels">
            <ul className="block-text__labels-list">
            </ul>
    </aside>

    <div className="grid__item md--three-quarters block-text__content">
            <p className="lg--two-thirds text--large block-text__subheader">
            <ul className="block-text__labels-list">
                <li>o	Recognition of Achievements: The awards recognized companies and organizations, such as FIINOVATION and IL&FS Skills Development Corporation Limited, for their exemplary CSR efforts across various sectors.</li>
                <li>o	Distinguished Speakers: Esteemed speakers, including Shri Shekhar Dutt and Mr. Sandeep Chachra, shared insights on the importance of CSR and its role in creating positive social impact.</li>
<li>o	Collaborative Efforts: CIMGlobal led the event to facilitate collaboration among different sectors and motivate them to work towards sustainable development goals, setting a benchmark for future achievements in CSR.</li>
            </ul>
            </p>
      
    </div>
</div>
</div>
<div className="grid__item md--one-whole block-text__content">
            <p className="lg--two-thirds text--large block-text__subheader">
            Through the Social Footprints National CSR Awards & Summit, CIMGlobal demonstrated its commitment to promoting sustainable CSR practices and fostering partnerships for social and economic progress in India.
            </p>
      
    </div>

                    </div>
                  
            </div>
    </div>
</div>
</section>



            </div>
          
           
           
            <div id="module-d0524ada-e3a1-470d-8dd9-a7c028327f13">
                
<div className="mod-related mod-related--light" id="5566" data-lazy-load>
<div className="content-wrapper content-wrapper--right">
    <h2 className="heading-2 mod-related__header grid--flex-center">
        <span className="mod-related__header-text">Case Stories</span>
           
    </h2>

    

<ul className="grid grid--flex">
    <li className="grid__item md--one-third mod-related__item">
        <div className="block text--small mod-related__block">
            <a href="#" className="link link--pointing dot__wrapper mod-related__block">
                <div className="media-wrapper block__media block__media--standard link--zoom-image">
                        <img className="img-cover" src="./img/FISITA-World-Automotive-Congress.jpg" alt="Sustainable Practices at the 37th FISITA World Automotive Congress" />                      </div>
                <div className="mod-related__link">
                    <div className="mod-related__content">
                        <div className="tag block__tag text--dark">
                            Case  -  CIMGlobal
                        </div>

                        <h3 className="text--bold text--small">
                            <span>Sustainable Practices at the 37th FISITA World Automotive Congress</span>
                        </h3>

                        <div className="block__text text--dark">
                            <span className="dot"></span>
                            <span>The automotive industry is undergoing significant transformation driven by digital technologies and evolving customer preferences. FISITA 2018, hosted by the Society of Automotive Engineers.....</span>
                        </div>
                    </div>

                    <span>
                        <span className="link__text"></span>
                        <span className="icon icon--middle icon-arrow-right"></span>
                    </span>
                </div>
            </a>
        </div>
    </li>
    <li className="grid__item md--one-third mod-related__item">
        <div className="block text--small mod-related__block">
            <a href="#" className="link link--pointing dot__wrapper mod-related__block">
                <div className="media-wrapper block__media block__media--standard link--zoom-image">
                        <img className="img-cover" src="./img/Social-Footprints-National-CSR-Awards-Summit.png" alt="Social Footprints National CSR Awards & Summit" /></div>
                <div className="mod-related__link">
                    <div className="mod-related__content">
                        <div className="tag block__tag text--dark">
                            Case challenge -
                            Well construction &amp; integrity
                        </div>

                        <h3 className="text--bold text--small">
                            <span>Social Footprints National CSR Awards & Summit</span>
                        </h3>

                        <div className="block__text text--dark">
                            <span className="dot"></span>
                            <span>CIMGlobal organized the Social Footprints National CSR Awards & Summit, demonstrating a commitment to sustainable nation-building. Held at Vigyan Bhawan on 27th April, the event brought together...</span>
                        </div>
                    </div>

                    <span>
                        <span className="link__text"></span>
                        <span className="icon icon--middle icon-arrow-right"></span>
                    </span>
                </div>
            </a>
        </div>
    </li>
    <li className="grid__item md--one-third mod-related__item">
        <div className="block text--small mod-related__block">
            <a href="#" className="link link--pointing dot__wrapper mod-related__block">
                <div className="media-wrapper block__media block__media--standard link--zoom-image">
                        <img className="img-cover" src="./img/FISITA-World-Automotive-Congress.jpg" alt="Sustainable Practices at the 37th FISITA World Automotive Congress" />                      </div>
                <div className="mod-related__link">
                    <div className="mod-related__content">
                        <div className="tag block__tag text--dark">
                            Case  -  CIMGlobal
                        </div>

                        <h3 className="text--bold text--small">
                            <span>Sustainable Practices at the 37th FISITA World Automotive Congress</span>
                        </h3>

                        <div className="block__text text--dark">
                            <span className="dot"></span>
                            <span>The automotive industry is undergoing significant transformation driven by digital technologies and evolving customer preferences. FISITA 2018, hosted by the Society of Automotive Engineers.....</span>
                        </div>
                    </div>

                    <span>
                        <span className="link__text"></span>
                        <span className="icon icon--middle icon-arrow-right"></span>
                    </span>
                </div>
            </a>
        </div>
    </li>
    
</ul>
</div>
</div>
            </div>
    </article>
</div>
        </>
    )
}


export default SocialFootprintsNationalCsrAwardsSummit