import Carousel from "../component/carousel"

const Bidding = ()=>{
    return(
        <>
         <Carousel
         mainText={
            `Elevate Your Global Presence`
         }
         smallText={
            `Decades of experience securing prestigious international conferences with strategic bidding.`
         }
         images={["img/slide-01.jpg","img/slide-01.jpg","img/slide-01.jpg"]}
         />
        <section className="mod-two-cols" data-lazy-load>

        <div className="">
        
        <div className="grid">
            <div className="grid__item md--two-thirds mod-two-cols--padded">
                <h3 className="heading-1"><span>Bidding</span></h3>

               
                <p style={{fontSize:"20px",lineHeight:"120%",marginTop:"15px"}}>CIMGLOBAL has decades of experience assisting local bodies and associations in attracting important international events through strategic bidding. Our expertise spans various fields, and we have helped numerous organizations secure prestigious conferences. CIMGLOBAL offers strategic bidding assistance tailored to secure international conferences, enhancing your organization's profile and global presence. Our comprehensive approach encompasses meticulous planning, precise execution, and strategic positioning to ensure successful bids.</p>
            </div>
        </div>
    </div>
</section>

<article className="mod-text-media mod-text-media__scheme--Light" data-lazy-load>
    <div className="mod-text-media__wrapper grid">
        <div className="grid  lg--two-thirds md-one-whole">


<div className="grid__item     
     md--one-half">
    <div className="mod-text__media">
        <div className="media-wrapper mod-text__media">
                <div className="mod-overview__media">
                        <a className="link--zoom-image">
                            <img className="img-cover" src="/img/Bidding-Benefits.jpg" alt="Conference Bid Support"/>  
                        </a>   
                </div>
        </div>
    </div>
</div>
            <div className="grid__item md--one-half">
                <div className="mod-text__content">
                    <h3 className="heading-3 mod-text__header mod-text-media__header">Benefits:</h3>
                    <div className="text--large mod-text-media__content">
                    <ul style={{listStyle:"disc",marginLeft:"25px",fontSize:"20px",lineHeight:"120%",marginTop:"5px"}}>
    <li><b>Enhanced Profile:</b> Secure prestigious international conferences to elevate your organization's reputation and visibility on the global stage.</li>
    <li><b>Global Presence:</b> Expand your organization's reach and influence by hosting events that attract participants from around the world.</li>
    <li><b>Knowledge Exchange:</b> Foster meaningful exchanges of ideas, innovations, and expertise by hosting conferences that bring together industry leaders and experts.</li>
    <li><b>Networking Opportunities:</b> Forge valuable connections with international stakeholders, fostering collaborations and partnerships that drive future growth and success.</li>
</ul>
                          
                    </div>
                       
                </div>
            </div>
        </div>
    </div>
</article>

<article className="mod-text-media mod-text-media__scheme--Light" data-lazy-load>
    <div className="mod-text-media__wrapper grid">
        <div className="grid push--lg--one-third lg--two-thirds md-one-whole">

            <div className="grid__item md--one-half">
                <div className="mod-text__content">
                    <h3 className="heading-3 mod-text__header mod-text-media__header">Services Include:</h3>
                    <div className="text--large mod-text-media__content">
                    <ul style={{listStyle:"disc",marginLeft:"25px",fontSize:"20px",lineHeight:"120%",marginTop:"15px"}}>
    <li><b>Market Research:</b> Conduct in-depth market analysis to identify potential conference opportunities and assess their feasibility.</li>
    <li><b>Bid Strategy Development:</b> Develop a customized bidding strategy tailored to your organization's goals, strengths, and target audience.</li>
    <li><b>Proposal Preparation:</b> Craft compelling bid proposals that effectively showcase your organization's capabilities, resources, and commitment to excellence.</li>
    <li><b>Presentation Support:</b> Provide expert guidance and support during the presentation stage to maximize impact and impress selection committees.</li>
    <li><b>Post-Bid Support:</b> Offer post-bid support, including negotiation assistance and contract finalization, to ensure a seamless transition to the conference planning phase.</li>
  
</ul>
                    </div>
                           
                </div>
            </div>


<div className="grid__item     
     md--one-half">
    <div className="mod-text__media">
        <div className="media-wrapper mod-text__media">
                <div className="mod-overview__media">
                        <a href="#" className="link--zoom-image">
                            <img className="img-cover" src="/img/Bidding-Services.jpg" alt="Bidding Service"/>  
                        </a>   
                </div>
        </div>
    </div>
    
</div> 


       </div>

    </div>
    
</article>

<article className="mod-text-media mod-text-media__scheme--Light" data-lazy-load>
    <div className="mod-text-media__wrapper grid">
        <div className="grid push--lg--one-third lg--one-whole md-one-whole">

            <div className="grid__item md--one-half">
                <div className="">
                    <div className="text--large mod-text-media__content">
                    <p style={{fontSize:"20px",lineHeight:"120%",marginTop:"15px"}}>Partner with CIMGLOBAL for strategic bidding assistance that propels your organization to new heights of success in the global conference arena.</p>
                    </div>
                          
                </div>
            </div>
            <br/>
<a href="/Contact" className="link--pointing link--pill ">
         <span className="link__animation">
         <span className="link__text">Conact Us</span>
         <span className="icon icon--middle icon-arrow-right"></span>
         </span>
         </a>




       </div>

    </div>
    
</article>


<div class="grid mod-related mod-related--light mod-related--has-read-more" data-lazy-load>
        <div class="grid__item lg--nine-twelfths">
            <h2 class="heading-2 mod-related__header grid--flex-center">
                <span class="mod-related__header-text">Case Stories</span>
                   
            </h2>
            <ul class="grid grid--flex">
                    <li class="grid__item md--one-third mod-related__item">
                        <article class="block text--small mod-related__block">
                            <a class="link link--pointing dot__wrapper mod-related__block link--zoom-image" href="/AnnualMeetingoftheInternationalSocietyofBloodPurification" title="Read more" rel="noopener">
                                <div class="media-wrapper block__media block__media--standard">
                                        <img class="img-cover" src="/img/Annual-Meeting-of-the-International-Society-of-Blood-Purification.jpg" alt="Bidding and Winning the 37th Annual Meeting of the International Society of Blood Purification (ISBP)" />
                                </div>
                                <div class="mod-related__link">
                                    <div class="mod-related__content">
                                      

                                        <h3 class="text--bold text--small">
                                            <span>Bidding and Winning the 37th Annual Meeting of the International Society of Blood Purification (ISBP)</span>
                                        </h3>

                                        <div class="block__text text--dark">
                                            <span class="dot"></span>
                                            <span>CIMGLOBAL, a premier global meetings and associations management company, has successfully won the bid to manage the 37th Annual Meeting of the International Society of Blood Purification (ISBP) in 2019...</span>
                                        </div>
                                    </div>

                                    <span>
                                        <span class="link__text">Read more</span>
                                        <span class="icon icon--middle icon-arrow-right"></span>
                                    </span>
                                </div>
                            </a>
                        </article>
                    </li>
                    <li class="grid__item md--one-third mod-related__item">
                        <article class="block text--small mod-related__block">
                            <a class="link link--pointing dot__wrapper mod-related__block link--zoom-image" href="/InternationalSTEMFest" title="Read more" rel="noopener">
                                <div class="media-wrapper block__media block__media--standard">
                                        <img class="img-cover" src="/img/3rd-International-STEMFest.jpg" alt="Bidding and Winning the 3rd International STEMFest" />
                                </div>
                                <div class="mod-related__link">
                                    <div class="mod-related__content">
                                        

                                        <h3 class="text--bold text--small">
                                            <span>Bidding and Winning the 3rd International STEMFest</span>
                                        </h3>

                                        <div class="block__text text--dark">
                                            <span class="dot"></span>
                                            <span>The 3rd International Festival of Science, Technology, Engineering & Mathematics (STEMFest) 2016 was a major science and technology event hosted in Mysore, India from October 17-20, 2016...</span>
                                        </div>
                                    </div>

                                    <span>
                                        <span class="link__text">Read more</span>
                                        <span class="icon icon--middle icon-arrow-right"></span>
                                    </span>
                                </div>
                            </a>
                        </article>
                    </li>
                    <li class="grid__item md--one-third mod-related__item">
                        <article class="block text--small mod-related__block">
                            <a class="link link--pointing dot__wrapper mod-related__block link--zoom-image" href="/WWWConference" title="Read more" rel="noopener">
                                <div class="media-wrapper block__media block__media--standard">
                                        <img class="img-cover" src="/img/WWW-Conference-2011.jpg" alt="Bidding and Organizing the WWW Conference " />
                                </div>
                                <div class="mod-related__link">
                                    <div class="mod-related__content">
                                        

                                        <h3 class="text--bold text--small">
                                            <span>Bidding and Organizing the WWW Conference </span>
                                        </h3>

                                        <div class="block__text text--dark">
                                            <span class="dot"></span>
                                            <span>CIMGLOBAL, a leading global meetings and associations management company, has demonstrated its expertise in bidding and organizing major international conferences. One such example is the...</span>
                                        </div>
                                    </div>

                                    <span>
                                        <span class="link__text">Read more</span>
                                        <span class="icon icon--middle icon-arrow-right"></span>
                                    </span>
                                </div>
                            </a>
                        </article>
                    </li>
            </ul>
        </div>
    </div>
        </>
    )
}

export default Bidding