import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import AllBlogList from "../baseURL/services"

const CaseStudiesComponent = () =>{
    const [data,setData] = useState([])
    const [imageUrl,setImageUrl] = useState('')
    function allBlog(){
        AllBlogList.getCaseStudyList().then((result)=>{
            console.log(result)
            setImageUrl(result.data.image_url)
            console.log('data',result.data.image_url)
            setData(result.data.posts)
        })
    }
    useEffect(()=>{
        allBlog()
    },[])
    return(
        <>
         <div id="module-d0524ada-e3a1-470d-8dd9-a7c028327f13">
                
                <div className="mod-related mod-related--light" id="5566" data-lazy-load>
                <div className="content-wrapper content-wrapper--right">
                    <h2 className="heading-2 mod-related__header grid--flex-center">
                        <span className="mod-related__header-text">Similar cases</span>
                            <Link to="/CaseStudiesAll" title="See more Well construction &amp; integrity cases" target="" className="link link--pointing text--small mod-related__header-link text--bold">
                                <span className="link__text">See more cases</span>
                                <span className="icon icon--middle icon-arrow-right"></span>
                            </Link>
                    </h2>
                
                    
                
                <ul className="grid grid--flex">
                {
                  data.slice(0, 3).map((item)=>
                        <li class="grid__item md--one-third mod-related__item">
                        <article class="block text--small mod-related__block">
                            <Link class="link link--pointing dot__wrapper mod-related__block link--zoom-image" to="/CasePage" state={{data:item,img:imageUrl+item.feature_image}}>
                                <div class="media-wrapper block__media block__media--standard">
                                        <img class="img-cover" src={imageUrl+item.feature_image} alt="GHA-SCAI SHOCK MENA – February 2024, Dubai, UAE"/>
                                                                        </div>
                                <div class="mod-related__link">
                                    <div class="mod-related__content">
                                        <div class="tag block__tag text--dark">
                                          
                                        </div>

                                        <h3 class="text--bold text--small">
                                            <span >{item.title}</span>
                                        </h3>

                                        <div class="block__text text--dark">
                                            <span class="dot"></span>
                                            <span className="threelinep">{item.excerpt}</span>
                                        </div>
                                    </div>

                                    <span>
                                        <span class="link__text">Read more</span>
                                        <span class="icon icon--middle icon-arrow-right"></span>
                                    </span>
                                </div>
                            </Link>
                        </article>
                    </li> 
                    )
                }
                    
                </ul>
                </div>
                </div>
                            </div>
        </>
    )
}

export default CaseStudiesComponent