import Carousel from "../../component/carousel"
import { Helmet } from "react-helmet"

const WWWConference = ()=>{
    return(
        <>
        <Helmet>
                <meta charSet="utf-8" />
                <title>Bidding and Winning the 3rd International STEMFest</title>
                <link rel="canonical" href="https://cimglobal.net/" />
            </Helmet>
         <Carousel
         mainText={
            `Bidding services - Elevate Your Global Presence`
         }
         smallText={
            `Decades of experience securing prestigious international conferences with strategic bidding.`
         }
         images={["img/slide-01.jpg","img/slide-01.jpg","img/slide-01.jpg"]}
         />
        <div className="page-contextual page-contextual--article"  >


<nav id="articleNav" className="nav-contextual ">
    <div className="accordion accordion--mobile">
        <div className="nav-contextual__head">
            <span className="heading-5 nav-contextual__current">Go to top</span>
            <button className="btn accordion__btn">
                <span className="icon icon-chevron"></span>
                <span className="u-visually-hidden">Expand</span>     
            </button>
        </div>
        <div className="accordion__content">
            <ul className="nav-contextual__list">
                    <li className="nav-contextual__item dot__wrapper text--xsmall">
                        <a className="nav-contextual__link" href="#top" title="">
                            <span className="dot"></span>
                            Go to top
                        </a>
                    </li>
                    <li className="nav-contextual__item dot__wrapper text--xsmall">
                        <a className="nav-contextual__link" href="#Overview" title="">
                            <span className="dot"></span>
                            Overview
                        </a>
                    </li>
                    <li className="nav-contextual__item dot__wrapper text--xsmall">
                        <a className="nav-contextual__link" href="#Bidding" title="">
                            <span className="dot"></span>
                            Bidding Process
                        </a>
                    </li>
                    <li className="nav-contextual__item dot__wrapper text--xsmall">
                        <a className="nav-contextual__link" href="#Conference" title="">
                            <span className="dot"></span>
                            Organizing the Conference
                        </a>
                    </li>
               
                    <li className="nav-contextual__item dot__wrapper text--xsmall">
                        <a className="nav-contextual__link" href="#Impact" title="">
                            <span className="dot"></span>
                            Impact and Outcomes
                        </a>
                    </li>
                    <li className="nav-contextual__item dot__wrapper text--xsmall">
                        <a className="nav-contextual__link" href="#Facts" title="">
                            <span className="dot"></span>
                            Key Facts
                        </a>
                    </li>
                 
            </ul>
           
        </div>
    </div>
</nav>

    <article className="article">
            <div id="top">
                
<section className="hero-article" id="5532">
<div className="content-wrapper">
        <h2 className="heading-2 hero-article__heading">Bidding and Organizing the WWW Conference </h2>

        

    <div className="media-wrapper hero-article__media link--zoom-image">
            <img className="img-cover" src="./img/WWW-Conference-2011.jpg" alt="Bidding and Organizing the WWW Conference "/></div>
    </div>
</section>
            </div>
            <div id="">
                <section className="mod-two-cols mod-two-cols--no-padding" data-lazy-load>
<div className="content-wrapper">
   {/* <div className="">
            
    </div>  */}
    <div className="">
            <div>
<div id="Overview">
<div className="block-text">
<h3 className="heading-3 block-text__header grid__item md--two-quarters push--md--one-quarter">Overview</h3>
<div className="grid grid--flex">
<aside className="grid__item md--one-quarter block-text__labels">
<ul className="block-text__labels-list">
</ul>
</aside>

<div className="grid__item md--three-quarters block-text__content">
<p className="lg--two-thirds text--large block-text__subheader">
CIMGLOBAL, a leading global meetings and associations management company, has demonstrated its expertise in bidding and organizing major international conferences. One such example is the successful hosting of the 20th International Conference on World Wide Web (WWW 2011) in Hyderabad, India, from March 28 to April 1, 2011. 
</p>
<p className="lg--two-thirds text--large block-text__subheader">
The WWW conference series is one of the most prestigious events in the field of computer science and internet technology, attracting renowned researchers, academics, and industry professionals from around the world. CIMGLOBALs ability to secure the bid and deliver an exceptional conference experience was highly commended by the organizers. 
</p>
</div>
</div>
</div>
</div>
<div id="Bidding">
<div className="block-text">
<h3 className="heading-3 block-text__header grid__item md--two-quarters push--md--one-quarter">Bidding Expertise</h3>
<div className="grid grid--flex">
<aside className="grid__item md--one-quarter block-text__labels">
<ul className="block-text__labels-list">
</ul>
</aside>

<div className="grid__item md--three-quarters block-text__content">
<p className="lg--two-thirds text--large block-text__subheader">
CIMGLOBAL's expertise in the bidding process was instrumental in securing the WWW 2011 conference for Hyderabad. The company's comprehensive understanding of the event management industry, combined with its strong partnerships and local knowledge, gave it a competitive edge over other bidders. 
</p>
<p className="lg--two-thirds text--large block-text__subheader">
CIMGLOBAL's bidding strategy involved a thorough analysis of the conference's requirements, the target audience, and the host city's capabilities. The company's extensive experience in managing similar international events and its ability to assemble a dedicated and experienced team further strengthened its bid. 
</p>
</div>
</div>
</div>
</div>
                    <div id="Conference">
                        
<div className="block-text">
<h3 className="heading-3 block-text__header grid__item md--two-quarters push--md--one-quarter">Organizing the Conference</h3>
<div className="grid grid--flex">
    <aside className="grid__item md--one-quarter block-text__labels">
            <ul className="block-text__labels-list">
            </ul>
    </aside>

    <div className="grid__item md--three-quarters block-text__content">
            <p className="lg--two-thirds text--large block-text__subheader">
            Once the bid was won, CIMGLOBAL's expertise in event management came to the forefront. The company's dedicated team stayed on top of the many logistical items required for the successful organization of the WWW 2011 conference, ensuring a seamless experience for the organizers and attendees. </p>
            <p className="lg--two-thirds text--large block-text__subheader">
            CIMGLOBAL's attention to detail, proactive approach, and ability to adapt to the ever-changing dynamics of the event were highly praised by the organizers. Mary Ellen Zurko, a representative of the WWW conference series, expressed her gratitude for the "stellar" organization and the "exceptionally well-organized" conference. </p>
        
       
    </div>
</div>
</div>

                    </div>
<div id="Impact">
                        
<div className="block-text">
<h3 className="heading-3 block-text__header grid__item md--two-quarters push--md--one-quarter">Impact and Outcomes</h3>
<div className="grid grid--flex">
    <aside className="grid__item md--one-quarter block-text__labels">
            <ul className="block-text__labels-list">
            </ul>
    </aside>

    <div className="grid__item md--three-quarters block-text__content">
            <p className="lg--two-thirds text--large block-text__subheader">
            The successful hosting of the WWW 2011 conference in Hyderabad had a significant impact on the city and the region. The event showcased Hyderabad's capabilities in hosting large-scale international conferences and attracted global attention to the city's growing importance as a hub for technology and innovation. 
            </p>
            <p className="lg--two-thirds text--large block-text__subheader">
            For CIMGLOBAL, the win further solidified its reputation as a leading event management company with expertise in bidding and delivering successful international conferences. The company's ability to leverage its extensive industry knowledge, strong partnerships, and deep understanding of the host city's capabilities has been instrumental in securing prestigious events like the WWW 2011 conference. 
            </p>
     
    </div>
</div>
</div>

                    </div>

 

<div id="Facts">
<div className="block-text">
<h3 className="heading-3 block-text__header grid__item md--two-quarters push--md--one-quarter">Key Facts</h3>
<div className="grid grid--flex">
    <aside className="grid__item md--one-quarter block-text__labels">
            <ul className="block-text__labels-list">
            </ul>
    </aside>

    <div className="grid__item md--three-quarters block-text__content">
            <p className="lg--two-thirds text--large block-text__subheader">
<ul className="block-text__labels-list">
<li>o	CIMGLOBAL successfully bid for and organized the 20th International Conference on World Wide Web (WWW 2011) in Hyderabad, India, from March 28 to April 1, 2011.</li>
<li>o	The WWW conference series is one of the most prestigious events in the field of computer science and internet technology, attracting renowned researchers, academics, and industry professionals from around the world.</li>
<li>o	CIMGLOBAL comprehensive understanding of the event management industry, strong partnerships, and local knowledge were key factors in securing the bid for the WWW 2011 conference.</li>
<li>o	The company's dedicated team stayed on top of the logistical requirements, ensuring a seamless experience for the organizers and attendees, as evidenced by the positive feedback from the conference representative.</li>
<li>o	The successful hosting of the WWW 2011 conference in Hyderabad showcased the city's capabilities in hosting large-scale international events and attracted global attention to its growing importance as a hub for technology and innovation.</li>
<li>o	The win further solidified CIMGLOBAL's reputation as a leading event management company with expertise in bidding and delivering successful international conferences.</li>
            </ul>
            </p>
      
    </div>
</div>
</div>


                    </div>
                  
            </div>
    </div>
</div>
</section>



            </div>
          
           
           
    </article>
</div>

<div class="grid mod-related mod-related--light mod-related--has-read-more" data-lazy-load>
        <div class="grid__item lg--nine-twelfths">
            <h2 class="heading-2 mod-related__header grid--flex-center">
                <span class="mod-related__header-text">Case Stories</span>
                   
            </h2>
            <ul class="grid grid--flex">
                    <li class="grid__item md--one-third mod-related__item">
                        <article class="block text--small mod-related__block">
                            <a class="link link--pointing dot__wrapper mod-related__block link--zoom-image" href="/AnnualMeetingoftheInternationalSocietyofBloodPurification" title="Read more" rel="noopener">
                                <div class="media-wrapper block__media block__media--standard">
                                        <img class="img-cover" src="/img/Annual-Meeting-of-the-International-Society-of-Blood-Purification.jpg" alt="Bidding and Winning the 37th Annual Meeting of the International Society of Blood Purification (ISBP)" />
                                </div>
                                <div class="mod-related__link">
                                    <div class="mod-related__content">
                                      

                                        <h3 class="text--bold text--small">
                                            <span>Bidding and Winning the 37th Annual Meeting of the International Society of Blood Purification (ISBP)</span>
                                        </h3>

                                        <div class="block__text text--dark">
                                            <span class="dot"></span>
                                            <span>CIMGLOBAL, a premier global meetings and associations management company, has successfully won the bid to manage the 37th Annual Meeting of the International Society of Blood Purification (ISBP) in 2019...</span>
                                        </div>
                                    </div>

                                    <span>
                                        <span class="link__text">Read more</span>
                                        <span class="icon icon--middle icon-arrow-right"></span>
                                    </span>
                                </div>
                            </a>
                        </article>
                    </li>
                    <li class="grid__item md--one-third mod-related__item">
                        <article class="block text--small mod-related__block">
                            <a class="link link--pointing dot__wrapper mod-related__block link--zoom-image" href="/InternationalSTEMFest" title="Read more" rel="noopener">
                                <div class="media-wrapper block__media block__media--standard">
                                        <img class="img-cover" src="/img/3rd-International-STEMFest.jpg" alt="Bidding and Winning the 3rd International STEMFest" />
                                </div>
                                <div class="mod-related__link">
                                    <div class="mod-related__content">
                                        

                                        <h3 class="text--bold text--small">
                                            <span>Bidding and Winning the 3rd International STEMFest</span>
                                        </h3>

                                        <div class="block__text text--dark">
                                            <span class="dot"></span>
                                            <span>The 3rd International Festival of Science, Technology, Engineering & Mathematics (STEMFest) 2016 was a major science and technology event hosted in Mysore, India from October 17-20, 2016...</span>
                                        </div>
                                    </div>

                                    <span>
                                        <span class="link__text">Read more</span>
                                        <span class="icon icon--middle icon-arrow-right"></span>
                                    </span>
                                </div>
                            </a>
                        </article>
                    </li>
                    <li class="grid__item md--one-third mod-related__item">
                        <article class="block text--small mod-related__block">
                            <a class="link link--pointing dot__wrapper mod-related__block link--zoom-image" href="/WWWConference" title="Read more" rel="noopener">
                                <div class="media-wrapper block__media block__media--standard">
                                        <img class="img-cover" src="/img/WWW-Conference-2011.jpg" alt="Bidding and Organizing the WWW Conference " />
                                </div>
                                <div class="mod-related__link">
                                    <div class="mod-related__content">
                                        

                                        <h3 class="text--bold text--small">
                                            <span>Bidding and Organizing the WWW Conference </span>
                                        </h3>

                                        <div class="block__text text--dark">
                                            <span class="dot"></span>
                                            <span>CIMGLOBAL, a leading global meetings and associations management company, has demonstrated its expertise in bidding and organizing major international conferences. One such example is the...</span>
                                        </div>
                                    </div>

                                    <span>
                                        <span class="link__text">Read more</span>
                                        <span class="icon icon--middle icon-arrow-right"></span>
                                    </span>
                                </div>
                            </a>
                        </article>
                    </li>
            </ul>
        </div>
    </div>
        </>
    )
}

export default WWWConference