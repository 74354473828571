import Carousel from "../../component/carousel"
import { Helmet } from "react-helmet"

const CaseStudyGlobalHealthForum = ()=>{
    return(
        <>
        <Helmet>
                <meta charSet="utf-8" />
                <title>Case Study: Global Health Forum</title>
                <link rel="canonical" href="https://cimglobal.net/" />
            </Helmet>
         <Carousel
         mainText={
            `Case Study`
         }
         smallText={
            ` `
         }
         images={["img/slide-01.jpg","img/slide-01.jpg","img/slide-01.jpg"]}
         />
        <div className="page-contextual page-contextual--article"  >


<nav id="articleNav" className="nav-contextual ">
    <div className="accordion accordion--mobile">
        <div className="nav-contextual__head">
            <span className="heading-5 nav-contextual__current">Go to top</span>
            <button className="btn accordion__btn">
                <span className="icon icon-chevron"></span>
                <span className="u-visually-hidden">Expand</span>     
            </button>
        </div>
        <div className="accordion__content">
            <ul className="nav-contextual__list">
                    <li className="nav-contextual__item dot__wrapper text--xsmall">
                        <a className="nav-contextual__link" href="#Sustainable-Practices-at-AFITA_WCCA-Conference" title="">
                            <span className="dot"></span>
                            Go to top
                        </a>
                    </li>
                    <li className="nav-contextual__item dot__wrapper text--xsmall">
                        <a className="nav-contextual__link" href="#Overview" title="">
                            <span className="dot"></span>
                            Overview
                        </a>
                    </li>
                    <li className="nav-contextual__item dot__wrapper text--xsmall">
                        <a className="nav-contextual__link" href="#Problem" title="">
                            <span className="dot"></span>
                            Problem
                        </a>
                    </li>
                    <li className="nav-contextual__item dot__wrapper text--xsmall">
                        <a className="nav-contextual__link" href="#Solution" title="">
                            <span className="dot"></span>
                            Solution
                        </a>
                    </li>
                    <li className="nav-contextual__item dot__wrapper text--xsmall">
                        <a className="nav-contextual__link" href="#Impact" title="">
                            <span className="dot"></span>
                            Impact
                        </a>
                    </li>
                    <li className="nav-contextual__item dot__wrapper text--xsmall">
                        <a className="nav-contextual__link" href="#Sustainable" title="">
                            <span className="dot"></span>
                            Key Facts:
                        </a>
                    </li>
                 
            </ul>
           
        </div>
    </div>
</nav>

    <article className="article">
            <div id="Sustainable-Practices-at-AFITA_WCCA-Conference">
                
<section className="hero-article" id="5532">
<div className="content-wrapper">
        <h2 className="heading-2 hero-article__heading">Global Health Forum</h2>

        

    <div className="media-wrapper hero-article__media link--zoom-image">
            <img className="img-cover" src="./img/global-health-forum1.jpg" alt=""/></div>
    </div>
</section>
            </div>
            <div id="">
                <section className="mod-two-cols mod-two-cols--no-padding" data-lazy-load>
<div className="content-wrapper">
   {/* <div className="">
            
    </div>  */}
    <div className="">
            <div>
                    <div id="Overview">
                        
<div className="block-text">
<h3 className="heading-3 block-text__header grid__item md--two-quarters push--md--one-quarter">Overview</h3>
<div className="grid grid--flex">
    <aside className="grid__item md--one-quarter block-text__labels">
            <ul className="block-text__labels-list">
            </ul>
    </aside>

    <div className="grid__item md--three-quarters block-text__content">
            <p className="lg--two-thirds text--large block-text__subheader">
            The Global Health Forum 2023, held on September 29-30 in Cascais, Portugal, was a groundbreaking healthcare event co-created by CIMGLOBAL and Fórum Saúde XXI. The forum convened esteemed national and international experts to explore the future of healthcare and tackle pressing global challenges.
            </p>
      
    </div>
</div>
</div>

                    </div>
                    <div id="Problem">
                        
<div className="block-text">
<h3 className="heading-3 block-text__header grid__item md--two-quarters push--md--one-quarter">Problem</h3>
<div className="grid grid--flex">
    <aside className="grid__item md--one-quarter block-text__labels">
            <ul className="block-text__labels-list">
            </ul>
    </aside>

    <div className="grid__item md--three-quarters block-text__content">
            <p className="lg--two-thirds text--large block-text__subheader">
            With the healthcare sector facing multifaceted challenges worldwide, including equitable access, technological integration, and sustainability, there was a crucial need for collaborative solutions. CIMGLOBAL proposed the idea to Fórum Saúde XXI to create a platform for dialogue, knowledge exchange, and collective action.
            </p>
       
    </div>
</div>
</div>

                    </div>
<div id="Solution">
                        
<div className="block-text">
<h3 className="heading-3 block-text__header grid__item md--two-quarters push--md--one-quarter">Solution</h3>
<div className="grid grid--flex">
    <aside className="grid__item md--one-quarter block-text__labels">
            <ul className="block-text__labels-list">
            </ul>
    </aside>

    <div className="grid__item md--three-quarters block-text__content">
            <p className="lg--two-thirds text--large block-text__subheader">
            In response to these challenges, CIMGLOBAL and Fórum Saúde XXI collaborated closely to co-create the Global Health Forum 2023. Leveraging CIMGLOBAL's Co-Create service, the event was meticulously designed to address specific healthcare sector needs. Key features included keynote speeches, panel discussions, workshops, and networking sessions covering digital health, climate change, health policies, and universal healthcare coverage.
            </p>
     
    </div>
</div>
</div>

                    </div>
                    <div id="Impact">
                        
<div className="block-text">
<h3 className="heading-3 block-text__header grid__item md--two-quarters push--md--one-quarter">Impact</h3>
<div className="grid grid--flex">
    <aside className="grid__item md--one-quarter block-text__labels">
            <ul className="block-text__labels-list">
            </ul>
    </aside>

    <div className="grid__item md--three-quarters block-text__content">
            <p className="lg--two-thirds text--large block-text__subheader">
            The Global Health Forum 2023 garnered significant attention, attracting over 2500 participants and featuring a diverse lineup of renowned speakers. The event not only raised awareness of healthcare's critical role in economic and social development but also fostered dialogue and innovation. CIMGLOBAL's co-creation approach ensured the forum's success, facilitating meaningful collaboration and the development of impactful solutions.
            </p>
      
    </div>
</div>
</div>
</div>
<div id="Sustainable">
<div className="block-text">
<h3 className="heading-3 block-text__header grid__item md--two-quarters push--md--one-quarter">Key Facts</h3>
<div className="grid grid--flex">
    <aside className="grid__item md--one-quarter block-text__labels">
            <ul className="block-text__labels-list">
            </ul>
    </aside>

    <div className="grid__item md--three-quarters block-text__content">
            <p className="lg--two-thirds text--large block-text__subheader">
            <ul className="block-text__labels-list">
                <li>o	CIMGLOBAL proposed the idea to Fórum Saúde XXI, initiating the co-creation process.</li>
                <li>o	The event received high patronage from the President of Portugal and institutional support from government agencies and healthcare organizations.</li>
                <li>o	Key topics addressed included digital health, climate change, and universal healthcare coverage.</li>
                <li>o	CIMGLOBAL's Co-Create service was instrumental in tailoring the event to address sector-specific challenges.</li>
                <li>o	Testimonials from participants and partners underscored the effectiveness of CIMGLOBAL's co-creation approach in delivering a successful and impactful event.</li>
            </ul>
            </p>
      
    </div>
</div>
</div>


                    </div>
                  
            </div>
    </div>
</div>
</section>



            </div>
          
           
            <div class="grid mod-related mod-related--light mod-related--has-read-more" data-lazy-load>
        <div class="grid__item lg--nine-twelfths">
            <h2 class="heading-2 mod-related__header grid--flex-center">
                <span class="mod-related__header-text">Case Stories</span>
                  
            </h2>
            <ul class="grid grid--flex">
                    <li class="grid__item md--one-third mod-related__item">
                        <article class="block text--small mod-related__block">
                            <a class="link link--pointing dot__wrapper mod-related__block link--zoom-image" href="/CaseStudyGlobalHealthForum" target="" title="Read more" rel="noopener">
                                <div class="media-wrapper block__media block__media--standard">
                                        <img class="img-cover" src="/img/global-health-forum1.jpg" />
                                </div>
                                <div class="mod-related__link">
                                    <div class="mod-related__content">
                                      

                                        <h3 class="text--bold text--small">
                                            <span>Global Health Forum</span>
                                        </h3>

                                        <div class="block__text text--dark">
                                            <span class="dot"></span>
                                            <span>The Global Health Forum 2023, held on September 29-30 in Cascais, Portugal, was a groundbreaking healthcare</span>
                                        </div>
                                    </div>

                                    <span>
                                        <span class="link__text">Read more</span>
                                        <span class="icon icon--middle icon-arrow-right"></span>
                                    </span>
                                </div>
                            </a>
                        </article>
                    </li>
<li class="grid__item md--one-third mod-related__item">
<article class="block text--small mod-related__block">
<a class="link link--pointing dot__wrapper mod-related__block link--zoom-image" href="/CaseStudyGhaScaiShockMena" target="" title="Read more" rel="noopener">
<div class="media-wrapper block__media block__media--standard">
<img class="img-cover" src="/img/CaseStudyGhaScaiShockMena.jpg" />
</div>
<div class="mod-related__link">
<div class="mod-related__content">

<h3 class="text--bold text--small">
<span>GHA-SCAI SHOCK MENA</span>
</h3>

<div class="block__text text--dark">
<span class="dot"></span>
<span>The GHA-SCAI SHOCK MENA conference, initiated and organized by CIMGLOBAL, is a groundbreaking event...</span>
</div>
</div>

<span>
<span class="link__text">Read more</span>
<span class="icon icon--middle icon-arrow-right"></span>
</span>
</div>
</a>
</article>
</li>

<li class="grid__item md--one-third mod-related__item">
<article class="block text--small mod-related__block">
<a class="link link--pointing dot__wrapper mod-related__block link--zoom-image" href="/TheBestofSCCMCriticalCareCongressinAbuDhabi" target="" title="Read more" rel="noopener">
<div class="media-wrapper block__media block__media--standard">
<img class="img-cover" src="/img/SCCM2019.jpg" />
</div>
<div class="mod-related__link">
<div class="mod-related__content">

<h3 class="text--bold text--small">
<span>The Best of SCCM Critical Care Congress in Abu Dhabi</span>
</h3>

<div class="block__text text--dark">
<span class="dot"></span>
<span>In November 2019, CIMGLOBAL spearheaded the collaboration between the Society of Critical Care ...</span>
</div>
</div>

<span>
<span class="link__text">Read more</span>
<span class="icon icon--middle icon-arrow-right"></span>
</span>
</div>
</a>
</article>
</li>
            </ul>
        </div>
    </div>
            
    </article>
</div>
        </>
    )
}

export default CaseStudyGlobalHealthForum