import Carousel from "../../component/carousel"
import News from "../../component/news";
const Whoweare = ()=>{
return(
<>
<Carousel
mainText={
`WE are CIMGLOBAL`
}
smallText={
` `
}
images={["img/WhoWeAre.jpg"]}
/>
<section className="mod-two-cols" data-lazy-load>

<div className="">

<div className="grid">
<div className="grid__item md--two-thirds mod-two-cols--padded">
<h3 className="heading-1"><span>WE are CIMGLOBAL</span></h3>
 {/* 
<ul className="list-metrics">
<li>
    <div className="metrics metrics--large metrics--base-info">
                                   <div className="text--small md--one-whole">
            <div className="text--bold">Creating Content, Connecting People</div>
           <div>
                <span className="dot"></span>
                    <div>At the core of our mission is a profound belief in the power of connection. </div>
            </div>

        </div>
    </div>
</li>
</ul>*/}



<article className="mod-text-media mod-text-media__scheme--Light " data-lazy-load>
<div className="mod-text-media__wrapper grid ">
<div className="grid  lg--one-whole md-one-whole">


<div className="grid__item md--one-half">
<div className="heading-2">
<br/>
<h3>Vision</h3>

</div>
<p style={{fontSize:"20px",lineHeight:"120%",marginTop:"15px"}}>As a dynamic catalyst, CIMGLOBAL sparks and nurtures profound conversations, fostering meaningful connections in diverse industries and among varied audiences.
</p>
<div className="heading-2">
<br/>
<h3>Mission</h3>

</div>
<p style={{fontSize:"20px",lineHeight:"120%",marginTop:"15px"}}>At the core of our mission is a profound belief in the power of connection. Through meticulously curated events, we unite global communities, fostering economic prosperity and knowledge exchange. Our commitment leaves a lasting impact on local communities, creating a harmonious blend of international influence and grassroots empowerment.
</p>
</div>
<div className="grid__item md--one-half">
<div className="media-wrapper">

                    <div className="video">
                        <div className="video__content">
                            <button className="btn btn-play">
                                <span className="icon icon-play"></span>
                            </button>
                            <h2 className="video__title"></h2>
                        </div>
                        <video preload="none" poster="/img/thumnail.png" preload="auto">
                            <source src="https://cimglobal.net/cim.mp4" type="video/mp4"/>
                        </video>
                    </div>
                </div>
</div>
</div>
</div>
</article>


</div>
</div>
</div>
</section>

<article className="mod-text-media mod-text-media__scheme--Light" data-lazy-load>
<div className="mod-text-media__wrapper grid">
<div className="grid  lg--two-thirds md-one-whole">

<div className="grid__item md--one-half">
<div className="heading-2">

<h3>Our top three values</h3>
</div>

</div>

</div>
</div>
</article>


<article className="mod-text-media mod-text-media__scheme--Light" data-lazy-load>
<div className="mod-text-media__wrapper grid">
<div className="grid  lg--two-thirds md-one-whole">

<div className="grid__item md--one-half">

<div className="mod-text__media">
<div className="media-wrapper mod-text__media">
<div className="mod-overview__media">
    <a className="link--zoom-image">
        <img className="img-cover" src="/img/Who-We-Are_Connection.jpg" alt="Conference Bid Support"/>  
    </a>   
</div>
</div>
</div>
</div>
<div className="grid__item md--one-half">
<div className="mod-text__content">
<h3 className="heading-3 mod-text__header mod-text-media__header">Connection</h3>
<div className="text--large mod-text-media__content">
<p style={{fontSize:"20px",lineHeight:"120%",marginTop:"5px"}}>
We prioritize building connections and fostering relationships globally. This value emphasizes the importance of connecting people, products, and ideas, aligning directly with the mission.</p>
        
</div>
    
</div>
</div>
</div>
</div>
</article>


<article className="mod-text-media mod-text-media__scheme--Light" data-lazy-load>
<div className="mod-text-media__wrapper grid">
<div className="grid push--lg--one-third lg--two-thirds md-one-whole">

<div className="grid__item md--one-half">
<div className="mod-text__content">
<h3 className="heading-3 mod-text__header mod-text-media__header">Impact</h3>
<div className="text--large mod-text-media__content">
<p style={{fontSize:"20px",lineHeight:"120%",marginTop:"5px"}}>
We are committed to fostering positive change. Beyond merely generating economic prosperity, we prioritize initiatives that make a lasting and meaningful impact on local communities. By spearheading projects that transcend financial gains, we aim to leave a legacy of empowerment and growth wherever we go.</p>
</div>
        
</div>
</div>


<div className="grid__item md--one-half">
<div className="mod-text__media">
<div className="media-wrapper mod-text__media">
<div className="mod-overview__media">
    <a href="#" className="link--zoom-image">
        <img className="img-cover" src="/img/Who-We-Are_Impact.jpg" alt="Impact"/>  
    </a>   
</div>
</div>
</div>
</div>   

     </div>
</div>
</article>

<article className="mod-text-media mod-text-media__scheme--Light" data-lazy-load>
<div className="mod-text-media__wrapper grid">
<div className="grid  lg--two-thirds md-one-whole">


<div className="grid__item     
md--one-half">
<div className="mod-text__media">
<div className="media-wrapper mod-text__media">
<div className="mod-overview__media">
<a className="link--zoom-image">
<img className="img-cover" src="/img/Innovation1.jpg" alt="Innovation"/>  
</a>   
</div>
</div>
</div>
</div>
<div className="grid__item md--one-half">
<div className="mod-text__content">
<h3 className="heading-3 mod-text__header mod-text-media__header">Innovation</h3>
<div className="text--large mod-text-media__content">
<p style={{fontSize:"20px",lineHeight:"120%",marginTop:"5px"}}>
Our value of Innovation propels us forward, driving us to embrace creativity and continuous improvement. By fostering a culture of innovation, we ensure that CIMGLOBAL remains at the forefront of connecting people, products, and ideas. We constantly seek new approaches and solutions to meet the evolving needs of our clients and stakeholders.</p>

</div>

</div>
</div>
</div>
</div>
</article>



<News/>
</>
)
}

export default Whoweare