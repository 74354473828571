import Carousel from "../../component/carousel"
import { Helmet } from "react-helmet"

const ReachingOver200ThousandThroughSocialMediaCampaign = ()=>{
    return(
        <>
        <Helmet>
                <meta charSet="utf-8" />
                <title>Case Stories - Reaching Over 200 Thousand Through Social Media Campaign</title>
                <link rel="canonical" href="https://cimglobal.net/" />
            </Helmet>
         <Carousel
         mainText={
            `Case Stories - Communication`
         }
         smallText={
            `Global Water Security Conference for Agricultural and Natural Resources - Unaware of Water`
         }
         images={["img/slide-01.jpg","img/slide-01.jpg","img/slide-01.jpg"]}
         />
        <div className="page-contextual page-contextual--article"  >


<nav id="articleNav" className="nav-contextual ">
    <div className="accordion accordion--mobile">
        <div className="nav-contextual__head">
            <span className="heading-5 nav-contextual__current">Go to top</span>
            <button className="btn accordion__btn">
                <span className="icon icon-chevron"></span>
                <span className="u-visually-hidden">Expand</span>     
            </button>
        </div>
        <div className="accordion__content">
            <ul className="nav-contextual__list">
                    <li className="nav-contextual__item dot__wrapper text--xsmall">
                        <a className="nav-contextual__link" href="#top" title="">
                            <span className="dot"></span>
                            Go to top
                        </a>
                    </li>
                    <li className="nav-contextual__item dot__wrapper text--xsmall">
                        <a className="nav-contextual__link" href="#Overview" title="">
                            <span className="dot"></span>
                            Overview
                        </a>
                    </li>
                    <li className="nav-contextual__item dot__wrapper text--xsmall">
                        <a className="nav-contextual__link" href="#Objectives" title="">
                            <span className="dot"></span>
                            The Challenges
                        </a>
                    </li>
                    <li className="nav-contextual__item dot__wrapper text--xsmall">
                        <a className="nav-contextual__link" href="#Role" title="">
                            <span className="dot"></span>
                            The Solutions
                        </a>
                    </li>
                                       <li className="nav-contextual__item dot__wrapper text--xsmall">
                        <a className="nav-contextual__link" href="#Facts" title="">
                            <span className="dot"></span>
                            Key Facts
                        </a>
                    </li>
                 
            </ul>
           
        </div>
    </div>
</nav>

    <article className="article">
            <div id="top">
                
<section className="hero-article" id="5532">
<div className="content-wrapper">
        <h2 className="heading-2 hero-article__heading">Global Water Security Conference for Agricultural and Natural Resources</h2>

        

    <div className="media-wrapper hero-article__media link--zoom-image">
            <img className="img-cover" src="./img/reaching-over-200-thousand-through-social-media-campaign.jpg" alt="Reaching Over 200 Thousand Through Social Media Campaign"/></div>
    </div>
</section>
            </div>
            <div id="">
                <section className="mod-two-cols mod-two-cols--no-padding" data-lazy-load>
<div className="content-wrapper">
   {/* <div className="">
            
    </div>  */}
    <div className="">
            <div>
<div id="Overview">
<div className="block-text">
<h3 className="heading-3 block-text__header grid__item md--two-quarters push--md--one-quarter">Overview</h3>
<div className="grid grid--flex">
<aside className="grid__item md--one-quarter block-text__labels">
<ul className="block-text__labels-list">
</ul>
</aside>

<div className="grid__item md--three-quarters block-text__content">
<p className="lg--two-thirds text--large block-text__subheader">
Date: 3 – 6 October 2018
</p>
<p className="lg--two-thirds text--large block-text__subheader">
Location: Taj Krishna Hotel, Hyderabad, India
</p>
<p className="lg--two-thirds text--large block-text__subheader">
Mandate: To strategize and manage the entire social media presence, increase awareness and registration numbers
</p>
<p className="lg--two-thirds text--large block-text__subheader">
The conference was planned by The American Society of Agricultural and Biological Engineers (ASABE), in partnership with the Indian Society of Agricultural Engineers (ISAE).
</p>
<p className="lg--two-thirds text--large block-text__subheader">
The purpose of this conference is to bring farmers, researchers, practitioners, entrepreneurs, and policymakers together on a common platform to discuss current and future water security problems, share research and discuss creative solutions that can be applicable at various scales in different regions
</p>
<p className="lg--two-thirds text--large block-text__subheader">
The conference specifically focused on water security for producing food, fiber, and energy crops as well as maintaining water quality and quantity needed for ecosystem health and services.
</p>
</div>
</div>
</div>
</div>
<div id="Objectives">
<div className="block-text">
<h3 className="heading-3 block-text__header grid__item md--two-quarters push--md--one-quarter">The Challenges</h3>
<div className="grid grid--flex">
<aside className="grid__item md--one-quarter block-text__labels">
<ul className="block-text__labels-list">
</ul>
</aside>

<div className="grid__item md--three-quarters block-text__content">
<p className="lg--two-thirds text--large block-text__subheader">
The water issues are so important and have to be brought to the public at large and communicated in a way that people connect and the conference is able to create a long-lasting impact. The challenge was to reach maximum awareness with minimum investment.
</p>
</div>
</div>
</div>
</div>
                    <div id="Role">
                        
<div className="block-text">
<h3 className="heading-3 block-text__header grid__item md--two-quarters push--md--one-quarter">The Solutions</h3>
<div className="grid grid--flex">
    <aside className="grid__item md--one-quarter block-text__labels">
            <ul className="block-text__labels-list">
            </ul>
    </aside>

    <div className="grid__item md--three-quarters block-text__content">
            <p className="lg--two-thirds text--large block-text__subheader">
            The team created social presence on Facebook, LinkedIn, and Twitter by posting the content related to the conference topics, issues, keynote speakers, registration, sponsorship, and venue. Also created the hashtag (#GWSC2018) for the conference by which everyone can follow the updates on social channels. The team constantly monitored the social media analytics and closely worked on improving the results on weekly basis. The activity helped in improving the audience base on social channels.</p>
            <p className="lg--two-thirds text--large block-text__subheader">
            We got a limited budget for running paid promotions on social channels. With lots of brainstorming, the team ran the ads keeping in mind the ROI. All efforts paid off and we crossed the audience base of 200 thousand. Running paid ads helped us in showcasing the conference to a large audience which was specifically selected keeping in mind the conference theme. The audience was selected on the basis of age, gender, language, demographics, location, interest, educational background, behavior, connection type, etc.</p>
            <p className="lg--two-thirds text--large block-text__subheader">
            With the combined efforts of traditional and digital marketing, the team was able to get the registration of 320 delegates from 30 countries and 28 sponsors.</p>
        
       
    </div>
</div>
</div>

                    </div>


<div id="Facts">
<div className="block-text">
<h3 className="heading-3 block-text__header grid__item md--two-quarters push--md--one-quarter">Key Facts</h3>
<div className="grid grid--flex">
    <aside className="grid__item md--one-quarter block-text__labels">
            <ul className="block-text__labels-list">
            </ul>
    </aside>

    <div className="grid__item md--three-quarters block-text__content">
            <p className="lg--two-thirds text--large block-text__subheader">
<ul className="block-text__labels-list">
<li>Reach - 209200</li>
<li>Impression - 230935</li>
<li>Engagement - 6124</li>
<li>Likes - 6647</li>
<li>Clicks - 3057</li>
<li>Expressed interest for registration - 582 </li>
            </ul>
            </p>
      
    </div>
</div>
</div>


                    </div>
                  
            </div>
    </div>
</div>
</section>



            </div>
          
           
       
    </article>
</div>
        </>
    )
}

export default ReachingOver200ThousandThroughSocialMediaCampaign