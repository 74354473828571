import Carousel from "../../component/carousel"
import { Helmet } from "react-helmet"

const WindergyIndiaConferenceandExhibition = ()=>{
    return(
        <>
        <Helmet>
                <meta charSet="utf-8" />
                <title>Windergy India Conference and Exhibition</title>
                <link rel="canonical" href="https://cimglobal.net/" />
            </Helmet>
         <Carousel
         mainText={
            `Windergy India Conference and Exhibition`
         }
         smallText={
            ` `
         }
         images={["img/slide-01.jpg","img/slide-01.jpg","img/slide-01.jpg"]}
         />
        <div className="page-contextual page-contextual--article"  >


<nav id="articleNav" className="nav-contextual ">
    <div className="accordion accordion--mobile">
        <div className="nav-contextual__head">
            <span className="heading-5 nav-contextual__current">Go to top</span>
            <button className="btn accordion__btn">
                <span className="icon icon-chevron"></span>
                <span className="u-visually-hidden">Expand</span>     
            </button>
        </div>
        <div className="accordion__content">
            <ul className="nav-contextual__list">
                    <li className="nav-contextual__item dot__wrapper text--xsmall">
                        <a className="nav-contextual__link" href="#top" title="">
                            <span className="dot"></span>
                            Go to top
                        </a>
                    </li>
                    <li className="nav-contextual__item dot__wrapper text--xsmall">
                        <a className="nav-contextual__link" href="#Overview" title="">
                            <span className="dot"></span>
                            Overview
                        </a>
                    </li>
                    <li className="nav-contextual__item dot__wrapper text--xsmall">
                        <a className="nav-contextual__link" href="#Objectives" title="">
                            <span className="dot"></span>
                            Objectives and Highlights
                        </a>
                    </li>
                    <li className="nav-contextual__item dot__wrapper text--xsmall">
                        <a className="nav-contextual__link" href="#Role" title="">
                            <span className="dot"></span>
                            CIMGLOBAL's Role
                        </a>
                    </li>
                    <li className="nav-contextual__item dot__wrapper text--xsmall">
                        <a className="nav-contextual__link" href="#Impact" title="">
                            <span className="dot"></span>
                            Impact and Outcomes
                        </a>
                    </li>
                    <li className="nav-contextual__item dot__wrapper text--xsmall">
                        <a className="nav-contextual__link" href="#Facts" title="">
                            <span className="dot"></span>
                            Key Facts
                        </a>
                    </li>
                 
            </ul>
           
        </div>
    </div>
</nav>

    <article className="article">
            <div id="top">
                
<section className="hero-article" id="5532">
<div className="content-wrapper">
        <h2 className="heading-2 hero-article__heading">Windergy India Conference and Exhibition</h2>

        

    <div className="media-wrapper hero-article__media link--zoom-image">
            <img className="img-cover" src="./img/Windergy-India-Conference-and-Exhibition.jpg" alt="Windergy India Conference and Exhibition"/></div>
    </div>
</section>
            </div>
            <div id="">
                <section className="mod-two-cols mod-two-cols--no-padding" data-lazy-load>
<div className="content-wrapper">
   {/* <div className="">
            
    </div>  */}
    <div className="">
            <div>
<div id="Overview">
<div className="block-text">
<h3 className="heading-3 block-text__header grid__item md--two-quarters push--md--one-quarter">Overview</h3>
<div className="grid grid--flex">
<aside className="grid__item md--one-quarter block-text__labels">
<ul className="block-text__labels-list">
</ul>
</aside>

<div className="grid__item md--three-quarters block-text__content">
<p className="lg--two-thirds text--large block-text__subheader">
The Windergy India 2017 conference and exhibition was a landmark event for the wind energy industry in India, held from April 25-27, 2017 in New Delhi. The event was organized by the Indian Wind Turbine Manufacturers Association (IWTMA) in partnership with the Global Wind Energy Council (GWEC). 
</p>
<p className="lg--two-thirds text--large block-text__subheader">
The 3-day event brought together key government authorities, international and national wind energy players, service providers, innovators, financing entities, and other stakeholders on a single platform. It provided a comprehensive showcase of the phenomenal prospects and latest technological advancements in the wind energy sector. 
</p>
</div>
</div>
</div>
</div>
<div id="Objectives">
<div className="block-text">
<h3 className="heading-3 block-text__header grid__item md--two-quarters push--md--one-quarter">Objectives and Highlights</h3>
<div className="grid grid--flex">
<aside className="grid__item md--one-quarter block-text__labels">
<ul className="block-text__labels-list">
</ul>
</aside>

<div className="grid__item md--three-quarters block-text__content">
<p className="lg--two-thirds text--large block-text__subheader">
The primary objectives of Windergy India 2017 were to facilitate global networking, enable business opportunities, and foster partnerships and investments in the wind energy industry. The event featured an extensive exhibition that highlighted the industry's leading products, services, and innovations. 
</p>
<p className="lg--two-thirds text--large block-text__subheader">
The conference component of the event provided a platform for around 750 professionals and researchers to exchange innovative ideas and discuss the latest developments in the field of wind energy. The event was graced by the presence of distinguished speakers, including Mr. Steve Sawyer, Secretary General of the Global Wind Energy Council, and Shri. Suresh Prabhu, the Honorable Minister of Railways, Government of India. 
</p>
</div>
</div>
</div>
</div>
                    <div id="Role">
                        
<div className="block-text">
<h3 className="heading-3 block-text__header grid__item md--two-quarters push--md--one-quarter">CIMGLOBAL's Role</h3>
<div className="grid grid--flex">
    <aside className="grid__item md--one-quarter block-text__labels">
            <ul className="block-text__labels-list">
            </ul>
    </aside>

    <div className="grid__item md--three-quarters block-text__content">
            <p className="lg--two-thirds text--large block-text__subheader">
            CIMGLOBAL, the event organizer, was widely appreciated by the Windergy India 2017 Organizing Committee for their constructive approach, dedicated and hardworking team, and the ability to adapt to the ever-changing dynamics of the event. CIMGLOBAL's expertise and commitment played a crucial role in the success of the Windergy India 2017 conference and exhibition. </p>
        
       
    </div>
</div>
</div>

                    </div>
<div id="Impact">
                        
<div className="block-text">
<h3 className="heading-3 block-text__header grid__item md--two-quarters push--md--one-quarter">Impact and Outcomes</h3>
<div className="grid grid--flex">
    <aside className="grid__item md--one-quarter block-text__labels">
            <ul className="block-text__labels-list">
            </ul>
    </aside>

    <div className="grid__item md--three-quarters block-text__content">
            <p className="lg--two-thirds text--large block-text__subheader">
            The Windergy India 2017 event was a resounding success, attracting the industry's leading professionals and key decision-makers. It provided a valuable platform for global networking, business opportunities, and the exchange of innovative ideas and technological advancements in the wind energy sector. 
            </p>
            <p className="lg--two-thirds text--large block-text__subheader">
            The event's success highlighted the growing importance and potential of the wind energy industry in India, as well as the country's commitment to renewable energy development. The participation of high-level government officials and the global wind energy community underscored the event's significance and its impact on the industry's future growth. 
            </p>
     
    </div>
</div>
</div>

                    </div>

<div id="Facts">
<div className="block-text">
<h3 className="heading-3 block-text__header grid__item md--two-quarters push--md--one-quarter">Key Facts</h3>
<div className="grid grid--flex">
    <aside className="grid__item md--one-quarter block-text__labels">
            <ul className="block-text__labels-list">
            </ul>
    </aside>

    <div className="grid__item md--three-quarters block-text__content">
            <p className="lg--two-thirds text--large block-text__subheader">
<ul className="block-text__labels-list">
<li>o	Windergy India 2017 was held from April 25-27, 2017 in New Delhi, India.</li>
<li>o	The event was organized by the Indian Wind Turbine Manufacturers Association (IWTMA) in partnership with the Global Wind Energy Council (GWEC).</li>
<li>o	The 3-day event featured an extensive exhibition showcasing the latest products, services, and innovations in the wind energy sector.</li>
<li>o	The conference component of the event attracted around 750 professionals and researchers, providing a platform for the exchange of innovative ideas and discussions on industry developments.</li>
<li>o	The event was graced by the presence of distinguished speakers, including Mr. Steve Sawyer, Secretary General of the Global Wind Energy Council, and Shri. Suresh Prabhu, the Honorable Minister of Railways, Government of India.</li>
<li>o	CIMGLOBAL, the event organizer, was widely appreciated for their constructive approach, dedicated team, and ability to adapt to the event's changing dynamics.</li>
            </ul>
            </p>
      
    </div>
</div>
</div>


                    </div>
                  
            </div>
    </div>
</div>
</section>



            </div>
          
           
            <div class="grid mod-related mod-related--light mod-related--has-read-more" data-lazy-load>
        <div class="grid__item lg--nine-twelfths">
            <h2 class="heading-2 mod-related__header grid--flex-center">
                <span class="mod-related__header-text">Case Stories</span>
                    
            </h2>
            <ul class="grid grid--flex">
                    <li class="grid__item md--one-third mod-related__item">
                        <article class="block text--small mod-related__block">
                            <a class="link link--pointing dot__wrapper mod-related__block link--zoom-image" href="/ASIAFORGEMeetingandForgetechIndiaExhibition" title="Read more" rel="noopener">
                                <div class="media-wrapper block__media block__media--standard">
                                        <img class="img-cover" src="/img/ASIAFORGE-Meeting-and-Forgetech-India-Exhibition.jpg" alt="ASIAFORGE Meeting and Forgetech India Exhibition" />
                                </div>
                                <div class="mod-related__link">
                                    <div class="mod-related__content">
                                        <h3 class="text--bold text--small">
                                            <span>ASIAFORGE Meeting and Forgetech India Exhibition</span>
                                        </h3>

                                        <div class="block__text text--dark">
                                            <span class="dot"></span>
                                            <span>The ASIAFORGE Meeting is an international conference organized by the ASIAFORGE organization, which was established in 2005 by the Association of Indian Forging Industry...</span>
                                        </div>
                                    </div>

                                    <span>
                                        <span class="link__text">Read more</span>
                                        <span class="icon icon--middle icon-arrow-right"></span>
                                    </span>
                                </div>
                            </a>
                        </article>
                    </li>
                    <li class="grid__item md--one-third mod-related__item">
                        <article class="block text--small mod-related__block">
                            <a class="link link--pointing dot__wrapper mod-related__block link--zoom-image" href="/WindergyIndiaConferenceandExhibition" title="Read more" rel="noopener">
                                <div class="media-wrapper block__media block__media--standard">
                                        <img class="img-cover" src="/img/Windergy-India-Conference-and-Exhibition.jpg" alt="Windergy India Conference and Exhibition" />
                                </div>
                                <div class="mod-related__link">
                                    <div class="mod-related__content">
                                         <h3 class="text--bold text--small">
                                            <span>Windergy India Conference and Exhibition</span>
                                        </h3>

                                        <div class="block__text text--dark">
                                            <span class="dot"></span>
                                            <span>The Windergy India 2017 conference and exhibition was a landmark event for the wind energy industry in India, held from April 25-27, 2017 in New Delhi. The event was organized...</span>
                                        </div>
                                    </div>

                                    <span>
                                        <span class="link__text">Read more</span>
                                        <span class="icon icon--middle icon-arrow-right"></span>
                                    </span>
                                </div>
                            </a>
                        </article>
                    </li>
                    <li class="grid__item md--one-third mod-related__item">
                        <article class="block text--small mod-related__block">
                            <a class="link link--pointing dot__wrapper mod-related__block link--zoom-image" href="/CONCRETEConferenceandExhibition" title="Read more" rel="noopener">
                                <div class="media-wrapper block__media block__media--standard">
                                        <img class="img-cover" src="/img/CONCRETE-Conference-and-Exhibition.jpg" alt="CONCRETE Conference and Exhibition" />
                                </div>
                                <div class="mod-related__link">
                                    <div class="mod-related__content">
                                      
                                        <h3 class="text--bold text--small">
                                            <span>CONCRETE Conference and Exhibition</span>
                                        </h3>

                                        <div class="block__text text--dark">
                                            <span class="dot"></span>
                                            <span>CIMGLOBAL, a leading event management company, successfully concluded the CONCRETE 2017 Conference and Exhibition on Underground Structures, held on July 14-15, 2017 at Hotel the Royal Plaza in New Delhi...</span>
                                        </div>
                                    </div>

                                    <span>
                                        <span class="link__text">Read more</span>
                                        <span class="icon icon--middle icon-arrow-right"></span>
                                    </span>
                                </div>
                            </a>
                        </article>
                    </li>
            </ul>
        </div>
    </div>
    </article>
</div>
        </>
    )
}

export default WindergyIndiaConferenceandExhibition