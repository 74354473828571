import Carousel from "../../component/carousel"
import { Helmet } from "react-helmet"

const CONCRETEConferenceandExhibition = ()=>{
    return(
        <>
        <Helmet>
                <meta charSet="utf-8" />
                <title>CONCRETE Conference and Exhibition</title>
                <link rel="canonical" href="https://cimglobal.net/" />
            </Helmet>
         <Carousel
         mainText={
            `CONCRETE Conference and Exhibition`
         }
         smallText={
            ` `
         }
         images={["img/slide-01.jpg","img/slide-01.jpg","img/slide-01.jpg"]}
         />
        <div className="page-contextual page-contextual--article"  >


<nav id="articleNav" className="nav-contextual ">
    <div className="accordion accordion--mobile">
        <div className="nav-contextual__head">
            <span className="heading-5 nav-contextual__current">Go to top</span>
            <button className="btn accordion__btn">
                <span className="icon icon-chevron"></span>
                <span className="u-visually-hidden">Expand</span>     
            </button>
        </div>
        <div className="accordion__content">
            <ul className="nav-contextual__list">
                    <li className="nav-contextual__item dot__wrapper text--xsmall">
                        <a className="nav-contextual__link" href="#top" title="">
                            <span className="dot"></span>
                            Go to top
                        </a>
                    </li>
                    <li className="nav-contextual__item dot__wrapper text--xsmall">
                        <a className="nav-contextual__link" href="#Overview" title="">
                            <span className="dot"></span>
                            Overview
                        </a>
                    </li>
                    <li className="nav-contextual__item dot__wrapper text--xsmall">
                        <a className="nav-contextual__link" href="#Objectives" title="">
                            <span className="dot"></span>
                            Objectives and Highlights
                        </a>
                    </li>
                    <li className="nav-contextual__item dot__wrapper text--xsmall">
                        <a className="nav-contextual__link" href="#Role" title="">
                            <span className="dot"></span>
                            CIMGLOBAL's Role
                        </a>
                    </li>
                    <li className="nav-contextual__item dot__wrapper text--xsmall">
                        <a className="nav-contextual__link" href="#Impact" title="">
                            <span className="dot"></span>
                            Impact and Outcomes
                        </a>
                    </li>
                    <li className="nav-contextual__item dot__wrapper text--xsmall">
                        <a className="nav-contextual__link" href="#Facts" title="">
                            <span className="dot"></span>
                            Key Facts
                        </a>
                    </li>
                 
            </ul>
           
        </div>
    </div>
</nav>

    <article className="article">
            <div id="top">
                
<section className="hero-article" id="5532">
<div className="content-wrapper">
        <h2 className="heading-2 hero-article__heading">CONCRETE Conference and Exhibition</h2>

        

    <div className="media-wrapper hero-article__media link--zoom-image">
            <img className="img-cover" src="./img/CONCRETE-Conference-and-Exhibition.jpg" alt="CONCRETE Conference and Exhibition"/></div>
    </div>
</section>
            </div>
            <div id="">
                <section className="mod-two-cols mod-two-cols--no-padding" data-lazy-load>
<div className="content-wrapper">
   {/* <div className="">
            
    </div>  */}
    <div className="">
            <div>
<div id="Overview">
<div className="block-text">
<h3 className="heading-3 block-text__header grid__item md--two-quarters push--md--one-quarter">Overview</h3>
<div className="grid grid--flex">
<aside className="grid__item md--one-quarter block-text__labels">
<ul className="block-text__labels-list">
</ul>
</aside>

<div className="grid__item md--three-quarters block-text__content">
<p className="lg--two-thirds text--large block-text__subheader">
CIMGLOBAL, a leading event management company, successfully concluded the CONCRETE 2017 Conference and Exhibition on Underground Structures, held on July 14-15, 2017 at Hotel the Royal Plaza in New Delhi, India. The event was the second edition of the flagship conference organized by the Indian Concrete Institute (ICI), the premier professional body for concrete technology in the country.</p>

</div>
</div>
</div>
</div>
<div id="Objectives">
<div className="block-text">
<h3 className="heading-3 block-text__header grid__item md--two-quarters push--md--one-quarter">Objectives and Highlights</h3>
<div className="grid grid--flex">
<aside className="grid__item md--one-quarter block-text__labels">
<ul className="block-text__labels-list">
</ul>
</aside>

<div className="grid__item md--three-quarters block-text__content">
<p className="lg--two-thirds text--large block-text__subheader">
The CONCRETE 2017 conference aimed to address the varied challenges faced by planners, designers, and constructors in the field of underground structures. The event provided a platform for industry professionals, scientists, young engineers, government officials, and researchers to exchange innovative ideas and discuss the latest developments in concrete and structural engineering. 
</p>
<p className="lg--two-thirds text--large block-text__subheader">
The 2-day conference attracted over 200 attendees, including leading professionals and key decision-makers whose expertise is shaping the future of the concrete and structures industry. The inaugural ceremony was graced by Er. Abhai Sinha, Director General of the Central Public Works Department (CPWD), Government of India. 
</p>
</div>
</div>
</div>
</div>
                    <div id="Role">
                        
<div className="block-text">
<h3 className="heading-3 block-text__header grid__item md--two-quarters push--md--one-quarter">CIMGLOBAL's Role</h3>
<div className="grid grid--flex">
    <aside className="grid__item md--one-quarter block-text__labels">
            <ul className="block-text__labels-list">
            </ul>
    </aside>

    <div className="grid__item md--three-quarters block-text__content">
            <p className="lg--two-thirds text--large block-text__subheader">
            CIMGLOBAL, the event management company, played a crucial role in the successful organization and execution of the CONCRETE 2017 conference and exhibition. The company's expertise in exhibition management, combined with its dedicated and hardworking team, enabled it to adapt to the ever-changing dynamics of the event and deliver a seamless experience for the organizers and attendees.</p>
            <p className="lg--two-thirds text--large block-text__subheader">
            CIMGLOBAL's comprehensive event management services included logistics coordination, venue management, speaker and sponsor management, marketing and promotion, and on-site coordination. The company's ability to handle the various aspects of the event was widely appreciated by the Indian Concrete Institute and the organizing committee. </p>
        
       
    </div>
</div>
</div>

                    </div>
<div id="Impact">
                        
<div className="block-text">
<h3 className="heading-3 block-text__header grid__item md--two-quarters push--md--one-quarter">Impact and Outcomes</h3>
<div className="grid grid--flex">
    <aside className="grid__item md--one-quarter block-text__labels">
            <ul className="block-text__labels-list">
            </ul>
    </aside>

    <div className="grid__item md--three-quarters block-text__content">
            <p className="lg--two-thirds text--large block-text__subheader">
            The CONCRETE 2017 conference and exhibition was a resounding success, providing a valuable platform for industry professionals to network, share knowledge, and explore innovative solutions in the field of underground structures. The event was supported by esteemed organizations such as BMTPC, PWD, IAStructE, and IGS, as well as prestigious sponsors like Pidilite, Gammon India, Penetron, and Hilti India. 
            </p>
            <p className="lg--two-thirds text--large block-text__subheader">
            The conference's success highlighted the growing importance of concrete and structural engineering in the construction industry, as well as the need for continuous knowledge sharing and collaboration among industry stakeholders. The event's impact was felt across the industry, with attendees carrying home the knowledge and best practices shared during the two-day deliberations. 
            </p>
     
    </div>
</div>
</div>

                    </div>

<div id="Facts">
<div className="block-text">
<h3 className="heading-3 block-text__header grid__item md--two-quarters push--md--one-quarter">Key Facts</h3>
<div className="grid grid--flex">
    <aside className="grid__item md--one-quarter block-text__labels">
            <ul className="block-text__labels-list">
            </ul>
    </aside>

    <div className="grid__item md--three-quarters block-text__content">
            <p className="lg--two-thirds text--large block-text__subheader">
<ul className="block-text__labels-list">
<li>o	CONCRETE 2017 was the second edition of the flagship conference organized by the Indian Concrete Institute (ICI), the premier professional body for concrete technology in India.</li>
<li>o	The event was held on July 14-15, 2017 at Hotel the Royal Plaza in New Delhi, India.</li>
<li>o	CIMGLOBAL, the event management company, played a crucial role in the successful organization and execution of the conference and exhibition.</li>
<li>o	The 2-day event attracted over 200 industry professionals, including leading experts, key decision-makers, scientists, young engineers, government officials, and researchers.</li>
<li>o	The conference was supported by esteemed organizations and prestigious sponsors, highlighting its significance in the industry.</li>
<li>o	The event provided a platform for the exchange of innovative ideas and the discussion of the latest developments in concrete and structural engineering, particularly in the field of underground structures.</li>
            </ul>
            </p>
      
    </div>
</div>
</div>


                    </div>
                  
            </div>
    </div>
</div>
</section>



            </div>
          
            <div class="grid mod-related mod-related--light mod-related--has-read-more" data-lazy-load>
        <div class="grid__item lg--nine-twelfths">
            <h2 class="heading-2 mod-related__header grid--flex-center">
                <span class="mod-related__header-text">Case Stories</span>
                    
            </h2>
            <ul class="grid grid--flex">
                    <li class="grid__item md--one-third mod-related__item">
                        <article class="block text--small mod-related__block">
                            <a class="link link--pointing dot__wrapper mod-related__block link--zoom-image" href="/ASIAFORGEMeetingandForgetechIndiaExhibition" title="Read more" rel="noopener">
                                <div class="media-wrapper block__media block__media--standard">
                                        <img class="img-cover" src="/img/ASIAFORGE-Meeting-and-Forgetech-India-Exhibition.jpg" alt="ASIAFORGE Meeting and Forgetech India Exhibition" />
                                </div>
                                <div class="mod-related__link">
                                    <div class="mod-related__content">
                                        <h3 class="text--bold text--small">
                                            <span>ASIAFORGE Meeting and Forgetech India Exhibition</span>
                                        </h3>

                                        <div class="block__text text--dark">
                                            <span class="dot"></span>
                                            <span>The ASIAFORGE Meeting is an international conference organized by the ASIAFORGE organization, which was established in 2005 by the Association of Indian Forging Industry...</span>
                                        </div>
                                    </div>

                                    <span>
                                        <span class="link__text">Read more</span>
                                        <span class="icon icon--middle icon-arrow-right"></span>
                                    </span>
                                </div>
                            </a>
                        </article>
                    </li>
                    <li class="grid__item md--one-third mod-related__item">
                        <article class="block text--small mod-related__block">
                            <a class="link link--pointing dot__wrapper mod-related__block link--zoom-image" href="/WindergyIndiaConferenceandExhibition" title="Read more" rel="noopener">
                                <div class="media-wrapper block__media block__media--standard">
                                        <img class="img-cover" src="/img/Windergy-India-Conference-and-Exhibition.jpg" alt="Windergy India Conference and Exhibition" />
                                </div>
                                <div class="mod-related__link">
                                    <div class="mod-related__content">
                                         <h3 class="text--bold text--small">
                                            <span>Windergy India Conference and Exhibition</span>
                                        </h3>

                                        <div class="block__text text--dark">
                                            <span class="dot"></span>
                                            <span>The Windergy India 2017 conference and exhibition was a landmark event for the wind energy industry in India, held from April 25-27, 2017 in New Delhi. The event was organized...</span>
                                        </div>
                                    </div>

                                    <span>
                                        <span class="link__text">Read more</span>
                                        <span class="icon icon--middle icon-arrow-right"></span>
                                    </span>
                                </div>
                            </a>
                        </article>
                    </li>
                    <li class="grid__item md--one-third mod-related__item">
                        <article class="block text--small mod-related__block">
                            <a class="link link--pointing dot__wrapper mod-related__block link--zoom-image" href="/CONCRETEConferenceandExhibition" title="Read more" rel="noopener">
                                <div class="media-wrapper block__media block__media--standard">
                                        <img class="img-cover" src="/img/CONCRETE-Conference-and-Exhibition.jpg" alt="CONCRETE Conference and Exhibition" />
                                </div>
                                <div class="mod-related__link">
                                    <div class="mod-related__content">
                                      
                                        <h3 class="text--bold text--small">
                                            <span>CONCRETE Conference and Exhibition</span>
                                        </h3>

                                        <div class="block__text text--dark">
                                            <span class="dot"></span>
                                            <span>CIMGLOBAL, a leading event management company, successfully concluded the CONCRETE 2017 Conference and Exhibition on Underground Structures, held on July 14-15, 2017 at Hotel the Royal Plaza in New Delhi...</span>
                                        </div>
                                    </div>

                                    <span>
                                        <span class="link__text">Read more</span>
                                        <span class="icon icon--middle icon-arrow-right"></span>
                                    </span>
                                </div>
                            </a>
                        </article>
                    </li>
            </ul>
        </div>
    </div>  
           
         
    </article>
</div>
        </>
    )
}

export default CONCRETEConferenceandExhibition