import http from "./httpcommon";
import authHeader from "./authHeader";

const getBlogList = () => {
  return http.get(`posts`);
};
const getCaseStudyList = () => {
  return http.get(`study`);
};
const singalPost = (id) => {
  return http.get(`single/post/${id}`);
};
const singalCase = (id) => {
  return http.get(`single/study/${id}`);
};

const AllBlogList = {
  getBlogList,
  getCaseStudyList,
  singalPost,
  singalCase
};

export default AllBlogList;
