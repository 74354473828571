import Carousel from "../../component/carousel"
import { Helmet } from "react-helmet"

const AnnualMeetingoftheInternationalSocietyofBloodPurification = ()=>{
    return(
        <>
        <Helmet>
                <meta charSet="utf-8" />
                <title>Bidding and Winning the 37th Annual Meeting of the International Society of Blood Purification (ISBP)</title>
                <link rel="canonical" href="https://cimglobal.net/" />
            </Helmet>
         <Carousel
         mainText={
            `Bidding services - Elevate Your Global Presence`
         }
         smallText={
            `Decades of experience securing prestigious international conferences with strategic bidding.`
         }
         images={["img/slide-01.jpg","img/slide-01.jpg","img/slide-01.jpg"]}
         />
        <div className="page-contextual page-contextual--article"  >


<nav id="articleNav" className="nav-contextual ">
    <div className="accordion accordion--mobile">
        <div className="nav-contextual__head">
            <span className="heading-5 nav-contextual__current">Go to top</span>
            <button className="btn accordion__btn">
                <span className="icon icon-chevron"></span>
                <span className="u-visually-hidden">Expand</span>     
            </button>
        </div>
        <div className="accordion__content">
            <ul className="nav-contextual__list">
                    <li className="nav-contextual__item dot__wrapper text--xsmall">
                        <a className="nav-contextual__link" href="#top" title="">
                            <span className="dot"></span>
                            Go to top
                        </a>
                    </li>
                    <li className="nav-contextual__item dot__wrapper text--xsmall">
                        <a className="nav-contextual__link" href="#Overview" title="">
                            <span className="dot"></span>
                            Overview
                        </a>
                    </li>
                    <li className="nav-contextual__item dot__wrapper text--xsmall">
                        <a className="nav-contextual__link" href="#Bidding" title="">
                            <span className="dot"></span>
                            CIMGLOBAL's Bidding Expertise
                        </a>
                    </li>
                    <li className="nav-contextual__item dot__wrapper text--xsmall">
                        <a className="nav-contextual__link" href="#City" title="">
                            <span className="dot"></span>
                            Hyderabad as the Host City
                        </a>
                    </li>
                    <li className="nav-contextual__item dot__wrapper text--xsmall">
                        <a className="nav-contextual__link" href="#Impact" title="">
                            <span className="dot"></span>
                            Impact and Outcomes
                        </a>
                    </li>
                    <li className="nav-contextual__item dot__wrapper text--xsmall">
                        <a className="nav-contextual__link" href="#Facts" title="">
                            <span className="dot"></span>
                            Key Facts
                        </a>
                    </li>
                 
            </ul>
           
        </div>
    </div>
</nav>

    <article className="article">
            <div id="top">
                
<section className="hero-article" id="5532">
<div className="content-wrapper">
        <h2 className="heading-2 hero-article__heading">Bidding and Winning the 37th Annual Meeting of the International Society of Blood Purification (ISBP)</h2>

        

    <div className="media-wrapper hero-article__media link--zoom-image">
            <img className="img-cover" src="./img/Annual-Meeting-of-the-International-Society-of-Blood-Purification.jpg" alt="Bidding and Winning the 37th Annual Meeting of the International Society of Blood Purification (ISBP)"/></div>
    </div>
</section>
            </div>
            <div id="">
                <section className="mod-two-cols mod-two-cols--no-padding" data-lazy-load>
<div className="content-wrapper">
   {/* <div className="">
            
    </div>  */}
    <div className="">
            <div>
<div id="Overview">
<div className="block-text">
<h3 className="heading-3 block-text__header grid__item md--two-quarters push--md--one-quarter">Overview</h3>
<div className="grid grid--flex">
<aside className="grid__item md--one-quarter block-text__labels">
<ul className="block-text__labels-list">
</ul>
</aside>

<div className="grid__item md--three-quarters block-text__content">
<p className="lg--two-thirds text--large block-text__subheader">
CIMGLOBAL, a premier global meetings and associations management company, has successfully won the bid to manage the 37th Annual Meeting of the International Society of Blood Purification (ISBP) in 2019. The event was held from August 21-24, 2019, in Hyderabad, India, in partnership with the Peritoneal Dialysis Society of India.  
</p>
<p className="lg--two-thirds text--large block-text__subheader">
The ISBP congress attracted more than 1,000 delegates from various countries, showcasing the latest international achievements and the most up-to-date methods used in the field of blood purification. 
</p>
</div>
</div>
</div>
</div>
<div id="Bidding">
<div className="block-text">
<h3 className="heading-3 block-text__header grid__item md--two-quarters push--md--one-quarter">CIMGLOBAL's Bidding Expertise</h3>
<div className="grid grid--flex">
<aside className="grid__item md--one-quarter block-text__labels">
<ul className="block-text__labels-list">
</ul>
</aside>

<div className="grid__item md--three-quarters block-text__content">
<p className="lg--two-thirds text--large block-text__subheader">
CIMGLOBAL 's expertise in bidding and winning major international conferences and events was instrumental in securing the ISBP 2019 congress for Hyderabad. The company's comprehensive understanding of the event management industry, combined with its proven track record of delivering successful events, gave it a competitive edge in the bidding process.  
</p>
<p className="lg--two-thirds text--large block-text__subheader">
CIMGLOBAL's bidding strategy involved a thorough analysis of the event's requirements, the target audience, and the host city's capabilities. The company's extensive experience in managing similar international conferences and its strong partnerships with local organizations, such as the Peritoneal Dialysis Society of India, further strengthened its bid. 
</p>
</div>
</div>
</div>
</div>
                    <div id="City">
                        
<div className="block-text">
<h3 className="heading-3 block-text__header grid__item md--two-quarters push--md--one-quarter">Hyderabad as the Host City</h3>
<div className="grid grid--flex">
    <aside className="grid__item md--one-quarter block-text__labels">
            <ul className="block-text__labels-list">
            </ul>
    </aside>

    <div className="grid__item md--three-quarters block-text__content">
            <p className="lg--two-thirds text--large block-text__subheader">
            Hyderabad, the chosen host city for the ISBP 2019 congress, is an ideal location for the event. As one of India's major metropolitan cities, Hyderabad is known for its rich cultural heritage, booming IT and biopharmaceutical industries, and diverse array of attractions. </p>
        
       
    </div>
</div>
</div>

                    </div>
<div id="Impact">
                        
<div className="block-text">
<h3 className="heading-3 block-text__header grid__item md--two-quarters push--md--one-quarter">Impact and Outcomes</h3>
<div className="grid grid--flex">
    <aside className="grid__item md--one-quarter block-text__labels">
            <ul className="block-text__labels-list">
            </ul>
    </aside>

    <div className="grid__item md--three-quarters block-text__content">
            <p className="lg--two-thirds text--large block-text__subheader">
            The successful bid and hosting of the ISBP 2019 congress in Hyderabad had a significant impact on the city and the region. The event showcased India's capabilities in the field of blood purification and attract global attention to the country's medical and scientific advancements. 
            </p>
            <p className="lg--two-thirds text--large block-text__subheader">
            For CIMGLOBAL, the win further solidifies its reputation as a leading event management company with expertise in bidding and delivering successful international conferences. The company's ability to leverage its extensive industry knowledge, strong partnerships, and deep understanding of the host city's capabilities has been instrumental in securing this prestigious event. 
            </p>
     
    </div>
</div>
</div>

                    </div>

<div id="Facts">
<div className="block-text">
<h3 className="heading-3 block-text__header grid__item md--two-quarters push--md--one-quarter">Key Facts</h3>
<div className="grid grid--flex">
    <aside className="grid__item md--one-quarter block-text__labels">
            <ul className="block-text__labels-list">
            </ul>
    </aside>

    <div className="grid__item md--three-quarters block-text__content">
            <p className="lg--two-thirds text--large block-text__subheader">
<ul className="block-text__labels-list">
<li>o	CIMGLOBAL, in partnership with the Peritoneal Dialysis Society of India, has won the bid to manage the 37th Annual Meeting of the International Society of Blood Purification (ISBP) in 2019.</li>
<li>o	The ISBP congress was held from August 21-24, 2019, in Hyderabad, India, and attracted more than 1,000 delegates from various countries.</li>
<li>o	CIMGLOBAL's expertise in bidding and winning major international conferences, combined with its comprehensive understanding of the event management industry and strong local partnerships, were key factors in securing the ISBP 2019 congress.</li>
<li>o	Hyderabad, the host city, is known for its rich cultural heritage, booming industries, and diverse array of attractions, making it an ideal location for the ISBP 2019 congress.</li>
<li>o	The successful hosting of the ISBP 2019 congress in Hyderabad showcased India's capabilities in the field of blood purification and attract global attention to the country's medical and scientific advancements.</li>
<li>o	The win further solidifies CIMGlobal's reputation as a leading event management company with expertise in bidding and delivering successful international conferences.</li>
            </ul>
            </p>
      
    </div>
</div>
</div>


                    </div>
                  
            </div>
    </div>
</div>
</section>



            </div>
            <div class="grid mod-related mod-related--light mod-related--has-read-more" data-lazy-load>
        <div class="grid__item lg--nine-twelfths">
            <h2 class="heading-2 mod-related__header grid--flex-center">
                <span class="mod-related__header-text">Case Stories</span>
                   
            </h2>
            <ul class="grid grid--flex">
                    <li class="grid__item md--one-third mod-related__item">
                        <article class="block text--small mod-related__block">
                            <a class="link link--pointing dot__wrapper mod-related__block link--zoom-image" href="/AnnualMeetingoftheInternationalSocietyofBloodPurification" title="Read more" rel="noopener">
                                <div class="media-wrapper block__media block__media--standard">
                                        <img class="img-cover" src="/img/Annual-Meeting-of-the-International-Society-of-Blood-Purification.jpg" alt="Bidding and Winning the 37th Annual Meeting of the International Society of Blood Purification (ISBP)" />
                                </div>
                                <div class="mod-related__link">
                                    <div class="mod-related__content">
                                      

                                        <h3 class="text--bold text--small">
                                            <span>Bidding and Winning the 37th Annual Meeting of the International Society of Blood Purification (ISBP)</span>
                                        </h3>

                                        <div class="block__text text--dark">
                                            <span class="dot"></span>
                                            <span>CIMGLOBAL, a premier global meetings and associations management company, has successfully won the bid to manage the 37th Annual Meeting of the International Society of Blood Purification (ISBP) in 2019...</span>
                                        </div>
                                    </div>

                                    <span>
                                        <span class="link__text">Read more</span>
                                        <span class="icon icon--middle icon-arrow-right"></span>
                                    </span>
                                </div>
                            </a>
                        </article>
                    </li>
                    <li class="grid__item md--one-third mod-related__item">
                        <article class="block text--small mod-related__block">
                            <a class="link link--pointing dot__wrapper mod-related__block link--zoom-image" href="/InternationalSTEMFest" title="Read more" rel="noopener">
                                <div class="media-wrapper block__media block__media--standard">
                                        <img class="img-cover" src="/img/3rd-International-STEMFest.jpg" alt="Bidding and Winning the 3rd International STEMFest" />
                                </div>
                                <div class="mod-related__link">
                                    <div class="mod-related__content">
                                        

                                        <h3 class="text--bold text--small">
                                            <span>Bidding and Winning the 3rd International STEMFest</span>
                                        </h3>

                                        <div class="block__text text--dark">
                                            <span class="dot"></span>
                                            <span>The 3rd International Festival of Science, Technology, Engineering & Mathematics (STEMFest) 2016 was a major science and technology event hosted in Mysore, India from October 17-20, 2016...</span>
                                        </div>
                                    </div>

                                    <span>
                                        <span class="link__text">Read more</span>
                                        <span class="icon icon--middle icon-arrow-right"></span>
                                    </span>
                                </div>
                            </a>
                        </article>
                    </li>
                    <li class="grid__item md--one-third mod-related__item">
                        <article class="block text--small mod-related__block">
                            <a class="link link--pointing dot__wrapper mod-related__block link--zoom-image" href="/WWWConference" title="Read more" rel="noopener">
                                <div class="media-wrapper block__media block__media--standard">
                                        <img class="img-cover" src="/img/WWW-Conference-2011.jpg" alt="Bidding and Organizing the WWW Conference " />
                                </div>
                                <div class="mod-related__link">
                                    <div class="mod-related__content">
                                        

                                        <h3 class="text--bold text--small">
                                            <span>Bidding and Organizing the WWW Conference </span>
                                        </h3>

                                        <div class="block__text text--dark">
                                            <span class="dot"></span>
                                            <span>CIMGLOBAL, a leading global meetings and associations management company, has demonstrated its expertise in bidding and organizing major international conferences. One such example is the...</span>
                                        </div>
                                    </div>

                                    <span>
                                        <span class="link__text">Read more</span>
                                        <span class="icon icon--middle icon-arrow-right"></span>
                                    </span>
                                </div>
                            </a>
                        </article>
                    </li>
            </ul>
        </div>
    </div>
          
    </article>
</div>
        </>
    )
}

export default AnnualMeetingoftheInternationalSocietyofBloodPurification