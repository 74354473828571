import Carousel from "../../component/carousel"
import News from "../../component/news"

const TermsandCondition = ()=>{
return(
<>
<Carousel
mainText={
`Terms and Condition`
}
smallText={
``
}
images={["img/slide-01.jpg","img/slide-01.jpg","img/slide-01.jpg"]}
/>
<section className="mod-two-cols" data-lazy-load>

<div className="">

<div className="grid">
<div className="grid__item md--two-thirds mod-two-cols--padded">
<h3 className="heading-2"><span>Privacy & Cookies Policy</span></h3>
<p style={{fontSize:"20px",lineHeight:"120%",marginTop:"15px"}}>
<b>Introduction</b><br/>
Thank you for visiting www.cimglobal.net This site is owned and operated by CIMGlobal.
<br/><br/>
<b>Website Copyright</b><br/>
The copyright in the material on this web site belongs to CIMGlobal. Your access to it does not imply a license to reproduce and/or distribute this information. This means that you cannot reproduce or distribute this information.
</p>
<div className="heading-2">
<p><br/></p>
</div>
</div>
</div>

<div className="grid">
<div className="grid__item md--two-thirds mod-two-cols--padded">
<h3 className="heading-2"><span>CIMGlobal Privacy Policy</span></h3>
<p style={{fontSize:"20px",lineHeight:"120%",marginTop:"15px"}}>We respect the privacy of our customers and we are committed to the following privacy policy, which is designed to give you confidence.
<br/><br/>
The data collected in this website are only use to process reservations and communication with customers, suppliers, information requests and any complaints, statistical analysis, as well as direct marketing purposes. Whether you are a conference participant, booking a service, requesting a brochure or other information, we will ask for your name, company name, address, contact number and e-mail address.
<br/><br/>
CIMGlobal guarantees the confidentiality of any data supplied by its Clients. Nevertheless, CIMGlobal shall collect and process the data in a safely way to preventing its loss or manipulation, using more refined techniques for the purpose. However, gathering in open network allows the movement of personal data without security, at the risk of being seen and used by unauthorized net users.
<br/><br/>
All Clients have the right of access, rectification, cancellation and opposition of their data. If you wish no longer be part of the CIMGlobal database at any time you may exercise this right through the email: unsubscribe@cimglobal.net
<br/><br/>
Client expressly authorizes CIMGlobal to use personal data to send information about products and services that may be of interest or for direct marketing purposes through any communication channel, including electronic e-mail, SMS, MMS or other forms of automatic call.
<br/><br/>
CIMGlobal does not sell or share customers, participants database with others.

<br/><br/>
<b>COOKIE POLICY</b><br/>
To make our website easier and more enjoyable, we use cookies throughout the site. Cookies are little pieces of data that allow us to compare new and past visitors, understand how users navigate through our site, and provide us with data that we can use to make the experience more enjoyable and more effective in the future. Cookies do not log any personal information about a user, and identifiable data will never be stored. If you would like to avoid the use of cookies, you must tailor your computer settings to erase all website Cookies and/or notify you via a warning if cookies are being stored. To proceed without changes to the cookie implementation, simply continue to the site.

<br/><br/>
<b>The types of cookie we use:</b><br/>
<u>Strictly necessary cookies</u><br/>

These cookies are essential in order to enable you to move around the website and use its features, such as accessing secure areas of the website. Without these cookies services you have asked for, like competition forms etc, cannot be provided. 
<br/><br/>
<u>Performance cookies</u><br/>

These cookies collect information about how visitors use a website, for instance which pages visitors go to most often, and if they get error messages from web pages. These cookies don’t collect information that identifies a visitor. All information these cookies collect is aggregated and therefore anonymous. It is only used to improve how a website works.
<br/><br/>
By using our website, you agree that we can place these types of cookies on your device. 
<br/><br/>
<u>Functionality Cookies</u><br/>

These cookies allow the website to remember choices you make and provide enhanced, more personal features. These cookies can also be used to remember changes you have made to text size, fonts and other parts of web pages that you can customise. They may also be used to provide services you have asked for such as watching a video or commenting on a blog. The information these cookies collect may be anonymised and they cannot track your browsing activity on other websites.
<br/><br/>
By using our website, you agree that we can place these types of cookies on your device.
<br/><br/>
<u>Newsletter Subscribers</u><br/>

Should you subscribe to the CIMGlobal newsletter, directly through the CIMGlobal websites/enquire forms you will receive newsletters per month sent. This will include news about CIMGlobal, products, or other information. You can unsubscribe from this list by clicking on the ‘unsubscribe’ button or by emailing us on unsubscribe@cimglobal.net informing us about your wish to be removed from the mailing list. Please write “unsubscribe” in the subject line.

</p>

</div>
</div>
</div>
</section>



<News/>
</>
)
}

export default TermsandCondition