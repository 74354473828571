import { Helmet } from "react-helmet"
import Carousel from "../component/carousel"
import News from "../component/news"
const Contact = () => {
return (
<>
<Helmet>
<meta charSet="utf-8" />
<title>Contact</title>
<link rel="canonical" href="https://cimglobal.net/" />
</Helmet>
<Carousel
mainText={
`Contacts and Locations`
}
smallText={
`Please feel free to get in touch with us`
}
images={["img/contact.jpg", "img/contact1.jpg"]}
/>
<section className="mod-two-cols" data-lazy-load>
<div className="">
<div class="grid mod-related mod-related--light mod-related--has-read-more" data-lazy-load>
<div class="grid__item lg--nine-twelfths">
<ul class="grid grid--flex">
<li class="grid__item md--one-third mod-related__item">
<article class="block text--small mod-related__block">
<div class="mod-related__link">
<div class="mod-related__content">
<h3 class="text--bold text--small"> <span>Asia</span> </h3>
<div class="block__text text--dark"> <span><b>India(Delhi)</b></span></div>
<p style={{fontSize:"16px",lineHeight:"120%",marginTop:"15px"}}>C-1/D, First Floor,<br/>Green Park Extension,<br/>New Delhi 110016<br/>
</p>
<div class="block__text text--dark"> <span><b>India(Mumbai)</b></span></div>
<p style={{fontSize:"16px",lineHeight:"120%",marginTop:"15px"}}>401, 4th Floor, Solaris-1 Premises<br/>Co-operative Society Ltd,<br/>
Saki Vihar Road, Powai , Mumbai – 400072<br/>
<b>E:</b> info@cimglobal.net
</p>

</div>
</div>
</article>
</li>

<li class="grid__item md--one-third mod-related__item">
<article class="block text--small mod-related__block">
<div class="mod-related__link">
<div class="mod-related__content">
<h3 class="text--bold text--small"> <span>Asia</span> </h3>
<div class="block__text text--dark"> <span><b>Singapore</b></span></div>
<p style={{fontSize:"16px",lineHeight:"120%",marginTop:"15px"}}>15 Queen Street, #03-03 Tan Chong<br/>Tower, Singapore 188537<br/>
<b>E:</b> info@cimglobal.net
</p>

</div>
</div>
</article>
</li>

<li class="grid__item md--one-third mod-related__item">
<article class="block text--small mod-related__block">
<div class="mod-related__link">
<div class="mod-related__content">
<h3 class="text--bold text--small"> <span>Europe</span> </h3>
<div class="block__text text--dark"> <span><b>LISBON</b></span></div>
<p style={{fontSize:"16px",lineHeight:"120%",marginTop:"15px"}}>Rua Bartolomeu Perestrelo<br/>
Numero 13, 3o D, Odivelas,<br/>
Lisbon, Portugal<br/>
<b>E:</b> info@cimglobal.net
</p>

</div>
</div>
</article>
</li>
<li class="grid__item md--one-third mod-related__item">
<article class="block text--small mod-related__block">
<div class="mod-related__link">
<div class="mod-related__content">
<h3 class="text--bold text--small"> <span>Middle East</span> </h3>
<div class="block__text text--dark"> <span><b>DUBAI</b></span></div>
<p style={{fontSize:"16px",lineHeight:"120%",marginTop:"15px"}}>Aspin Commercial Tower,<br/>44th floor, Sheikh Zayed Road,<br/>Dubai.<br/>
<b>E:</b> info@cimglobal.net
</p>

</div>
</div>
</article>
</li>
</ul>
</div>
</div>
</div>
</section>
<News />
</>
)
}
export default Contact