import Carousel from "../../component/carousel"
import News from "../../component/news";
const CorporateGovernance = ()=>{
return(
<>
<Carousel
mainText={
`Corporate Governance`
}
smallText={
`Corporate Social Responsibility (CSR) Policy `
}
images={["img/Corporate-Governance.jpg"]}
/>
<section className="mod-two-cols" data-lazy-load>

<div className="">

<div className="grid">
<div className="grid__item md--two-thirds mod-two-cols--padded">
  {/* <h3 className="heading-1"><span>Corporate Social Responsibility (CSR) Policy</span></h3> -->
 
<ul className="list-metrics">
<li>
    <div className="metrics metrics--large metrics--base-info">
                                   <div className="text--small md--one-whole">
            <div className="text--bold">Creating Content, Connecting People</div>
           <div>
                <span className="dot"></span>
                    <div>At the core of our mission is a profound belief in the power of connection. </div>
            </div>

        </div>
    </div>
</li>
</ul>*/}



<article className="mod-text-media mod-text-media__scheme--Light " data-lazy-load>
<div className="mod-text-media__wrapper grid ">
<div className="grid  lg--one-whole md-one-whole">


<div className="grid__item">
<div className="heading-2">
<h3>Introduction:</h3>

</div>
<p style={{fontSize:"20px",lineHeight:"120%",marginTop:"15px"}}>At CIMGLOBAL, we recognize our responsibility to contribute positively to society and the environment in which we operate. Our Corporate Social Responsibility (CSR) policy reflects our commitment to making a meaningful impact on communities and the planet. We strive to uphold ethical business practices, support social causes, and minimize our environmental footprint in all aspects of our operations.
</p>
</div>

</div>
</div>
</article>


</div>
</div>
</div>
</section>

<article className="mod-text-media mod-text-media__scheme--Light mod-two-cols--padded " data-lazy-load>
<div className="mod-text-media__wrapper grid ">
<div className="grid  lg--two-thirds md-one-whole">


<div className="grid__item     
md--one-half">
<div className="mod-text__media">
<div className="media-wrapper mod-text__media">
<div className="mod-overview__media">
    <a className="link--zoom-image">
        <img className="img-cover" src="/img/Ethical-Business-Practices.jpg" alt="Ethical Business Practices"/>  
    </a>   
</div>
</div>
</div>
</div>
<div className="grid__item md--one-half">
<div className="mod-text__content">
<h3 className="heading-3 mod-text__header mod-text-media__header">Ethical Business Practices:</h3>
<div className="text--large mod-text-media__content">
<p style={{fontSize:"20px",lineHeight:"120%",marginTop:"5px"}}>
<ul style={{listStyle:"disc",marginLeft:"25px",fontSize:"20px",lineHeight:"120%",marginTop:"15px"}}>
<li>We adhere to the highest standards of integrity, honesty, and transparency in all our dealings.</li>
<li>We comply with applicable laws and regulations governing our business activities.</li>
<li>We promote fair and ethical treatment of our employees, clients, partners, and suppliers.</li>
</ul></p>
        
</div>
    
</div>
</div>
</div>
</div>
</article>

<article className="mod-text-media mod-text-media__scheme--Light" data-lazy-load>
<div className="mod-text-media__wrapper grid">
<div className="grid push--lg--one-third lg--two-thirds md-one-whole">

<div className="grid__item md--one-half">
<div className="mod-text__content">
<h3 className="heading-3 mod-text__header mod-text-media__header">Community Engagement:</h3>
<div className="text--large mod-text-media__content">
<p style={{fontSize:"20px",lineHeight:"120%",marginTop:"5px"}}>
<ul style={{listStyle:"disc",marginLeft:"25px",fontSize:"20px",lineHeight:"120%",marginTop:"15px"}}>
<li>We actively engage with local communities to understand their needs and priorities.</li>
<li>We support community development initiatives, with a focus on education, healthcare, and social welfare.</li>
<li>We encourage our employees to volunteer their time and skills for community service projects.</li>
</ul>
    </p>
</div>
        
</div>
</div>


<div className="grid__item md--one-half">
<div className="mod-text__media">
<div className="media-wrapper mod-text__media">
<div className="mod-overview__media">
    <a href="#" className="link--zoom-image">
        <img className="img-cover" src="/img/Community-Engagement.jpg" alt="Community Engagement"/>  
    </a>   
</div>
</div>
</div>
</div>        </div>
</div>
</article>

<article className="mod-text-media mod-text-media__scheme--Light mod-two-cols--padded" data-lazy-load>
<div className="mod-text-media__wrapper grid">
<div className="grid  lg--two-thirds md-one-whole">

<div className="grid__item     
md--one-half">
<div className="mod-text__media">
<div className="media-wrapper mod-text__media">
<div className="mod-overview__media">
    <a className="link--zoom-image">
        <img className="img-cover" src="/img/Environmental-Sustainability.jpg" alt="Environmental Sustainability"/>  
    </a>   
</div>
</div>
</div>
</div>
<div className="grid__item md--one-half">
<div className="mod-text__content">
<h3 className="heading-3 mod-text__header mod-text-media__header">Environmental Sustainability:</h3>
<div className="text--large mod-text-media__content">
<p style={{fontSize:"20px",lineHeight:"120%",marginTop:"5px"}}>
<ul style={{listStyle:"disc",marginLeft:"25px",fontSize:"20px",lineHeight:"120%",marginTop:"15px"}}>
<li>We are committed to minimizing our environmental impact through sustainable practices.</li>
<li>We reduce energy consumption, promote recycling and waste reduction, and minimize greenhouse gas emissions.</li>
<li>We encourage the use of eco-friendly products and services in our operations and events.</li>
</ul>

</p>
        
</div>
    
</div>
</div>
</div>
</div>
</article>

<article className="mod-text-media mod-text-media__scheme--Light" data-lazy-load>
<div className="mod-text-media__wrapper grid">
<div className="grid push--lg--one-third lg--two-thirds md-one-whole">

<div className="grid__item md--one-half">
<div className="mod-text__content">
<h3 className="heading-3 mod-text__header mod-text-media__header">Employee Well-being:</h3>
<div className="text--large mod-text-media__content">
<p style={{fontSize:"20px",lineHeight:"120%",marginTop:"5px"}}>
<ul style={{listStyle:"disc",marginLeft:"25px",fontSize:"20px",lineHeight:"120%",marginTop:"15px"}}>
<li>We prioritize the health, safety, and well-being of our employees.</li>
<li>We provide a supportive work environment that fosters professional growth, diversity, and inclusion.</li>
<li>We offer opportunities for training, development, and work-life balance to enhance employee satisfaction and productivity.</li>
</ul>
    </p>
</div>
        
</div>
</div>


<div className="grid__item md--one-half">
<div className="mod-text__media">
<div className="media-wrapper mod-text__media">
<div className="mod-overview__media">
    <a href="#" className="link--zoom-image">
        <img className="img-cover" src="/img/Employee-Well-being.jpg" alt="Employee Well-being"/>  
    </a>   
</div>
</div>
</div>
</div>        </div>
</div>
</article>

<article className="mod-text-media mod-text-media__scheme--Light mod-two-cols--padded" data-lazy-load>
<div className="mod-text-media__wrapper grid">
<div className="grid  lg--two-thirds md-one-whole">

<div className="grid__item     
md--one-half">
<div className="mod-text__media">
<div className="media-wrapper mod-text__media">
<div className="mod-overview__media">
    <a className="link--zoom-image">
        <img className="img-cover" src="/img/Partnerships-and-Collaboration.jpg" alt="Partnerships and Collaboration"/>  
    </a>   
</div>
</div>
</div>
</div>
<div className="grid__item md--one-half">
<div className="mod-text__content">
<h3 className="heading-3 mod-text__header mod-text-media__header">Partnerships and Collaboration:</h3>
<div className="text--large mod-text-media__content">
<p style={{fontSize:"20px",lineHeight:"120%",marginTop:"5px"}}>
<ul style={{listStyle:"disc",marginLeft:"25px",fontSize:"20px",lineHeight:"120%",marginTop:"15px"}}>
<li>We collaborate with like-minded organizations, NGOs, and government agencies to address social and environmental challenges.</li>
<li>We seek partnerships that amplify our impact and create shared value for communities and stakeholders.</li>
<li>We promote knowledge-sharing and best practices in CSR within our industry and beyond.</li>
</ul>

</p>
        
</div>
    
</div>
</div>
</div>
</div>
</article>

<article className="mod-text-media mod-text-media__scheme--Light" data-lazy-load>
<div className="mod-text-media__wrapper grid">
<div className="grid push--lg--one-third lg--two-thirds md-one-whole">

<div className="grid__item">
<div className="mod-text__content">
<h3 className="heading-3 mod-text__header mod-text-media__header">Conclusion:</h3>
<div className="text--large mod-text-media__content">
<p style={{fontSize:"20px",lineHeight:"120%",marginTop:"5px"}}>At CIMGLOBAL, we believe that corporate social responsibility is not just a choice but a fundamental part of who we are as a company. By aligning our business goals with the well-being of society and the environment, we strive to create a more sustainable and inclusive world for future generations. Through our collective efforts, we aim to make a positive difference and leave a lasting legacy of responsible business practices.
    </p>
</div>
        
</div>
</div>

       </div>
</div>
</article>

<News/>
</>
)
}

export default CorporateGovernance