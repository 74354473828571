import Carousel from "../../component/carousel"
import News from "../../component/news";
const Ourstory = ()=>{
    return(
        <>
         <Carousel
         mainText={
            `Our Story`
         }
         smallText={
            `Explore the journey that shaped who we are today`
         }
         images={["img/slide-01.jpg"]}
         />



<article className="mod-text-media mod-text-media__scheme--Light" data-lazy-load>
    <div className="mod-text-media__wrapper grid">
        <div className="grid push--lg--one-third lg--two-thirds md-one-whole">

            <div className="grid__item md--one-half">
                <div className="mod-text__content">
                    <h3 className="heading-3 mod-text__header">The journey begins</h3>
                    <div className="text--large mod-text-media__content">
                    <p style={{listStyle:"disc", fontSize:"20px",lineHeight:"120%",marginTop:"5px"}}>
    Since its inception in 1997, CIMGLOBAL has been a trailblazer in the realm of association and corporate services in India. Recognizing a unique opportunity, we expanded our horizons to the Middle East in 2009, a strategic move in the wake of the 2008 crisis. Building on our success, we ventured into Europe in 2014, solidifying our presence with the acquisition of Congrex SA in Amsterdam. From 2023, we proudly call Lisbon our European home base, poised to continue our journey of innovation and excellence on a global scale. Join us as we write the next chapter of our story together.
  
</p>
                    </div>
                           
                </div>
            </div>


<div className="grid__item     
     md--one-half">
    <div className="mod-text__media">
        <div className="media-wrapper mod-text__media">
                <div className="mod-overview__media">
                        <a href="#" className="link--zoom-image">
                            <img className="img-cover" src="/img/journey-begins.jpg" alt="Conference Project Management and Delivery"/>  
                        </a>   
                </div>
        </div>
    </div>
</div>        </div>
    </div>
</article>

<article className="mod-text-media mod-text-media__scheme--Light" data-lazy-load>
    <div className="mod-text-media__wrapper grid">
        <div className="grid  lg--two-whole  md-one-whole">

            <div className="grid__item md--one-full">
                <div className="mod-text__content">
                    <h3 style={{border:"0px"}} className="heading-3 mod-text__header mod-text-media__header">Awards</h3>
                    <div className="text--large">
                    <a href="img/Forbes.jpg" target="_blank" className="link--zoom-image"> <img src="img/Forbes_logo.png" style={{width:"181px",height:"84px" ,position:"static", float:"left"}}/></a>
                    <img src="img/gmi-logo.png" style={{width:"145px",height:"84px",position:"static", float:"left"}}/>
                    <a href="img/btvi.jpg" target="_blank" className="link--zoom-image"> <img src="img/btvi.png" style={{width:"244px",height:"84px" ,position:"static", float:"left"}}/></a>
                    <a href="img/asia-one_certificate.jpg" target="_blank" className="link--zoom-image"> <img src="img/asia-one.png" style={{width:"255px",height:"84px",position:"static", float:"left"}}/> </a>
                    
                        
                         
                    </div>
                       
                </div>

            </div>
        </div>
    </div>
</article>
<article className="mod-text-media mod-text-media__scheme--Light" data-lazy-load>
    <div className="mod-text-media__wrapper grid">
        <div className="grid push--lg--one-third lg--one-whole md-one-whole">

            <div className="grid__item md--one-half">
                <div className="mod-text__content">
                    <br></br><br></br>
                    <h3 className="heading-3 mod-text__header mod-text-media__header">Memberships and Partners</h3>
                    <div className="text--large">
                    <img src="img/asae.jpg" style={{width:"150px",height:"98px" ,position:"static", float:"left"}}/>
                    <img src="img/Association-Forum.jpg"  style={{width:"150px" ,position:"static", float:"left"}}/>
                    <img src="img/hcvb.jpg" style={{width:"150px",height:"98px" ,position:"static", float:"left"}}/>
                    <img src="img/icca.jpg" style={{width:"150px",height:"98px" ,position:"static", float:"left"}}/>
                    <img src="img/mpi.jpg"  style={{width:"150px",height:"98px" ,position:"static", float:"left"}}/>
                    <img src="img/pcma.jpg" style={{width:"150px",height:"98px" ,position:"static", float:"left"}}/>
                    </div>
                           
                </div>
            </div>


       </div>
    </div>
</article>

<article className="mod-text-media mod-text-media__scheme--Light mod-two-cols--padded" data-lazy-load>
<div className="mod-text-media__wrapper grid">
<div className="grid  lg--two-thirds md-one-whole">

<div className="grid__item     
md--one-half">
<div className="mod-text__media">
<div className="media-wrapper mod-text__media">
<div className="mod-overview__media">
    <a className="link--zoom-image">
        <img className="img-cover" src="/img/Our-Clients_ourstory.jpg" alt="Our Clients"/>  
    </a>   
</div>
</div>
</div>
</div>
<div className="grid__item md--one-half">
<div className="mod-text__content">
<h3 className="heading-3 mod-text__header mod-text-media__header">Our Clients:</h3>
<div className="text--large mod-text-media__content">
<p style={{fontSize:"20px",lineHeight:"120%",marginTop:"5px"}}>
With a client list that speaks volumes, CIMGLOBAL prides itself on fostering true partnerships built on trust, respect, and expertise

</p>
<br/>
<a href="/Clients" className="link--pointing link--pill ">
               <span className="link__animation">
               <span className="link__text">Read more</span>
               <span className="icon icon--middle icon-arrow-right"></span>
               </span>
               </a>
        
</div>
    
</div>
</div>
</div>
</div>
</article>

<article className="mod-text-media mod-text-media__scheme--Light" data-lazy-load>
<div className="mod-text-media__wrapper grid">
<div className="grid push--lg--one-third lg--two-thirds md-one-whole">

<div className="grid__item md--one-half">
<div className="mod-text__content">
<h3 className="heading-3 mod-text__header mod-text-media__header">Career:</h3>
<div className="text--large mod-text-media__content">
<p style={{fontSize:"20px",lineHeight:"120%",marginTop:"5px"}}>
At CIMGLOBAL, we are passionate about fostering a diverse and inclusive workplace where everyone has an equal opportunity to thrive, regardless of their background
    </p>
    <br/>
                <a href="/Career" className="link--pointing link--pill ">
                    <span className="link__animation">
                        <span className="link__text">Read more</span>
                        <span className="icon icon--middle icon-arrow-right"></span>
                    </span>
                </a>
</div>
        
</div>
</div>


<div className="grid__item md--one-half">
<div className="mod-text__media">
<div className="media-wrapper mod-text__media">
<div className="mod-overview__media">
    <a href="#" className="link--zoom-image">
        <img className="img-cover" src="/img/Community-Engagement.jpg" alt="Community Engagement"/>  
    </a>   
</div>
</div>
</div>
</div>        </div>
</div>
</article>





<News/>

        </>
    )
}

export default Ourstory