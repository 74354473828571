import Carousel from "../../component/carousel"
import { Helmet } from "react-helmet"

const ASIAFORGEMeetingandForgetechIndiaExhibition = ()=>{
    return(
        <>
        <Helmet>
                <meta charSet="utf-8" />
                <title>ASIAFORGE Meeting and Forgetech India Exhibition</title>
                <link rel="canonical" href="https://cimglobal.net/" />
            </Helmet>
         <Carousel
         mainText={
            `ASIAFORGE Meeting and Forgetech India Exhibition`
         }
         smallText={
            ` `
         }
         images={["img/slide-01.jpg","img/slide-01.jpg","img/slide-01.jpg"]}
         />
        <div className="page-contextual page-contextual--article"  >


<nav id="articleNav" className="nav-contextual ">
    <div className="accordion accordion--mobile">
        <div className="nav-contextual__head">
            <span className="heading-5 nav-contextual__current">Go to top</span>
            <button className="btn accordion__btn">
                <span className="icon icon-chevron"></span>
                <span className="u-visually-hidden">Expand</span>     
            </button>
        </div>
        <div className="accordion__content">
            <ul className="nav-contextual__list">
                    <li className="nav-contextual__item dot__wrapper text--xsmall">
                        <a className="nav-contextual__link" href="#ASIAFORGE-Meeting-and-Forgetech-India-Exhibition" title="">
                            <span className="dot"></span>
                            Go to top
                        </a>
                    </li>
                    <li className="nav-contextual__item dot__wrapper text--xsmall">
                        <a className="nav-contextual__link" href="#Overview" title="">
                            <span className="dot"></span>
                            Overview
                        </a>
                    </li>
                    <li className="nav-contextual__item dot__wrapper text--xsmall">
                        <a className="nav-contextual__link" href="#Objectives" title="">
                            <span className="dot"></span>
                            Objectives and Highlights
                        </a>
                    </li>
                    <li className="nav-contextual__item dot__wrapper text--xsmall">
                        <a className="nav-contextual__link" href="#Role" title="">
                            <span className="dot"></span>
                            CIMGLOBAL's Role
                        </a>
                    </li>
                    <li className="nav-contextual__item dot__wrapper text--xsmall">
                        <a className="nav-contextual__link" href="#Impact" title="">
                            <span className="dot"></span>
                            Impact and Outcomes
                        </a>
                    </li>
                    <li className="nav-contextual__item dot__wrapper text--xsmall">
                        <a className="nav-contextual__link" href="#Facts" title="">
                            <span className="dot"></span>
                            Key Facts
                        </a>
                    </li>
                 
            </ul>
           
        </div>
    </div>
</nav>

    <article className="article">
            <div id="ASIAFORGE-Meeting-and-Forgetech-India-Exhibition">
                
<section className="hero-article" id="5532">
<div className="content-wrapper">
        <h2 className="heading-2 hero-article__heading">ASIAFORGE Meeting and Forgetech India Exhibition</h2>

        

    <div className="media-wrapper hero-article__media link--zoom-image">
            <img className="img-cover" src="./img/ASIAFORGE-Meeting-and-Forgetech-India-Exhibition.jpg" alt="ASIAFORGE Meeting and Forgetech India Exhibition"/></div>
    </div>
</section>
            </div>
            <div id="">
                <section className="mod-two-cols mod-two-cols--no-padding" data-lazy-load>
<div className="content-wrapper">
   {/* <div className="">
            
    </div>  */}
    <div className="">
            <div>
<div id="Overview">
<div className="block-text">
<h3 className="heading-3 block-text__header grid__item md--two-quarters push--md--one-quarter">Overview</h3>
<div className="grid grid--flex">
<aside className="grid__item md--one-quarter block-text__labels">
<ul className="block-text__labels-list">
</ul>
</aside>

<div className="grid__item md--three-quarters block-text__content">
<p className="lg--two-thirds text--large block-text__subheader">
The ASIAFORGE Meeting is an international conference organized by the ASIAFORGE organization, which was established in 2005 by the Association of Indian Forging Industry (AIFI), Confederation of Chinese Metalforming Industry (CCMI), Korea Forging Industry Cooperative (KFC), Japan Forging Association (JFA), and Taiwan Forging Association (TFA). The 7th edition of the ASIAFORGE Meeting was hosted by AIFI in Chennai, India, from January 18-24, 2019.
</p>
<p className="lg--two-thirds text--large block-text__subheader">
Concurrent with the ASIAFORGE Meeting, AIFI also organized the second edition of the Forgetech India exhibition, a dedicated event for the forging industry in India. CIMGLOBAL, a leading event management company, was entrusted with the responsibility of managing both the ASIAFORGE Meeting and the Forgetech India exhibition.
</p>
</div>
</div>
</div>
</div>
<div id="Objectives">
<div className="block-text">
<h3 className="heading-3 block-text__header grid__item md--two-quarters push--md--one-quarter">Objectives and Highlights</h3>
<div className="grid grid--flex">
<aside className="grid__item md--one-quarter block-text__labels">
<ul className="block-text__labels-list">
</ul>
</aside>

<div className="grid__item md--three-quarters block-text__content">
<p className="lg--two-thirds text--large block-text__subheader">
The ASIAFORGE Meeting 2019 aimed to deepen the partnership and collaboration among the forging industry stakeholders across Asia, a strategically important region. The event featured the presentation of technical articles on forging-related subjects, as well as an exhibition showcasing the latest products and technologies in the industry.
</p>
<p className="lg--two-thirds text--large block-text__subheader">
The Forgetech India exhibition provided a dedicated platform for companies to display their offerings and interact with the forging industry professionals in India. The concurrent organization of these two events created a comprehensive platform for knowledge sharing, networking, and business opportunities.
</p>
</div>
</div>
</div>
</div>
                    <div id="Role">
                        
<div className="block-text">
<h3 className="heading-3 block-text__header grid__item md--two-quarters push--md--one-quarter">CIMGLOBAL's Role</h3>
<div className="grid grid--flex">
    <aside className="grid__item md--one-quarter block-text__labels">
            <ul className="block-text__labels-list">
            </ul>
    </aside>

    <div className="grid__item md--three-quarters block-text__content">
            <p className="lg--two-thirds text--large block-text__subheader">
            CIMGLOABAL's expertise in event management was instrumental in the successful organization and execution of the ASIAFORGE Meeting 2019 and the Forgetech India exhibition. The company's comprehensive services included venue management, logistics coordination, speaker and sponsor management, marketing and promotion, and on-site coordination.
            </p>
            <p className="lg--two-thirds text--large block-text__subheader">
            CIMGLOBAL's dedicated and hardworking team adapted to the ever-changing dynamics of the events, ensuring a seamless experience for the organizers and attendees. The company's ability to handle the various aspects of the events was widely appreciated by the AIFI and the ASIAFORGE organization.
            </p>
       
    </div>
</div>
</div>

                    </div>
<div id="Impact">
                        
<div className="block-text">
<h3 className="heading-3 block-text__header grid__item md--two-quarters push--md--one-quarter">Impact and Outcomes</h3>
<div className="grid grid--flex">
    <aside className="grid__item md--one-quarter block-text__labels">
            <ul className="block-text__labels-list">
            </ul>
    </aside>

    <div className="grid__item md--three-quarters block-text__content">
            <p className="lg--two-thirds text--large block-text__subheader">
            The ASIAFORGE Meeting 2019 and the Forgetech India exhibition were resounding successes, attracting over 550 delegates from the Asian forging community and 600 participants, respectively. The events provided a valuable platform for industry professionals to network, share knowledge, and explore business opportunities. The events' success highlighted the growing importance of the forging industry in Asia and the need for such collaborative platforms.
            </p>
     
    </div>
</div>
</div>

                    </div>

<div id="Facts">
<div className="block-text">
<h3 className="heading-3 block-text__header grid__item md--two-quarters push--md--one-quarter">Key Facts</h3>
<div className="grid grid--flex">
    <aside className="grid__item md--one-quarter block-text__labels">
            <ul className="block-text__labels-list">
            </ul>
    </aside>

    <div className="grid__item md--three-quarters block-text__content">
            <p className="lg--two-thirds text--large block-text__subheader">
<ul className="block-text__labels-list">
<li>o	The ASIAFORGE Meeting is an international conference organized by the ASIAFORGE organization, which was established in 2005 by leading forging industry associations in Asia.</li>
<li>o	The 7th edition of the ASIAFORGE Meeting was hosted by the Association of Indian Forging Industry (AIFI) in Chennai, India, from January 18-24, 2019.</li>
<li>o	Concurrent with the ASIAFORGE Meeting, AIFI also organized the second edition of the Forgetech India exhibition, a dedicated event for the forging industry in India.</li>
<li>o	CIMGLOBAL, a leading event management company, was entrusted with the responsibility of managing both the ASIAFORGE Meeting and the Forgetech India exhibition.</li>
<li>o	CIMGLOBAL's expertise in event management, including venue management, logistics coordination, speaker and sponsor management, and on-site coordination, was instrumental in the success of the events.</li>
<li>o	The ASIAFORGE Meeting 2019 attracted over 550 delegates from the Asian forging community, while the Forgetech India exhibition witnessed a footfall of 600 participants.</li>
<li>o	The concurrent organization of the ASIAFORGE Meeting and the Forgetech India exhibition created a comprehensive platform for the forging industry, fostering collaboration, knowledge sharing, and the showcasing of the latest products and technologies.</li>
<li>o	The event attracted forging experts and suppliers from China, Japan, South Korea, and Taiwan and required effective coordination and logistics management.</li>
            </ul>
            </p>
      
    </div>
</div>
</div>


                    </div>
                  
            </div>
    </div>
</div>
</section>



            </div>
          
           
            <div class="grid mod-related mod-related--light mod-related--has-read-more" data-lazy-load>
        <div class="grid__item lg--nine-twelfths">
            <h2 class="heading-2 mod-related__header grid--flex-center">
                <span class="mod-related__header-text">Case Stories</span>
                    
            </h2>
            <ul class="grid grid--flex">
                    <li class="grid__item md--one-third mod-related__item">
                        <article class="block text--small mod-related__block">
                            <a class="link link--pointing dot__wrapper mod-related__block link--zoom-image" href="/ASIAFORGEMeetingandForgetechIndiaExhibition" title="Read more" rel="noopener">
                                <div class="media-wrapper block__media block__media--standard">
                                        <img class="img-cover" src="/img/ASIAFORGE-Meeting-and-Forgetech-India-Exhibition.jpg" alt="ASIAFORGE Meeting and Forgetech India Exhibition" />
                                </div>
                                <div class="mod-related__link">
                                    <div class="mod-related__content">
                                        <h3 class="text--bold text--small">
                                            <span>ASIAFORGE Meeting and Forgetech India Exhibition</span>
                                        </h3>

                                        <div class="block__text text--dark">
                                            <span class="dot"></span>
                                            <span>The ASIAFORGE Meeting is an international conference organized by the ASIAFORGE organization, which was established in 2005 by the Association of Indian Forging Industry...</span>
                                        </div>
                                    </div>

                                    <span>
                                        <span class="link__text">Read more</span>
                                        <span class="icon icon--middle icon-arrow-right"></span>
                                    </span>
                                </div>
                            </a>
                        </article>
                    </li>
                    <li class="grid__item md--one-third mod-related__item">
                        <article class="block text--small mod-related__block">
                            <a class="link link--pointing dot__wrapper mod-related__block link--zoom-image" href="/WindergyIndiaConferenceandExhibition" title="Read more" rel="noopener">
                                <div class="media-wrapper block__media block__media--standard">
                                        <img class="img-cover" src="/img/Windergy-India-Conference-and-Exhibition.jpg" alt="Windergy India Conference and Exhibition" />
                                </div>
                                <div class="mod-related__link">
                                    <div class="mod-related__content">
                                         <h3 class="text--bold text--small">
                                            <span>Windergy India Conference and Exhibition</span>
                                        </h3>

                                        <div class="block__text text--dark">
                                            <span class="dot"></span>
                                            <span>The Windergy India 2017 conference and exhibition was a landmark event for the wind energy industry in India, held from April 25-27, 2017 in New Delhi. The event was organized...</span>
                                        </div>
                                    </div>

                                    <span>
                                        <span class="link__text">Read more</span>
                                        <span class="icon icon--middle icon-arrow-right"></span>
                                    </span>
                                </div>
                            </a>
                        </article>
                    </li>
                    <li class="grid__item md--one-third mod-related__item">
                        <article class="block text--small mod-related__block">
                            <a class="link link--pointing dot__wrapper mod-related__block link--zoom-image" href="/CONCRETEConferenceandExhibition" title="Read more" rel="noopener">
                                <div class="media-wrapper block__media block__media--standard">
                                        <img class="img-cover" src="/img/CONCRETE-Conference-and-Exhibition.jpg" alt="CONCRETE Conference and Exhibition" />
                                </div>
                                <div class="mod-related__link">
                                    <div class="mod-related__content">
                                      
                                        <h3 class="text--bold text--small">
                                            <span>CONCRETE Conference and Exhibition</span>
                                        </h3>

                                        <div class="block__text text--dark">
                                            <span class="dot"></span>
                                            <span>CIMGLOBAL, a leading event management company, successfully concluded the CONCRETE 2017 Conference and Exhibition on Underground Structures, held on July 14-15, 2017 at Hotel the Royal Plaza in New Delhi...</span>
                                        </div>
                                    </div>

                                    <span>
                                        <span class="link__text">Read more</span>
                                        <span class="icon icon--middle icon-arrow-right"></span>
                                    </span>
                                </div>
                            </a>
                        </article>
                    </li>
            </ul>
        </div>
    </div>
    </article>
</div>
        </>
    )
}

export default ASIAFORGEMeetingandForgetechIndiaExhibition