import Carousel from "../../component/carousel"
import News from "../../component/news"

const GlobalPresence = ()=>{
return(
<>
    <Carousel
    mainText={
    `Global Presence<br/>Local Excellence`
    }
    smallText={
    `Comprehensive conference planning and execution services ensuring seamless experiences and successful events worldwide.`
    }
    images={["img/pco_bg_00.jpg","img/pco_bg_01.jpg","img/pco_bg_02.jpg"]}
    />
<section className="mod-two-cols" data-lazy-load>
<div className="">

<div className="grid">
    <div className="grid__item md--two-thirds mod-two-cols--padded">
        <h3 className="heading-1"><span>Conference Management (PCO)</span></h3>
<p style={{fontSize:"20px",lineHeight:"120%",marginTop:"15px"}}>CIMGLOBAL organizes and manages your conference pro-actively – from beginning to end. Take advantage of our resources and experience. Our professional teams will organize, coordinate the logistics and deliver your conference – with outstanding service and passion for detail.
</p>


<div className="heading-2">
              <br/>
<h3>Your conference can benefit from:</h3>

                </div>
<ul style={{listStyle:"disc",marginLeft:"25px",fontSize:"20px",lineHeight:"120%",marginTop:"15px"}}>
<li>Specialized delegate relations professionals</li>
<li>Complete financial management</li>
<li>Smooth logistic handling</li>
<li>Vast experience with international conferences</li>
<li>Integrated marketing solutions</li>
<li>Multilingual teams</li>
</ul>
    </div>
</div>
</div>
</section>

<article className="mod-text-media mod-text-media__scheme--Light" data-lazy-load>
<div className="mod-text-media__wrapper grid">
<div className="grid  lg--two-thirds md-one-whole">


<div className="grid__item     
md--one-half">
<div className="mod-text__media">
<div className="media-wrapper mod-text__media">
        <div className="mod-overview__media">
                <a className="link--zoom-image">
                    <img className="img-cover" src="/img/Conference-Bid-Support.jpg" alt="Conference Bid Support"/>  
                </a>   
        </div>
</div>
</div>
</div>
    <div className="grid__item md--one-half">
        <div className="mod-text__content">
            <h3 className="heading-3 mod-text__header mod-text-media__header">Conference Bid Support</h3>
            <div className="text--large mod-text-media__content">
            <ul style={{listStyle:"disc",marginLeft:"25px",fontSize:"20px",lineHeight:"120%",marginTop:"15px"}}>
<li> Lobbying advice and support</li>
<li> Assistance in the creation of the bid proposal</li>
</ul>
                   
            </div>
            <a href="\bidding" className="link link--pointing">
                        <span className="link__text">Read more</span>
                        <span className="icon icon--middle icon--big icon-arrow-right"></span>
                    </a>
                
        </div>
    </div>
</div>
</div>
</article>

<article className="mod-text-media mod-text-media__scheme--Light" data-lazy-load>
<div className="mod-text-media__wrapper grid">
<div className="grid push--lg--one-third lg--two-thirds md-one-whole">

    <div className="grid__item md--one-half">
        <div className="mod-text__content">
            <h3 className="heading-3 mod-text__header mod-text-media__header">Conference Project Management and Delivery</h3>
            <div className="text--large mod-text-media__content">
            <ul style={{listStyle:"disc",marginLeft:"25px",fontSize:"20px",lineHeight:"120%",marginTop:"15px"}}>
<li> Conference logistics management</li>
<li> Participant handling</li>
<li> Registration services</li>
<li>Programme, Content and Abstract management</li>
<li>Financial management and budgeting</li>
</ul>
                {/* <p>-------------------------</p> */}
            </div>
                  
        </div>
    </div>


<div className="grid__item     
md--one-half">
<div className="mod-text__media">
<div className="media-wrapper mod-text__media">
        <div className="mod-overview__media">
                <a href="#" className="link--zoom-image">
                    <img className="img-cover" src="/img/Conference-Project-Management-and-Delivery.jpg" alt="Conference Project Management and Delivery"/>  
                </a>   
        </div>
</div>
</div>
</div>        </div>
</div>
</article>

<article className="mod-text-media mod-text-media__scheme--Gray" data-lazy-load>
<div className="mod-text-media__wrapper grid">
<div className="grid  lg--two-thirds md-one-whole">


<div className="grid__item     
md--one-half">
<div className="mod-text__media">
<div className="media-wrapper mod-text__media">
        <div className="video">
            <div className="video__content">
                <button className="btn btn-play">
                    <span className="video__play_btn"></span>
                </button>
                    <h2 className="video-plug__title text--small">
                        <span></span>
                    </h2>
            </div>
            <div className="mod-overview__media">
                <a className="link--zoom-image">
                    <img className="img-cover" src="/img/Conference-Promotion.jpg" alt="Conference Promotion"/>  
                </a>   
        </div>
            
            {/* <video poster="/img/Conference-Promotion.jpg"> 
                <source src="https://www.youtube.com/watch?v=GAtNqTwVVG4&t" type="video/mp4"/>
</video> */}
        </div>
</div>
</div>
</div>
    <div className="grid__item md--one-half">
        <div className="mod-text__content">
            <h3 className="heading-3 mod-text__header mod-text-media__header">Conference Promotion</h3>
            <div className="text--large mod-text-media__content">
            <ul style={{listStyle:"disc",marginLeft:"25px",fontSize:"20px",lineHeight:"120%",marginTop:"15px"}}>
<li> Event branding and marketing</li>
<li> Online presence and promotion</li>
<li> Press releases, advertisements and press coordination</li>
</ul>
            </div>
        </div>
    </div>
</div>
</div>
</article>

<article className="mod-text-media mod-text-media__scheme--Light" data-lazy-load>
<div className="mod-text-media__wrapper grid">
<div className="grid push--lg--one-third lg--two-thirds md-one-whole">

    <div className="grid__item md--one-half">
        <div className="mod-text__content">
            <h3 className="heading-3 mod-text__header mod-text-media__header">Venue Sourcing</h3>
            <div className="text--large mod-text-media__content">
            <ul style={{listStyle:"disc",marginLeft:"25px",fontSize:"20px",lineHeight:"120%",marginTop:"15px"}}>
<li> Negotiating rates and contract details</li>
<li> Destination comparisons</li>
<li> Registration services</li>
</ul>
            </div>
                
        </div>
    </div>


<div className="grid__item     
md--one-half">
<div className="mod-text__media">
<div className="media-wrapper mod-text__media">
        <div className="mod-overview__media">
                <a href="#" className="link--zoom-image">
                    <img className="img-cover" src="/img/Venue-Sourcing.jpg" alt="Venue Sourcing"/>  
                </a>   
        </div>
</div>
</div>
</div>        </div>
</div>
</article>

<article className="mod-text-media mod-text-media__scheme--Gray" data-lazy-load>
<div className="mod-text-media__wrapper grid">
<div className="grid  lg--two-thirds md-one-whole">


<div className="grid__item     
md--one-half">
<div className="mod-text__media">
<div className="media-wrapper mod-text__media">
        <div className="video">
            <div className="video__content">
                <button className="btn btn-play">
                    <span className="video__play_btn"></span>
                </button>
                    <h2 className="video-plug__title text--small">
                        <span></span>
                    </h2>
            </div>
            <div className="mod-overview__media">
                <a className="link--zoom-image">
                    <img className="img-cover" src="/img/Travel-Accommodation-Management.jpg" alt="Travel & Accommodation Management"/>  
                </a>   
        </div>
            {/* <video poster="/img/Travel-Accommodation-Management.jpg"> 
                <source src="https://player.vimeo.com/external/329810926.hd.mp4?s=10231c01bbf40bf846479fd498a5b9365dd61473&amp;profile_id=175" type="video/mp4"/>
</video> */}
        </div>
</div>
</div>
</div>
    <div className="grid__item md--one-half">
        <div className="mod-text__content">
            <h3 className="heading-3 mod-text__header mod-text-media__header">Travel &amp; Accommodation Management</h3>
            <div className="text--large mod-text-media__content">
            <ul style={{listStyle:"disc",marginLeft:"25px",fontSize:"20px",lineHeight:"120%",marginTop:"15px"}}>
<li> Accommodation assistance &amp; booking</li>
<li> Online airline bookings &amp; ticketing</li>
<li> Ground transportation</li>
</ul>
            </div>
        </div>
    </div>
</div>
</div>
</article>

<article className="mod-text-media mod-text-media__scheme--Gray" data-lazy-load>
<div className="mod-text-media__wrapper grid">
<div className="grid">


<div className="grid__item     
">
<p style={{textAlign:"left",fontSize:"20px",lineHeight:"120%"}}>CIMGLOBAL is your partner in bringing your conference vision to life. We understand the importance of your conference, and we&#39;re committed to helping you achieve your goals. Whether it's increasing attendance, enhancing the attendee experience, or showcasing your organization&#39;s expertise, we&#39;re here to make it happen. With our expertise and attention to detail, you can trust us to handle every aspect of your conference, allowing you to focus on what matters most: delivering an exceptional experience for your attendees and achieving your vision of success.</p>
<br/>
<a href="/Contact" className="link--pointing link--pill ">
         <span className="link__animation">
         <span className="link__text">Conact Us</span>
         <span className="icon icon--middle icon-arrow-right"></span>
         </span>
         </a>
</div>
    
</div>
</div>

</article>

<News/>
</>
)
}

export default GlobalPresence